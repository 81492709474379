import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom'; // You might need to install react-router-dom if not already
import api from '../api';

function PasswordReset() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const location = useLocation();
    let latestRequestToken = 0;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const queryParams = new URLSearchParams(location.search);
        const email = queryParams.get('email');
        const resetToken = queryParams.get('token');

        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match.');
            return;
        }

        const currentRequestToken = Date.now(); // Use the current timestamp as a unique identifier
        latestRequestToken = currentRequestToken; // Update the latest request token

        try {
            const response = await api.post('/passwordreset', { 
                email,
                newPassword,
                confirmPassword,
                resetToken
            });
            if (latestRequestToken !== currentRequestToken) {
                // This was not the latest request, so ignore its response
                return;
            }
            setMessage(response.data.message); // Display success message
        } catch (error) {
            if (latestRequestToken !== currentRequestToken) {
                // This was not the latest request, so ignore its response
                return;
            }
            setMessage(error.response.data.message); // Display error message
        }
    };

    return (
        <div>
            <h2>Set New Password</h2>
            <form onSubmit={handleSubmit}>
                <label htmlFor="newPassword">New Password:</label>
                <input 
                    id="newPassword" 
                    type="password" 
                    value={newPassword} 
                    onChange={e => setNewPassword(e.target.value)} 
                    required 
                />
                <label htmlFor="confirmPassword">Confirm New Password:</label>
                <input 
                    id="confirmPassword" 
                    type="password" 
                    value={confirmPassword} 
                    onChange={e => setConfirmPassword(e.target.value)} 
                    required 
                />
                <button type="submit">Set New Password</button>
            </form>
            {message && <p>{message}</p>}
            <NavLink to='/'>Sign in</NavLink>
        </div>
    );
}

export default PasswordReset;
