import React, {useState} from 'react';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../authcontext';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/login', { email, password });
            console.log(response.data);  // Handle login success, redirect or store login state
            setErrorMessage('');
            login(response.data.access_token, response.data.refresh_token); 
            navigate('/home');  // Use navigate('/home') instead of history.push('/home')
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                setErrorMessage(error.response.data.message);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                setErrorMessage('An error occurred. Please try again.');
            }
        }
    };

    // Handler to navigate to the register page
    const handleRegister = () => {
        navigate('/register');
    };

    // Handler to navigate to the reset password page
    const handleResetPassword = () => {
        navigate('/requestreset');
    };

    return (
        <div className="login-page">
            <div className='login-container'>
                <div className='login-content'>
                <h1 style={{textAlign: 'center'}}>Sign In</h1>
                <form onSubmit={handleSubmit}>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Log In</button>
                </form>
                <div className="button-group">
                    <button onClick={handleRegister} className="half-width-button">Register</button>
                    <button onClick={handleResetPassword} className="half-width-button">Reset your password</button>
                </div>
                {errorMessage && <p>{errorMessage}</p>}
                </div>
            </div>
        </div>
    );
}

export default LoginPage;