import React, { useEffect, useState } from 'react';
import { useAuth } from '../authcontext';
import api from '../api'; // Assuming you have an API handler

const PivotPopup = ({ pivotTableId, histPeriods, forePeriods, basis, onClose }) => {
  const { pivottables } = useAuth();
  const [pivotTableData, setPivotTableData] = useState(null);
  const [visiblePeriods, setVisiblePeriods] = useState([]);
  const [expandedRows, setExpandedRows] = useState(new Set()); // Track expanded rows
  const [driverSelections, setDriverSelections] = useState({}); // Track driver type for each row
  const [forecastValues, setForecastValues] = useState({}); // Track forecast input values for each row

  useEffect(() => {
    const pivotTable = pivottables.find(pivot => pivot.id === pivotTableId);
    if (pivotTable) {
      console.log('Pivot Table Data Found:', pivotTable);
      setPivotTableData(pivotTable);
      updateVisiblePeriods(pivotTable, histPeriods, forePeriods, basis);
    } else {
      console.warn('Pivot table not found for ID:', pivotTableId);
    }
  }, [pivotTableId, pivottables, histPeriods, forePeriods, basis]);
  
  useEffect(() => {
    if (pivotTableData && visiblePeriods.length > 0) {
      initializeDriverSelections(pivotTableData, basis);
      initializeForecastValues(pivotTableData, basis); // Ensure forecast values are initialized after periods
    }
  }, [visiblePeriods, basis]);

  const updateVisiblePeriods = (pivotData, histPeriods, forePeriods, basis) => {
    const sortPeriodsChronologically = (periods, groupingOption) => {
        if (groupingOption === 'Monthly') {
          return periods.sort((a, b) => {
            const [monthA, yearA] = a.split("/").map(Number);
            const [monthB, yearB] = b.split("/").map(Number); // Adjusted to compare 'b'
            return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
          });
        } else if (groupingOption === 'Quarterly') {
          return periods.sort((a, b) => {
            const [quarterA, yearA] = a.split(" ");
            const [quarterB, yearB] = b.split(" "); // Adjusted to compare 'b'
            const yearDiff = parseInt(yearA) - parseInt(yearB);
      
            if (yearDiff === 0) {
              // Order quarters correctly
              const quarterOrder = { 'Q1': 1, 'Q2': 2, 'Q3': 3, 'Q4': 4 };
              return quarterOrder[quarterA] - quarterOrder[quarterB];
            }
            return yearDiff;
          });
        } else {
          return periods.sort((a, b) => parseInt(a) - parseInt(b)); // Handle yearly case
        }
      };      

    const getPeriodsFromData = (data) => {
      const firstEntryKey = Object.keys(data)[0];
      if (firstEntryKey && data[firstEntryKey].total) {
        console.log('Periods found in data:', Object.keys(data[firstEntryKey].total));
        return Object.keys(data[firstEntryKey].total);
      }
      console.warn('No periods found in data');
      return [];
    };

    let histPeriodsList = [];
    let forePeriodsList = [];

    switch (basis) {
      case 'Yearly':
        histPeriodsList = getPeriodsFromData(pivotData.annualHistData);
        forePeriodsList = getPeriodsFromData(pivotData.annualForeData);
        break;
      case 'Quarterly':
        histPeriodsList = getPeriodsFromData(pivotData.quarterlyHistData);
        forePeriodsList = getPeriodsFromData(pivotData.quarterlyForeData);
        break;
      case 'Monthly':
        histPeriodsList = getPeriodsFromData(pivotData.monthlyHistData);
        forePeriodsList = getPeriodsFromData(pivotData.monthlyForeData);
        break;
      default:
        histPeriodsList = getPeriodsFromData(pivotData.annualHistData);
        forePeriodsList = getPeriodsFromData(pivotData.annualForeData);
    }

    console.log('Historical Periods List:', histPeriodsList);
    console.log('Forecast Periods List:', forePeriodsList);

    histPeriodsList = sortPeriodsChronologically(histPeriodsList, basis);
    forePeriodsList = sortPeriodsChronologically(forePeriodsList, basis);

    const adjustedPeriods = [
      ...histPeriodsList.slice(-histPeriods),
      ...forePeriodsList.slice(0, forePeriods),
    ];

    console.log('Adjusted Visible Periods:', adjustedPeriods);

    setVisiblePeriods(adjustedPeriods);
  };

  // Initialize driver selections from stored data
  const initializeDriverSelections = (pivotData, basis) => {
    const driverData = 
      basis === 'Yearly' ? pivotData.annualHistData :
      basis === 'Quarterly' ? pivotData.quarterlyHistData :
      pivotData.monthlyHistData;
  
    const populateDriverSelections = (data, parentKey = '') => {
      Object.keys(data).forEach((key) => {
        const rowKey = `${parentKey}-${key}`;
        if (data[key].children && Object.keys(data[key].children).length > 0) {
          populateDriverSelections(data[key].children, rowKey);
        } else if (data[key].driver && !driverSelections[rowKey]) { // Check if the driver isn't already in state
          console.log(`Initializing driver for ${rowKey}: ${data[key].driver}`);
          setDriverSelections(prevSelections => ({
            ...prevSelections,
            [rowKey]: data[key].driver // Initialize the driver from the stored value only if not in state
          }));
        }
      });
    };
  
    if (driverData) {
      populateDriverSelections(driverData);
    }
  };  

  const initializeForecastValues = (pivotData, basis) => {
    const forecastData =
      basis === 'Yearly' ? pivotData.annualForeData :
      basis === 'Quarterly' ? pivotData.quarterlyForeData :
      pivotData.monthlyForeData;
  
    const populateForecastValues = (data, parentKey = '') => {
      Object.keys(data).forEach((key) => {
        const rowKey = `${parentKey}-${key}`;
        if (data[key].children && Object.keys(data[key].children).length > 0) {
          populateForecastValues(data[key].children, rowKey);
        } else if (data[key].total) {
          // Only map forecast periods to forecast values
          const forecastPeriods = visiblePeriods.slice(histPeriods); // Only forecast periods
          setForecastValues(prevValues => ({
            ...prevValues,
            [rowKey]: forecastPeriods.map(period => 
              data[key].total[period]?.sum !== undefined ? data[key].total[period].sum : 0
            ) // Use period's sum or 0 if not available
          }));
        }
      });
    };
  
    if (forecastData) {
      populateForecastValues(forecastData);
    }
  };  

  const toggleRowExpansion = (rowKey) => {
    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(rowKey)) {
        newExpandedRows.delete(rowKey);
      } else {
        newExpandedRows.add(rowKey);
      }
      return newExpandedRows;
    });
  };

  const getRowDataForPeriods = (rowData, periods) => {
    return periods.map((period, index) => {
      if (index < histPeriods) {
        return rowData && rowData[period] ? rowData[period].sum : 0;
      } else {
        return 0; // Fill forecast columns with 0 if no value
      }
    });
  };

  const handleDriverChange = (key, newDriver) => {
    console.log(`Driver change detected for ${key}, new driver: ${newDriver}`);
    setDriverSelections((prevSelections) => ({
      ...prevSelections,
      [key]: newDriver,
    }));
  };  

  const handleSave = async () => {
    try {
      const updatedPivotData = { ...pivotTableData };

      // Apply the updated drivers to the relevant levels of data
      const applyDriversToData = (data, parentKey = '') => {
        Object.keys(data).forEach((key) => {
          const rowKey = `${parentKey}-${key}`;
          if (data[key].children && Object.keys(data[key].children).length > 0) {
            applyDriversToData(data[key].children, rowKey);
          } else if (driverSelections[rowKey]) {
            data[key].driver = driverSelections[rowKey]; // Update driver with the selected one
          }
        });
      };

      // Update the drivers for the appropriate data (annual, quarterly, or monthly)
      switch (basis) {
        case 'Yearly':
          applyDriversToData(updatedPivotData.annualHistData);
          break;
        case 'Quarterly':
          applyDriversToData(updatedPivotData.quarterlyHistData);
          break;
        case 'Monthly':
          applyDriversToData(updatedPivotData.monthlyHistData);
          break;
        default:
          break;
      }

      // Send the updated pivot table data to the backend
      await api.put(`/pivottables/update/${pivotTableId}`, updatedPivotData);

      console.log('Pivot table updated successfully!');
      window.location.reload()
    } catch (error) {
      console.error('Error saving pivot table:', error);
    }
  };

  const propagateTotalsUpwards = (pivotTableData, keyParts, periods) => {
    // Extract the relevant data section (quarterlyForeData) that needs updating
    let currentLevel = pivotTableData.quarterlyForeData;
    let fullPath = []; // Track the full path of parent levels

    // Traverse the data structure based on the key parts (e.g., ['USD', 'Vendor E'])
    keyParts.forEach((part, index) => {
        if (currentLevel[part]) {
            fullPath.push({ key: part, data: currentLevel }); // Save the current level and key
            if (currentLevel[part].children) {
                currentLevel = currentLevel[part].children; // Traverse deeper into the children
            } else {
                console.warn(`No children found for ${part}. Stopping traversal.`);
            }
        } else {
            console.warn(`Key part ${part} not found. Stopping traversal.`);
            return;
        }
    });

    const updateParentTotals = (data, key, periods) => {
        // Ensure the key exists and has children to update
        if (!data[key] || !data[key].children) {
            console.warn(`No data or children found for key: ${key}`);
            return;
        }

        periods.forEach((period) => {
            // Initialize the total for the parent key if it doesn't exist
            if (!data[key].total) {
                data[key].total = {};
            }
            if (!data[key].total[period]) {
                data[key].total[period] = { sum: 0 };
            }

            // Reset the parent sum for this period
            data[key].total[period].sum = 0;

            // Sum all children totals for the given period
            for (const childKey in data[key].children) {
                const child = data[key].children[childKey];
                if (child && child.total && child.total[period]) {
                    data[key].total[period].sum += parseFloat(child.total[period].sum) || 0;
                } else {
                    console.warn(`No total found for child ${childKey} at period ${period}`);
                }
            }

            console.log(`Updated total for parent ${key} at period ${period}: ${data[key].total[period].sum}`);
        });
    };

    // Now go upwards through the path and update the parent totals
    for (let i = fullPath.length - 2; i >= 0; i--) {
        const { key, data: parentData } = fullPath[i];
        updateParentTotals(parentData, key, periods);
    }
};
  
const handleForecastInputChange = (key, periodIndex, value) => {
  console.log(`Received input change: key=${key}, periodIndex=${periodIndex}, value=${value}`);

  setForecastValues((prevValues) => {
    const correctedKey = key.startsWith('-') ? key : `-${key}`;

    // Ensure the forecast values array exists for this key
    const updatedValues = {
      ...prevValues,
      [correctedKey]: [
        ...(prevValues[correctedKey] || Array(forePeriods).fill(0)), // Initialize with zeros if undefined
      ],
    };

    // Log current forecast values for the row before updating
    console.log('Before update:', updatedValues[correctedKey]);

    // Update the specific period index with the new value
    updatedValues[correctedKey][periodIndex] = parseFloat(value) || 0;

    // Log the updated forecast values for the row
    console.log(`Updated forecast values for key ${correctedKey} at period index ${periodIndex}:`, updatedValues[correctedKey]);

    // Now updating the pivot table data based on the `basis`
    const updatedPivotData = { ...pivotTableData };
    let currentLevel;

    switch (basis) {
      case 'Yearly':
        currentLevel = updatedPivotData.annualForeData;
        break;
      case 'Quarterly':
        currentLevel = updatedPivotData.quarterlyForeData;
        break;
      case 'Monthly':
        currentLevel = updatedPivotData.monthlyForeData;
        break;
      default:
        console.error(`Unknown basis: ${basis}`);
        return prevValues;
    }

    const keyParts = correctedKey.split('-').filter(Boolean);
    console.log('Key parts:', keyParts);

    const period = visiblePeriods[parseInt(periodIndex) + parseInt(histPeriods)];
    console.log('Selected period:', period);

    keyParts.forEach((part, index) => {
      if (index === keyParts.length - 1 && currentLevel[part]) {
        if (!currentLevel[part].total) {
          currentLevel[part].total = {};
        }
        if (!currentLevel[part].total[period]) {
          currentLevel[part].total[period] = { sum: 0 };
        }
        console.log(`Updating pivot data for part ${part} at period ${period}: value=${value}`);
        currentLevel[part].total[period].sum = parseFloat(value) || 0;
      } else if (currentLevel[part]?.children) {
        currentLevel = currentLevel[part].children;
      } else {
        console.warn(`No children found for part ${part}, skipping...`);
      }
    });

    console.log('Updated pivot table data:', updatedPivotData);

    propagateTotalsUpwards(updatedPivotData, keyParts, visiblePeriods.slice(histPeriods));

    setPivotTableData(updatedPivotData);

    return updatedValues;
  });
};

  
const renderRows = (histData, foreData, depth = 0, parentKey = '') => {
  const sortedKeys = Object.keys(histData).sort((a, b) => {
    const totalA = histData[a].total
      ? Object.values(histData[a].total).reduce((acc, period) => acc + (period.sum || 0), 0)
      : 0;
    const totalB = histData[b].total
      ? Object.values(histData[b].total).reduce((acc, period) => acc + (period.sum || 0), 0)
      : 0;
    return totalB - totalA;
  });

  return sortedKeys.map((key) => {
    const histRow = histData[key];
    const foreRow = foreData[key];

    // Add the dash back to the key if needed
    const rowKey = parentKey ? `${parentKey}-${key}` : `-${key}`;
    const isExpanded = expandedRows.has(rowKey);
    const hasChildren = histRow.children && Object.keys(histRow.children).length > 0;

    console.log("Rendering row for rowKey:", rowKey, "forecastValues:", forecastValues[rowKey]);

    // Combine historical and forecast row values for display
    const combinedRowValues = visiblePeriods.map((period, index) => {
      if (index < histPeriods) {
        return histRow.total && histRow.total[period] ? histRow.total[period].sum : 0;
      } else if (foreRow && foreRow.total && foreRow.total[period]) {
        return foreRow.total[period].sum !== undefined ? foreRow.total[period].sum : 0;
      }
      return 0; // Default to 0 if no data is found
    });

    // Display total sums for higher-level categories and input boxes for the bottom level
    const rowCells = combinedRowValues.map((value, index) => {
      return (
        <td key={index}>
          {index < histPeriods ? (
            value // Show historical data
          ) : (
            hasChildren ? (
              value  // Display total sum for higher-level categories
            ) : (
              <input
                type="text"
                value={
                  forecastValues[rowKey]?.[index - histPeriods] !== undefined
                    ? forecastValues[rowKey][index - histPeriods]  // Ensure this is bound to state
                    : '' // Empty if no forecast value
                }
                onChange={(e) => handleForecastInputChange(rowKey, index - histPeriods, e.target.value)}
              />
            )
          )}
        </td>
      );
    });

    return (
      <React.Fragment key={rowKey}>
        <tr>
          {/* Row name (first column) */}
          <td style={{ paddingLeft: `${depth * 20}px` }} onClick={() => hasChildren && toggleRowExpansion(rowKey)}>
            {hasChildren ? (
              <span>{isExpanded ? '▼' : '►'}</span>
            ) : (
              <span style={{ visibility: 'hidden' }}>•</span>
            )}
            {key}
          </td>
          {/* Driver column (second column) */}
          <td>
            {hasChildren ? (
              'Total'
            ) : (
              <select
                value={driverSelections[rowKey] || 'Fixed Amount'}
                onChange={(e) => handleDriverChange(rowKey, e.target.value)}
              >
                <option value="Fixed Amount">Fixed Amount</option>
                <option value="Fixed Growth">Fixed Growth</option>
                <option value="Percent Increase">Percent Increase</option>
              </select>
            )}
          </td>
          {/* Render historical and forecast data for all rows */}
          {rowCells}
        </tr>
        {isExpanded && hasChildren && renderRows(histRow.children, foreRow?.children || {}, depth + 1, rowKey)}
      </React.Fragment>
    );
  });
};

  // Only render the popup when pivotTableData is available
  if (!pivotTableData) {
    return null; // Return nothing until data is available
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>×</button>
        <h2>Pivot Table: {pivotTableId}</h2>

        <table className="popup-table">
          <thead>
            <tr>
              <th>Row Name</th>
              <th>Driver</th> {/* Driver column */}
              {visiblePeriods.map((period, index) => (
                <th key={index}>{period}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Render based on the basis */}
            {basis === 'Yearly' && renderRows(pivotTableData.annualHistData || {}, pivotTableData.annualForeData || {})}
            {basis === 'Quarterly' && renderRows(pivotTableData.quarterlyHistData || {}, pivotTableData.quarterlyForeData || {})}
            {basis === 'Monthly' && renderRows(pivotTableData.monthlyHistData || {}, pivotTableData.monthlyForeData || {})}
          </tbody>
        </table>

        {/* Add Save button */}
        <button className="popup-save" onClick={handleSave}>Save</button>
      </div>
    </div>
  );
};

export default PivotPopup;
