import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to refresh token
const refreshToken = async () => {
  const refreshTokenValue = localStorage.getItem('refreshToken');
  if (!refreshTokenValue) {
    throw new Error('No refresh token available');
  }

  console.log('Attempting to refresh token with:', refreshTokenValue); // Debugging line

  try {
    const response = await axios.post('/api/refresh', {}, {
      headers: {
        Authorization: `Bearer ${refreshTokenValue}`
      }
    });

    console.log('Refresh token response:', response.data); // Debugging line

    localStorage.setItem('token', response.data.access_token); // Update the access token in localStorage
    return response.data.access_token;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    throw error;
  }
};

// Add a response interceptor
api.interceptors.response.use(response => response, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    try {
      const newAccessToken = await refreshToken();
      originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
      return api(originalRequest); // Retry the original request with the new token
    } catch (refreshError) {
      // Refresh token failed
      console.error('Refresh token failed:', refreshError);
      // Optionally handle the error by logging out the user or showing an error message
      // logout(); // Call your logout function here
      return Promise.reject(refreshError);
    }
  }
  return Promise.reject(error);
});

export default api;
