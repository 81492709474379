import React, { useState, useEffect } from 'react';
import { useAuth } from '../authcontext';
import AddRowForm from './addrow';
import AddCalculatedRowForm from './addcalculatedrow';
import api from '../api'; // Assuming you have an API utility for making requests
import { useParams } from 'react-router-dom'; 

const FormulaBuildPage = () => {
    const { modelId } = useParams(); // Get the modelId from the URL
    const { currentModel, setCurrentModel, spreadsheets, models, isLoading: isModelsLoading } = useAuth();
    const [tableHeaders, setTableHeaders] = useState([]);
    const [rowsData, setRowsData] = useState([]);
    const [showAddRowPopup, setShowAddRowPopup] = useState(false); // Controls simplified row popup
    const [showCalculatedRowPopup, setShowCalculatedRowPopup] = useState(false); // Controls simplified row popup
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null); // State to hold the clicked row data

    // Fetch the model whenever the modelId changes and models are loaded
    useEffect(() => {
        // Fetch the model only if modelId is present and models have been loaded
        if (modelId && !isModelsLoading && models.length > 0) {
            fetchModel(modelId);
        } else if (!isModelsLoading && models.length === 0) {
            // Optionally fetch the models again if not loaded (depending on context)
            console.warn('No models available.');
        }
    }, [modelId, isModelsLoading, models]);
       

    const fetchModel = async (modelId) => {
        try {
            setIsLoading(true);
            const model = models.find((m) => m.id === parseInt(modelId, 10));

            if (model) {
                setCurrentModel(model); // Set the new model in context
                const histHeaders = getHeadersByBasis(model, 'Hist');
                const foreHeaders = getHeadersByBasis(model, 'Fore');

                const headers = ['Row name', 'Driver', ...histHeaders.slice(-model.histPeriods), ...foreHeaders.slice(0, model.forePeriods)];
                setTableHeaders(headers);

                processRowsData(model, model.histPeriods, model.forePeriods);
            } else {
                console.error('Model not found for modelId:', modelId);
            }
        } catch (error) {
            console.error('Error fetching model:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const getHeadersByBasis = (model, type) => {
        let headers = [];
        if (!model) {
            console.error('Model is undefined');
            return headers;
        }
    
        try {
            let rawHeaders;
            switch (model.basis) {
                case 'Yearly':
                    rawHeaders = type === 'Hist' ? model.annualHistHeaders : model.annualForeHeaders;
                    break;
                case 'Quarterly':
                    rawHeaders = type === 'Hist' ? model.quarterlyHistHeaders : model.quarterlyForeHeaders;
                    break;
                case 'Monthly':
                    rawHeaders = type === 'Hist' ? model.monthlyHistHeaders : model.monthlyForeHeaders;
                    break;
                default:
                    console.error('Invalid basis specified in the model');
                    return [];
            }
    
            // If rawHeaders is already an array, no need to parse
            headers = Array.isArray(rawHeaders) ? rawHeaders : JSON.parse(rawHeaders);
    
            if (!Array.isArray(headers)) {
                console.error('Headers are not an array:', headers);
                return [];
            }
    
            return headers.map(header => Array.isArray(header) ? header[0] : header.label || '');
        } catch (error) {
            console.error('Error parsing headers:', error);
            return [];
        }
    };
    

    const processRowsData = async (model, histPeriodsCount, forePeriodsCount) => {
        if (!model) {
            console.error('Model is undefined');
            setRowsData([]);
            return;
        }
    
        console.log('Processing rows data...');
        setIsLoading(true);
        const rows = [];
    
        const {
            rowNames = [],
            drivers = [],
            annualHistRows = [],
            quarterlyHistRows = [],
            monthlyHistRows = [],
            annualForeRows = [],
            quarterlyForeRows = [],
            monthlyForeRows = [],
        } = model;
    
        if (!Array.isArray(rowNames) || rowNames.length === 0) {
            console.warn('No rows found in the model');
            setRowsData([]); 
            setIsLoading(false); 
            return;
        }
    
        let histRowsData, foreRowsData;
        switch (model.basis) {
            case 'Yearly':
                histRowsData = JSON.parse(annualHistRows);
                foreRowsData = JSON.parse(annualForeRows);
                break;
            case 'Quarterly':
                histRowsData = JSON.parse(quarterlyHistRows);
                foreRowsData = JSON.parse(quarterlyForeRows);
                break;
            case 'Monthly':
                histRowsData = JSON.parse(monthlyHistRows);
                foreRowsData = JSON.parse(monthlyForeRows);
                break;
            default:
                console.error('Invalid basis specified in the model');
                setRowsData([]);
                setIsLoading(false);
                return;
        }
    
        for (let i = 0; i < rowNames.length; i++) {
            const rowName = rowNames[i];
    
            const rowData = {
                name: rowName || '',
                driver: drivers[i] || '',
                values: [],
            };
    
            // **Process Historical Data**
            if (histRowsData && Array.isArray(histRowsData[i])) {
                const histValues = histRowsData[i].slice(-histPeriodsCount);
                rowData.values.push(...histValues);
            }
    
            // **Process Forecast Data Directly from foreRowsData**
            if (foreRowsData && Array.isArray(foreRowsData[i])) {
                const foreValues = foreRowsData[i].slice(0, forePeriodsCount);
                rowData.values.push(...foreValues);
            }
    
            rows.push(rowData);
        }
    
        setRowsData(rows);
        setIsLoading(false);
    };    

    const handleRowClick = (row, rowIndex) => {
        // Ensure currentModel is loaded
        if (!currentModel) {
            console.error('currentModel is not loaded');
            return;
        }
        const modelBasis = currentModel.basis;

        // Extract historical and forecast data based on model's basis
        let historicalData = [];
        let forecastData = [];
        let forecastValues = [];

        switch (modelBasis) {
            case 'Yearly':
                historicalData = JSON.parse(currentModel.annualHistRows)[rowIndex] || [];
                forecastData = JSON.parse(currentModel.annualForeRows)[rowIndex] || [];
                forecastValues = JSON.parse(currentModel.annualForeValues)[rowIndex] || [];
                break;
            case 'Quarterly':
                historicalData = JSON.parse(currentModel.quarterlyHistRows)[rowIndex] || [];
                forecastData = JSON.parse(currentModel.quarterlyForeRows)[rowIndex] || [];
                forecastValues = JSON.parse(currentModel.quarterlyForeValues)[rowIndex] || [];
                break;
            case 'Monthly':
                historicalData = JSON.parse(currentModel.monthlyHistRows)[rowIndex] || [];
                forecastData = JSON.parse(currentModel.monthlyForeRows)[rowIndex] || [];
                forecastValues = JSON.parse(currentModel.monthlyForeValues)[rowIndex] || [];
                break;
            default:
                console.warn('Invalid basis type');
                return;
        }

        // Pass the data to the AddRowForm component
        setSelectedRowData({
            ...row,
            rowIndex, // Correctly use the rowIndex parameter
            historicalData, // Pass historical data for initial load
            forecastData, // Pass forecast data for initial load
            forecastValues, // Pass underlying values as well (like percent increases and growth amounts)
            selectedSpreadsheetId: currentModel.sourceFile?.[rowIndex]?.[0] || '',
            selectedCategory: currentModel.categories?.[rowIndex] || '',
            selectedCategoryValue: currentModel.rowVariables?.[rowIndex] || '',
            selectedDateColumn: currentModel.dateColumn?.[rowIndex] || '',
            selectedVariable: currentModel.groupedOver?.[rowIndex] || '',
            sourceFile: currentModel.sourceFile?.[rowIndex] || [],
        });

        // Ensure only the edit popup opens
        setShowAddRowPopup(false); // Ensure "Add Simplified Row" popup is closed
    };       

    const closePopup = () => {
        setSelectedRowData(null); // Close the popup
    };

    const handleRowAdded = async (updatedModel) => {
        // Hide the row forms
        setShowAddRowPopup(false);
    
        if (updatedModel) {
            try {
                // Update the context with the new model returned from AddRowForm or AddCalculatedRowForm
                console.log('updatedModel:', updatedModel);
                setCurrentModel(updatedModel);
    
                // Identify the newly added row (assuming it's the last one)
                const newRowIndex = updatedModel.drivers.length - 1;
                const newDriver = updatedModel.drivers[newRowIndex];
                console.log(`New row added at index ${newRowIndex} with driver:`, newDriver);
    
                if (Array.isArray(newDriver) && newDriver[0] === 'Calculated Row') {
                    // Handle Calculated Row
                    await handleCalculatedRowAddition(updatedModel, newRowIndex);
                } else {
                    // Handle Normal Row
                    console.log('Normal row added. No additional actions required.');
                    // If there's any specific action for normal rows, implement here
                }

                window.location.reload()
    
            } catch (error) {
                console.error('Error updating row:', error);
            }
        }
    };        
    
    const handleCalculatedRowAddition = async (updatedModel, addedRowIndex) => {
        try {
            console.log('Handling Calculated Row at index:', addedRowIndex);
    
            // Find the parent models associated with this calculated row
            const relevantModels = models.map((model) => {
                let parsedSupportingIds = [];
    
                // Parse supportingAssumptionModelIds if necessary
                try {
                    parsedSupportingIds = Array.isArray(model.supportingAssumptionModelIds) 
                                          ? model.supportingAssumptionModelIds 
                                          : JSON.parse(model.supportingAssumptionModelIds || '[]');
                } catch (error) {
                    console.error('Error parsing supportingAssumptionModelIds:', error);
                }
    
                // Find the index where the updatedModel.id matches
                const rowIndex = parsedSupportingIds.findIndex(id => id === String(updatedModel.id));
    
                console.log('Parent Model ID:', model.id);
                console.log('Matching Row Index:', rowIndex);
    
                if (rowIndex !== -1) {
                    return {
                        modelId: model.id,
                        rowIndex // Store the index where the matching supporting assumption model ID was found
                    };
                }
                return null;
            }).filter(item => item !== null); // Filter out any null results
    
            console.log('Relevant parent models to update:', relevantModels);
    
            if (relevantModels.length === 0) {
                console.warn('No matching models found for this supporting assumption ID.');
                return;
            }

            console.log('updatedmodel: ', updatedModel)
    
            // **Extract the forecast rows from the added calculated row**
            const { 
                annualHistRows, 
                quarterlyHistRows, 
                monthlyHistRows,
                annualForeRows, 
                quarterlyForeRows, 
                monthlyForeRows 
            } = updatedModel;
        
            const addedAnnualHistRows = annualHistRows[addedRowIndex] || [];
            const addedQuarterlyHistRows = quarterlyHistRows[addedRowIndex] || [];
            const addedMonthlyHistRows = monthlyHistRows[addedRowIndex] || [];
    
            const addedAnnualForeRows = annualForeRows[addedRowIndex] || [];
            const addedQuarterlyForeRows = quarterlyForeRows[addedRowIndex] || [];
            const addedMonthlyForeRows = monthlyForeRows[addedRowIndex] || [];
    
            console.log('Added Annual Hist Rows:', addedAnnualHistRows);
            console.log('Added Quarterly Hist Rows:', addedQuarterlyHistRows);
            console.log('Added Monthly Hist Rows:', addedMonthlyHistRows);
    
            console.log('Added Annual Forecast Rows:', addedAnnualForeRows);
            console.log('Added Quarterly Forecast Rows:', addedQuarterlyForeRows);
            console.log('Added Monthly Forecast Rows:', addedMonthlyForeRows);
    
            // **Prepare the payload to overwrite the parent row**
            const rowData = {
                rowIndex: relevantModels[0].rowIndex,
                annualHistRows: addedAnnualHistRows,
                quarterlyHistRows: addedQuarterlyHistRows,
                monthlyHistRows: addedMonthlyHistRows,
                annualForeRows: addedAnnualForeRows,
                quarterlyForeRows: addedQuarterlyForeRows,
                monthlyForeRows: addedMonthlyForeRows
            };
    
            console.log('Payload for updating parent model:', rowData);
    
            // **Make the API call to update the parent model's row for each relevant model**
            for (const { modelId, rowIndex } of relevantModels) {
                console.log(`Updating parent model ID: ${modelId}, Row Index: ${rowIndex}`);
                console.log('Data being sent to update parent model:', { ...rowData, rowIndex });
    
                try {
                    const updateResponse = await api.put(`/models/${modelId}/update-parent-row`, rowData);
                    console.log(`Successfully updated parent model with ID: ${modelId}`, updateResponse.data);
                } catch (error) {
                    console.error(`Error updating parent model with ID ${modelId}:`, error.response ? error.response.data : error.message);
                }
            }
    
            window.location.reload()
    
        } catch (error) {
            console.error('Error updating original models with calculated rows:', error);
        }
    };    

    const handleSave = async (updatedData) => {
        try {
            console.log('handleSave called with data:', JSON.stringify(updatedData, null, 2));

            const {
                rowIndex,
                rowName,
                driver,
                category,
                categoryValue,
                groupedOver,
                dateColumn,
                sourceFile,
                updatedHistoricalValues,
                updatedForecastValues,
                updatedForecastRows,
                basis,
            } = updatedData;

            // Validate that all required fields are present
            const requiredFields = ['rowName', 'category', 'categoryValue', 'groupedOver', 'dateColumn', 'sourceFile'];
            const missingFields = requiredFields.filter(field => !updatedData[field]);

            if (missingFields.length > 0) {
                console.error(`Missing required fields: ${missingFields.join(', ')}`);
                alert(`Missing required fields: ${missingFields.join(', ')}`);
                return;
            }

            // Ensure driver is always an array
            const formattedDriver = Array.isArray(driver) ? driver : [driver];

            const payload = {
                rowIndex,
                rowName,
                driver: formattedDriver,
                category,
                categoryValue,
                groupedOver,
                dateColumn,
                sourceFile,
                updatedHistoricalValues,
                updatedForecastValues,
                updatedForecastRows,
                basis,
            };

            console.log("Data being sent to backend:", JSON.stringify(payload, null, 2));

            const response = await api.put(`/model/update-forecasting-row/${currentModel.id}`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    // Include Authorization header if required
                    // 'Authorization': `Bearer ${token}`,
                },
            });

            console.log('Received response from backend:', response);

            if (response.status === 200) {
                console.log('Row data successfully updated:', response.data);
                closePopup();
                fetchModel(currentModel.id); // Refresh model data
                window.location.reload()
            } else {
                console.error('Failed to save changes:', response.data);
                alert(`Failed to save changes: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error saving changes:', error);
            alert('An error occurred while saving changes.');
        }
    };    

    const toggleShowRowForm = () => {
        setShowAddRowPopup(!showAddRowPopup);
        setShowCalculatedRowPopup(false);
        setSelectedRowData(null);
    };

    const toggleShowCalculatedRowPopup = () => {
        setShowCalculatedRowPopup(!showCalculatedRowPopup);
        setShowAddRowPopup(false);
        setSelectedRowData(null);
    };

    const hasCalculatedRow = currentModel?.drivers?.some(driver => Array.isArray(driver) && driver[0] === 'Calculated Row');

    return (
        <div>
            {currentModel && (
                <>
                    <h2>{`${currentModel.modelName} - ${currentModel.modelType}`}</h2>
                    <div className="button-container">
                        <button onClick={toggleShowRowForm}>Add Simplified Row</button>
                        {!hasCalculatedRow ? (
                            <button onClick={toggleShowCalculatedRowPopup}>Add Calculated Values Row</button>
                        ) : (<></>)                        
                        }
                    </div>

                    {showAddRowPopup && !selectedRowData && (
                        <div className="popup-overlay">
                            <button className="popup-close" onClick={() => setShowAddRowPopup(false)}>×</button>
                            <AddRowForm 
                                currentModel={currentModel}
                                spreadsheets={spreadsheets}
                                onRowAdded={handleRowAdded}
                                onClose={() => setShowAddRowPopup(false)}
                                isEditing={false}
                            />
                        </div>
                    )}

                    {showCalculatedRowPopup && !selectedRowData && (
                        <div className="popup-overlay">
                            <button className="popup-close" onClick={() => setShowCalculatedRowPopup(false)}>×</button>
                            <AddCalculatedRowForm 
                                currentModel={currentModel}
                                onRowAdded={handleRowAdded}
                                onClose={() => setShowCalculatedRowPopup(false)}
                                isEditing={false}
                            />
                        </div>
                    )}

                    {isLoading ? (
                        <p>Loading data...</p>
                    ) : (
                        <div className='scrollable-table-container'>
                            <table className='data-table data-table--model'>
                                <thead>
                                    <tr>
                                        {tableHeaders.map((header, index) => {
                                            const isForecastingColumn = index >= tableHeaders.length - currentModel.forePeriods;
                                            return (
                                                <th 
                                                    key={index} 
                                                    className={isForecastingColumn ? 'forecasting-column' : ''}
                                                >
                                                    {header}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(rowsData) && rowsData.length > 0 ? (
                                        rowsData.map((row, rowIndex) => (
                                            <tr 
                                                key={rowIndex} 
                                                onClick={() => handleRowClick(row, rowIndex)} // Pass row and rowIndex
                                            >
                                                <td>{row.name}</td>
                                                <td>{row.driver}</td>
                                                {row.values.map((value, valueIndex) => {
                                                    const isForecastingColumn = valueIndex >= row.values.length - currentModel.forePeriods;
                                                    return (
                                                        <td 
                                                            key={valueIndex} 
                                                            className={isForecastingColumn ? 'forecasting-column' : ''}
                                                        >
                                                            {value}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={tableHeaders.length}>No data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </>
            )}

            {/* Popup for editing row */}
            {selectedRowData && (
                <div className="popup-overlay">
                    <AddRowForm
                        currentModel={currentModel}
                        spreadsheets={spreadsheets}
                        onRowAdded={handleRowAdded}
                        onClose={closePopup}
                        isEditing={true}
                        rowData={selectedRowData}
                        rowIndex={selectedRowData.rowIndex} // Correctly pass rowIndex from selectedRowData
                        onSave={handleSave}
                    />
                </div>
            )}
        </div>
    );
};

export default FormulaBuildPage;
