import React, { useState, useEffect } from 'react';

const CustomPivotTableInModel = ({ pivotData, histPeriods, forePeriods, basis, onCellClick }) => {
  const [visiblePeriods, setVisiblePeriods] = useState([]);
  const [expandedRows, setExpandedRows] = useState(new Set()); // Track expanded rows

  const DEBUG = false; // Toggle detailed logging

  // Function to sort periods chronologically based on the grouping option (Monthly, Quarterly, Yearly)
  const sortPeriodsChronologically = (periods, groupingOption) => {
    if (groupingOption === 'Monthly') {
      return periods.sort((a, b) => {
        const [monthA, yearA] = a.split("/").map(Number);
        const [monthB, yearB] = b.split("/").map(Number);
        return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
      });
    } else if (groupingOption === 'Quarterly') {
      return periods.sort((a, b) => {
        const [quarterA, yearA] = a.split(" ");
        const [quarterB, yearB] = b.split(" ");
        const yearDiff = parseInt(yearA) - parseInt(yearB);
        if (yearDiff === 0) {
          const quarterOrder = { 'Q1': 1, 'Q2': 2, 'Q3': 3, 'Q4': 4 };
          return quarterOrder[quarterA] - quarterOrder[quarterB];
        }
        return yearDiff;
      });
    } else {
      return periods.sort((a, b) => parseInt(a) - parseInt(b)); // Yearly sorting
    }
  };

  // Effect to set visible periods from historical and forecast data
  useEffect(() => {
    if (!pivotData) {
      if (DEBUG) console.warn('No pivot data available.');
      return;
    }

    const getPeriodsFromData = (data) => {
      const firstEntryKey = Object.keys(data)[0];
      if (firstEntryKey && data[firstEntryKey].total) {
        return Object.keys(data[firstEntryKey].total);
      }
      return [];
    };

    let histPeriodsList = [];
    let forePeriodsList = [];

    switch (basis) {
      case 'Yearly':
        histPeriodsList = getPeriodsFromData(pivotData.annualHistData);
        forePeriodsList = getPeriodsFromData(pivotData.annualForeData);
        break;
      case 'Quarterly':
        histPeriodsList = getPeriodsFromData(pivotData.quarterlyHistData);
        forePeriodsList = getPeriodsFromData(pivotData.quarterlyForeData);
        break;
      case 'Monthly':
        histPeriodsList = getPeriodsFromData(pivotData.monthlyHistData);
        forePeriodsList = getPeriodsFromData(pivotData.monthlyForeData);
        break;
      default:
        histPeriodsList = getPeriodsFromData(pivotData.annualHistData);
        forePeriodsList = getPeriodsFromData(pivotData.annualForeData);
    }

    histPeriodsList = sortPeriodsChronologically(histPeriodsList, basis);
    forePeriodsList = sortPeriodsChronologically(forePeriodsList, basis);

    const adjustedPeriods = [
      ...histPeriodsList.slice(-histPeriods),
      ...forePeriodsList.slice(0, forePeriods),
    ];

    setVisiblePeriods(adjustedPeriods);

    if (DEBUG) console.log('Visible Periods Set:', adjustedPeriods);
  }, [pivotData, histPeriods, forePeriods, basis, DEBUG]);

  const toggleRowExpansion = (rowKey) => {
    setExpandedRows((prevExpandedRows) => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(rowKey)) {
        newExpandedRows.delete(rowKey);
      } else {
        newExpandedRows.add(rowKey);
      }
      return newExpandedRows;
    });
  };

  // Function to calculate forecast values based on the driver
  const calculateForecastValues = (histRow, foreRow, driver) => {
    const lastHistValue = histRow[histRow.length - 1] || 0;  // Last historical value as starting point
    const forecastValues = [];

    if (DEBUG) {
      console.log(`Calculating forecast values for driver: ${driver}`);
      console.log(`Historical Row Values:`, histRow);
      console.log(`Raw Forecast Row Values:`, foreRow);
    }

    if (driver === 'Fixed Amount') {
      if (DEBUG) console.log(`Driver: Fixed Amount - directly using forecast values`);
      return foreRow;  // Fixed Amount just uses the forecast values as is.
    } 
    else if (driver === 'Fixed Growth') {
      if (DEBUG) console.log(`Driver: Fixed Growth - applying growth to last historical value`);
      let currentValue = lastHistValue;  // Start with the last historical value
      foreRow.forEach((growthValue, index) => {
        const parsedGrowth = parseFloat(growthValue) || 0;
        currentValue += parsedGrowth;  // Add the growth value
        forecastValues.push(currentValue);
        if (DEBUG) console.log(`Period ${index + 1}: Growth ${growthValue}, Current Value ${currentValue}`);
      });
    } 
    else if (driver === 'Percent Increase') {
      if (DEBUG) console.log(`Driver: Percent Increase - applying percentage to last historical value`);
      let currentValue = lastHistValue;  // Start with the last historical value
      foreRow.forEach((percentValue, index) => {
        const parsedPercent = parseFloat(percentValue) || 0;
        currentValue += (currentValue * parsedPercent) / 100;  // Apply the percentage increase
        forecastValues.push(currentValue);
        if (DEBUG) console.log(`Period ${index + 1}: Percent Increase ${percentValue}%, Current Value ${currentValue}`);
      });
    }

    if (DEBUG) console.log(`Calculated Forecast Values:`, forecastValues);
    return forecastValues;
  };

  const getRowDataForPeriods = (rowData, periods) => {
    const rowValues = periods.map((period) => rowData && rowData[period] ? parseFloat(rowData[period].sum) : 0);
    if (DEBUG) console.log(`Row data for periods: ${periods}:`, rowValues);
    return rowValues;
  };

  // Function to sum child rows for a higher-level category
  const sumChildRows = (children) => {
    if (DEBUG) console.log('Summing child rows:', children);
    const sums = Array(visiblePeriods.length).fill(0);

    Object.values(children).forEach((child, childIndex) => {
      if (DEBUG) console.log(`Child ${childIndex + 1}:`, child);

      const histRowValues = getRowDataForPeriods(child.total, visiblePeriods.slice(0, histPeriods));
      const foreRowValues = calculateForecastValues(
        histRowValues,
        getRowDataForPeriods(child.total, visiblePeriods.slice(histPeriods)),
        child.driver
      );
      const combinedValues = [...histRowValues, ...foreRowValues];

      if (DEBUG) console.log(`Child ${childIndex + 1}: Combined Values:`, combinedValues);

      combinedValues.forEach((value, index) => {
        sums[index] += value;
      });
    });

    if (DEBUG) console.log(`Summed values for higher-level category:`, sums);
    return sums;
  };

  const renderRows = (histData, foreData, depth = 0, parentKey = '') => {
    const sortedKeys = Object.keys(histData).sort((a, b) => {
      const totalA = histData[a].total
        ? Object.values(histData[a].total).reduce((acc, period) => acc + (parseFloat(period.sum) || 0), 0)
        : 0;
      const totalB = histData[b].total
        ? Object.values(histData[b].total).reduce((acc, period) => acc + (parseFloat(period.sum) || 0), 0)
        : 0;
      return totalB - totalA;
    });

    return sortedKeys.map((key) => {
      const histRow = histData[key];
      const foreRow = foreData[key];
      const driver = histRow.driver || 'Fixed Amount';  // Retrieve driver for the row

      const isExpanded = expandedRows.has(parentKey + key);
      const hasChildren = histRow.children && Object.keys(histRow.children).length > 0;

      if (DEBUG) {
        console.log(`Rendering row: ${key}, Driver: ${driver}, Has Children: ${hasChildren}`);
      }

      // Historical values
      const histRowValues = getRowDataForPeriods(histRow.total, visiblePeriods.slice(0, histPeriods));

      // Forecast values based on the driver
      const forecastValues = calculateForecastValues(
        histRowValues,
        getRowDataForPeriods(foreRow?.total || {}, visiblePeriods.slice(histPeriods)),
        driver
      );

      // If the row has children, sum their calculated values after applying the driver
      const combinedRowValues = hasChildren
        ? sumChildRows(histRow.children)
        : [...histRowValues, ...forecastValues];

      if (DEBUG) {
        console.log(`Final Combined Row Values for ${key}:`, combinedRowValues);
      }

      return (
        <React.Fragment key={key}>
          <tr>
            {/* Row name (first column) */}
            <td
              style={{ paddingLeft: `${depth * 20}px`, cursor: hasChildren ? 'pointer' : 'default' }}
              onClick={() => hasChildren && toggleRowExpansion(parentKey + key)}
            >
              {hasChildren ? (
                <span style={{ marginRight: '5px' }}>{isExpanded ? '▼' : '►'}</span>
              ) : (
                <span style={{ visibility: 'hidden', marginRight: '5px' }}>•</span>
              )}
              {key}
            </td>
            {/* Driver (second column) */}
            <td onClick={() => onCellClick(1)}>{driver}</td>
            {/* Historical and forecasting columns start from index 2 */}
            {combinedRowValues.map((value, index) => (
              <td
                key={index}
                className={index >= histPeriods ? 'forecasting-column' : ''}
                onClick={() => onCellClick(index + 2)}  // +2 to account for the first two columns
              >
                {isNaN(value) ? '' : value}
              </td>
            ))}
          </tr>
          {isExpanded && hasChildren && renderRows(histRow.children, foreRow?.children || {}, depth + 1, parentKey + key)}
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      {renderRows(
        basis === 'Yearly' ? pivotData.annualHistData :
        basis === 'Quarterly' ? pivotData.quarterlyHistData :
        pivotData.monthlyHistData,
        basis === 'Yearly' ? pivotData.annualForeData :
        basis === 'Quarterly' ? pivotData.quarterlyForeData :
        pivotData.monthlyForeData
      )}
    </React.Fragment>
  );
};

export default CustomPivotTableInModel;
