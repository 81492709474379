import React from 'react';

const ProposeCleaning = ({ uniqueValues, setEditedValues }) => {
  // Function to normalize and standardize company names
  const normalizeString = (str) => {
    if (typeof str !== 'string') return ''; // Ensure we are handling strings
    return str.toLowerCase().replace(/[^a-zA-Z0-9\s]/g, '').trim();
  };

  // Helper function to extract the core name by removing common suffixes and geographic indicators
  const extractCoreName = (name) => {
    const suffixes = ["ltd", "limited", "corp", "corporation", "co", "inc", "uk"];
    const tokens = normalizeString(name).split(' ');
    // Filter out suffixes and return core name
    return tokens.filter(token => !suffixes.includes(token)).join(' ');
  };

  // Main function to propose cleaned values
  const proposeCleanedValues = () => {
    const coreNameMap = new Map();  // To track canonical core names
    const proposedEdits = {};       // To store proposed cleaned values

    // Step 1: Process each unique value object
    uniqueValues.forEach(({ originalValue, spreadsheetNames }) => {
      const coreName = extractCoreName(originalValue);  // Extract the core name from the original value

      // Step 2: Check if the core name already exists in our map
      if (coreNameMap.has(coreName)) {
        // If it exists, suggest the existing core name as the cleaned value
        proposedEdits[`${originalValue}-${spreadsheetNames}`] = coreNameMap.get(coreName);
      } else {
        // If it's a new core name, add the original value as its canonical form
        coreNameMap.set(coreName, originalValue);
      }
    });

    // Step 3: Apply the proposed cleaned values
    setEditedValues((prevValues) => {
      const newEditedValues = { ...prevValues };
      Object.keys(proposedEdits).forEach((key) => {
        newEditedValues[key] = proposedEdits[key];
      });
      return newEditedValues;
    });
  };

  return (
    <button onClick={proposeCleanedValues}>Propose Cleaning</button>
  );
};

export default ProposeCleaning;
