import React, { useState, useEffect, useRef } from 'react';

function AutosuggestDropdown({ options, onSelect, showDropdown, initialValue, isValid }) {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [inputValue, setInputValue] = useState(initialValue || '');
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setIsDropdownVisible(showDropdown && filteredOptions.length > 0);
  }, [showDropdown, filteredOptions]);

  const handleFilter = (value) => {
    const filtered = options.filter(option =>
      option.toLowerCase().startsWith(value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setInputValue(value);
  };

  const handleSelect = (option) => {
    onSelect(option);
    setInputValue(option);
    setIsDropdownVisible(false);
  };

  const handleFocus = () => {
    if (inputRef.current) {
      const value = inputRef.current.value;
      const filtered = options.filter(option =>
        option.toLowerCase().startsWith(value.toLowerCase())
      );
      setFilteredOptions(filtered);
      setIsDropdownVisible(filtered.length > 0);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    onSelect(value);  // Ensure the parent component gets updated with the new value
    const filtered = options.filter(option =>
      option.toLowerCase().startsWith(value.toLowerCase())
    );
    setFilteredOptions(filtered);
    setIsDropdownVisible(true);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="autosuggest-dropdown" ref={dropdownRef}>
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleChange}
        onFocus={handleFocus}
        className={isValid ? '' : 'invalid-input'}
      />
      {isDropdownVisible && (
        <ul className="dropdown">
          {filteredOptions.map((option, index) => (
            <li key={index} onClick={() => handleSelect(option)}>
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default AutosuggestDropdown;
