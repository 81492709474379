import React, { useState, useEffect } from 'react';
import { useAuth } from '../authcontext';
import api from '../api';
import { faPen, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { calculatePeriods } from './calculateperiods';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setYear, getMonth, getDate, parseISO, format } from 'date-fns';

const ForecastingLandingPage = () => {
    const { currentOrg, supermodels, setSupermodels, models, spreadsheets } = useAuth();
    const [defaultSettings, setDefaultSettings] = useState({
        defaultBasis: '',
        defaultHistPeriods: '',
        defaultForePeriods: '',
        defaultYearEnd: '',
    });
    // Add the new fields to the newSuperModel state
    const [newSuperModel, setNewSuperModel] = useState({
        modelName: '',
        folderName: '',
        profitAndLoss: false,
        balanceSheet: false,
        cashFlow: false,
        basis: '',         
        histPeriods: '',    
        forePeriods: '',  
        yearEnd: '',     
    });
    const [folderOptions, setFolderOptions] = useState([]);
    const [error, setError] = useState('');
    const [editMode, setEditMode] = useState({});
    const [editedName, setEditedName] = useState({});

    const orgSupermodels =
    currentOrg && Array.isArray(supermodels) && supermodels.length > 0
        ? supermodels.filter(
            (supermodel) => supermodel.organisationId === currentOrg.id
        )
        : [];


        useEffect(() => {
            if (currentOrg) {
                const orgDefaults = {
                    defaultBasis: currentOrg.defaultBasis || '',
                    defaultHistPeriods: currentOrg.defaultHistPeriods || '',
                    defaultForePeriods: currentOrg.defaultForePeriods || '',
                    defaultYearEnd: currentOrg.defaultYearEnd ? parseISO(currentOrg.defaultYearEnd) : null,
                };
                setDefaultSettings(orgDefaults);
        
                // Initialize newSuperModel settings with default settings
                setNewSuperModel(prevModel => ({
                    ...prevModel,
                    basis: orgDefaults.defaultBasis,
                    histPeriods: orgDefaults.defaultHistPeriods,
                    forePeriods: orgDefaults.defaultForePeriods,
                    yearEnd: orgDefaults.defaultYearEnd,
                }));
        
                // Extract folder names from existing supermodels
                const folders = Array.from(new Set(orgSupermodels.map(sm => sm.modelFolder)));
                setFolderOptions(folders);
            }
        }, [currentOrg, supermodels]);        

    const handleSuperModelChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewSuperModel((prevModel) => ({
            ...prevModel,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const transformPeriods = (periods) => {
        return periods.map(period => [
            period.label,
            moment(period.startDate).format('DD/MM/YYYY'),
            moment(period.endDate).format('DD/MM/YYYY')
        ]);
    };

    const getLatestDateFromSpreadsheets = () => {
        const today = new Date();
    
        if (!spreadsheets || spreadsheets.length === 0) {
            return today;
        }
    
        console.log('spreadsheets: ', spreadsheets);
    
        let latestDate = null;
    
        spreadsheets.forEach((spreadsheet) => {
            // Assuming each spreadsheet has a 'latestDate' property in 'yyyy-MM-dd' format
            if (spreadsheet.latestDate) {
                const date = parseISO(spreadsheet.latestDate);
                if (!latestDate || date > latestDate) {
                    latestDate = date;
                }
            }
        });
    
        // If no dates found in spreadsheets, use today
        if (!latestDate) {
            return today;
        }
    
        // Compare latestDate with today's date and return the earlier one
        return latestDate < today ? latestDate : today;
    };    

    const getPreviousYearEndDate = (latestDate, yearEnd) => {
        if (!latestDate || !yearEnd) {
            return null;
        }

        const latest = latestDate; // `latestDate` is a Date object
        const yearEndDate = yearEnd; // `yearEnd` is a Date object

        const yearEndMonth = getMonth(yearEndDate);
        const yearEndDay = getDate(yearEndDate);

        // Construct a date with the same year as the latest date and the year-end month and day
        let fyEndDate = new Date(latest.getFullYear(), yearEndMonth, yearEndDay);

        // If the financial year-end date is after the latest date, subtract one year
        if (fyEndDate > latest) {
            fyEndDate.setFullYear(fyEndDate.getFullYear() - 1);
        }

        return fyEndDate;
    };

    const handleAddModel = async (supermodel, modelType) => {
        const currentDate = new Date();
        const basis = supermodel.basis;
        const histPeriods = parseInt(supermodel.histPeriods, 10);
        const forePeriods = parseInt(supermodel.forePeriods, 10);
        const yearEnd = supermodel.yearEnd;
        const superModelName = supermodel.superModelName;
    
        // Calculate headers as done in the supermodel creation
        const annualHistHeaders = JSON.stringify(transformPeriods(calculatePeriods('Yearly', currentDate, 10, 0, yearEnd)));
        const quarterlyHistHeaders = JSON.stringify(transformPeriods(calculatePeriods('Quarterly', currentDate, 40, 0, yearEnd)));
        const monthlyHistHeaders = JSON.stringify(transformPeriods(calculatePeriods('Monthly', currentDate, 120, 0, yearEnd)));
    
        const annualForeHeaders = JSON.stringify(transformPeriods(calculatePeriods('Yearly', currentDate, 0, 10, yearEnd)));
        const quarterlyForeHeaders = JSON.stringify(transformPeriods(calculatePeriods('Quarterly', currentDate, 0, 40, yearEnd)));
        const monthlyForeHeaders = JSON.stringify(transformPeriods(calculatePeriods('Monthly', currentDate, 0, 120, yearEnd)));
    
        const emptyArray = '[]';
    
        try {
            const response = await api.post(`/supermodel/${supermodel.id}/add-model`, {
                organisationId: supermodel.organisationId,
                modelType,
                superModelName,
                basis,
                histPeriods,
                forePeriods,
                yearEnd,
                headers: {
                    annualHistHeaders,
                    quarterlyHistHeaders,
                    monthlyHistHeaders,
                    annualForeHeaders,
                    quarterlyForeHeaders,
                    monthlyForeHeaders,
                },
                rows: {
                    annualHistRows: emptyArray,
                    quarterlyHistRows: emptyArray,
                    monthlyHistRows: emptyArray,
                    annualForeRows: emptyArray,
                    quarterlyForeRows: emptyArray,
                    monthlyForeRows: emptyArray,
                    annualForeValues: emptyArray,
                    quarterlyForeValues: emptyArray,
                    monthlyForeValues: emptyArray,
                },
                otherFields: {
                    rowNames: emptyArray,
                    rowVariables: emptyArray,
                    categories: emptyArray,
                    groupedOver: emptyArray,
                    dateColumn: emptyArray,
                    sourceFile: emptyArray,
                    drivers: emptyArray,
                    supportingAssumptionModelIds: emptyArray
                }
            });
    
            if (response.status === 201) {
                console.log('Model updated successfully')
                window.location.reload()
            }
        } catch (error) {
            console.error('Error adding model:', error);
        }
    };
    
    // Function to handle the edit icon click
    const handleEditClick = (modelId, currentName) => {
        setEditMode((prevState) => ({
            ...prevState,
            [modelId]: true,
        }));
        setEditedName((prevState) => ({
            ...prevState,
            [modelId]: currentName,
        }));
    };

    // Function to handle the save action
    const handleSaveName = async (modelId) => {
        try {
            await api.put(`/supermodel/${modelId}/update-name`, {
                superModelName: editedName[modelId],
            });
            setEditMode((prevState) => ({
                ...prevState,
                [modelId]: false,
            }));
            window.location.reload();
        } catch (error) {
            console.error('Error updating supermodel name:', error);
        }
    };
    
    const handleDeleteModel = async (supermodel, modelType) => {
        if (window.confirm('Are you sure you want to delete this model? This action is irreversible.')) {
            try {
                const model = models.find((m) => m.superModelId === supermodel.id && m.modelType === modelType);
                if (model) {
                    const response = await api.delete(`/supermodel/${supermodel.id}/delete-model/${model.id}`);
                    if (response.status === 200) {
                        console.log('Model deleted successfully')
                        window.location.reload()
                    }
                }
            } catch (error) {
                console.error('Error deleting model:', error);
            }
        }
    };  
    
    const handleDeleteSuperModel = async (supermodel) => {
        if (window.confirm(`Are you sure you want to delete the model "${supermodel.superModelName}"? This action is irreversible.`)) {
            try {
                const response = await api.delete(`/supermodel/${supermodel.id}/delete`);
                if (response.status === 200) {
                    console.log('Supermodel deleted successfully');
                    window.location.reload();
                }
            } catch (error) {
                console.error('Error deleting supermodel:', error);
            }
        }
    };

    const renderModelToggle = (supermodel, modelType, isChecked) => (
        <span
            onClick={() => isChecked ? handleDeleteModel(supermodel, modelType) : handleAddModel(supermodel, modelType)}
            style={{
                display: 'inline-block',
                width: '24px',
                height: '24px',
                border: '1px solid #000',
                textAlign: 'center',
                cursor: 'pointer',
                backgroundColor: isChecked ? 'green' : 'transparent',
                color: isChecked ? '#fff' : '#000',
                lineHeight: '24px',
                margin: '0 8px'
            }}
        >
            {isChecked ? '✔️' : ''}
        </span>
    );

    const renderDeleteButton = (supermodel) => (
        <span
            onClick={() => handleDeleteSuperModel(supermodel)}
            style={{
                color: 'red',
                cursor: 'pointer',
                margin: '0 8px',
            }}
        >
            Delete
        </span>
    );
    

    const handleCreateSuperModel = async () => {
        if (!newSuperModel.modelName || !newSuperModel.folderName || (!newSuperModel.profitAndLoss && !newSuperModel.balanceSheet && !newSuperModel.cashFlow)) {
            setError('Please fill in all fields and select at least one model type.');
            return;
        }
    
        const basis = newSuperModel.basis;
        const histPeriods = parseInt(newSuperModel.histPeriods, 10);
        const forePeriods = parseInt(newSuperModel.forePeriods, 10);
        const selectedDate = newSuperModel.yearEnd; // Date object
        
        // Validate that all settings are provided
        if (!basis || isNaN(histPeriods) || isNaN(forePeriods) || !selectedDate) {
            setError('Please provide all model settings.');
            return;
        }

        // Format the adjusted date as 'yyyy-MM-dd'
        const yearEndFormatted = format(selectedDate, 'yyyy-MM-dd');

        const yearEnd = selectedDate; // Keep as Date object for calculations

        // Get the latest date from spreadsheets
        const latestDate = getLatestDateFromSpreadsheets();
        console.log('Latest date from spreadsheets:', latestDate);
        
        if (!latestDate) {
            setError('No spreadsheets with dates found to determine the latest date.');
            return;
        }
        

        // Get the previous year-end date before the latest date
        const fyEndDate = getPreviousYearEndDate(latestDate, yearEnd);

        if (!fyEndDate) {
            setError('Could not determine the financial year-end date before the latest date.');
            return;
        }

        // Use fyEndDate as the currentDate in calculatePeriods
        const currentDate = fyEndDate;
    
        const annualHistHeaders = JSON.stringify(transformPeriods(calculatePeriods('Yearly', currentDate, 10, 0, yearEndFormatted)));
        const quarterlyHistHeaders = JSON.stringify(transformPeriods(calculatePeriods('Quarterly', currentDate, 40, 0, yearEndFormatted)));
        const monthlyHistHeaders = JSON.stringify(transformPeriods(calculatePeriods('Monthly', currentDate, 120, 0, yearEndFormatted)));
    
        const annualForeHeaders = JSON.stringify(transformPeriods(calculatePeriods('Yearly', currentDate, 0, 10, yearEndFormatted)));
        const quarterlyForeHeaders = JSON.stringify(transformPeriods(calculatePeriods('Quarterly', currentDate, 0, 40, yearEndFormatted)));
        const monthlyForeHeaders = JSON.stringify(transformPeriods(calculatePeriods('Monthly', currentDate, 0, 120, yearEndFormatted)));
    
        const emptyArray = '[]';
    
        const modelTypes = [];
    
        if (newSuperModel.profitAndLoss) {
            modelTypes.push('Profit and Loss');
        }
        if (newSuperModel.balanceSheet) {
            modelTypes.push('Balance Sheet');
        }
        if (newSuperModel.cashFlow) {
            modelTypes.push('Cash Flow');
        }
    
        try {
            const response = await api.post('/supermodel/create', {
                organisationId: currentOrg.id,
                superModelName: newSuperModel.modelName,
                modelFolder: newSuperModel.folderName,
                basis,
                histPeriods,
                forePeriods,
                yearEnd: yearEndFormatted,
                modelTypes,
                headers: {
                    annualHistHeaders,
                    quarterlyHistHeaders,
                    monthlyHistHeaders,
                    annualForeHeaders,
                    quarterlyForeHeaders,
                    monthlyForeHeaders,
                },
                rows: {
                    annualHistRows: emptyArray,
                    quarterlyHistRows: emptyArray,
                    monthlyHistRows: emptyArray,
                    annualForeRows: emptyArray,
                    quarterlyForeRows: emptyArray,
                    monthlyForeRows: emptyArray,
                    annualForeValues: emptyArray,
                    quarterlyForeValues: emptyArray,
                    monthlyForeValues: emptyArray,
                },
                otherFields: {
                    rowNames: emptyArray,
                    rowVariables: emptyArray,
                    categories: emptyArray,
                    groupedOver: emptyArray,
                    dateColumn: emptyArray,
                    sourceFile: emptyArray,
                    drivers: emptyArray,
                    supportingAssumptionModelIds: emptyArray
                }
            });
    
            if (response.status === 201) {
                setSupermodels((prevSupermodels) => [...prevSupermodels, response.data.superModel]);
                setNewSuperModel({
                    modelName: '',
                    folderName: '',
                    profitAndLoss: false,
                    balanceSheet: false,
                    cashFlow: false,
                    basis: defaultSettings.defaultBasis,
                    histPeriods: defaultSettings.defaultHistPeriods,
                    forePeriods: defaultSettings.defaultForePeriods,
                    yearEnd: defaultSettings.defaultYearEnd,
                });
                window.location.reload();

            } else {
                setError('Failed to create super model. Please try again.');
            }
        } catch (error) {
            console.error('Failed to create super model:', error);
            setError('Failed to create super model. Please try again.');
        }
    };
      

    const groupedSupermodels = orgSupermodels.reduce((acc, supermodel) => {
        const folder = supermodel.modelFolder;
        if (!acc[folder]) {
            acc[folder] = [];
        }
        acc[folder].push(supermodel);
        return acc;
    }, {});

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <input
            onClick={onClick}
            ref={ref}
            value={value}
            onChange={() => {}}
            placeholder="Select Day and Month"
            readOnly
        />
    ));
    

    return (
        <div className="forecasting-landing-page">
            <h1>Forecasting Dashboard</h1>

            <div className="new-supermodel">
                <h2>Create New Model</h2>
                <input
                    type="text"
                    name="modelName"
                    placeholder="Model Name"
                    value={newSuperModel.modelName}
                    onChange={handleSuperModelChange}
                    autoComplete="off"
                />

                <input
                    list="folderSuggestions"
                    name="folderName"
                    placeholder="Folder Name"
                    value={newSuperModel.folderName}
                    onChange={handleSuperModelChange}
                    autoComplete="off"
                />
                <datalist id="folderSuggestions">
                    {folderOptions.map((folder, index) => (
                        <option key={index} value={folder}>
                            {folder}
                        </option>
                    ))}
                </datalist>

                <div>
                    <label>Basis:</label>
                    <select
                        name="basis"
                        value={newSuperModel.basis}
                        onChange={handleSuperModelChange}
                    >
                        <option value="">Select Basis</option>
                        <option value="Yearly">Yearly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Monthly">Monthly</option>
                    </select>
                </div>
                <div>
                    <label>Historical Periods:</label>
                    <input
                        type="number"
                        name="histPeriods"
                        value={newSuperModel.histPeriods}
                        onChange={handleSuperModelChange}
                        min="1"
                    />
                </div>
                <div>
                    <label>Forecasting Periods:</label>
                    <input
                        type="number"
                        name="forePeriods"
                        value={newSuperModel.forePeriods}
                        onChange={handleSuperModelChange}
                        min="1"
                    />
                </div>
                <div>
                    <label>Financial Year End:</label>
                    <ReactDatePicker
                        selected={newSuperModel.yearEnd}
                        onChange={(date) =>
                            setNewSuperModel({
                                ...newSuperModel,
                                yearEnd: date,
                            })
                        }
                        dateFormat="dd MMMM"
                        showMonthDropdown
                        showYearDropdown={false}
                        placeholderText="Select Day and Month"
                        dropdownMode="select"
                        showPopperArrow={false}
                        isClearable
                        customInput={<CustomInput />}
                    />
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            name="profitAndLoss"
                            checked={newSuperModel.profitAndLoss}
                            onChange={handleSuperModelChange}
                        />
                        Profit and Loss
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            name="balanceSheet"
                            checked={newSuperModel.balanceSheet}
                            onChange={handleSuperModelChange}
                        />
                        Balance Sheet
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            name="cashFlow"
                            checked={newSuperModel.cashFlow}
                            onChange={handleSuperModelChange}
                        />
                        Cash Flow
                    </label>
                </div>
                <button onClick={handleCreateSuperModel}>Create Model</button>
            </div>

            <div className="supermodels-table">
                <h2>Models</h2>
                <div className='scrollable-table-container'>
                    <table className='data-table'>
                    <thead>
                        <tr>
                            <th>Model Name</th>
                            <th>Basis</th>
                            <th>Historical Periods</th>
                            <th>Forecasting Periods</th>
                            <th>Year End</th>
                            <th>Profit and Loss</th>
                            <th>Balance Sheet</th>
                            <th>Cash Flow</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(groupedSupermodels).map(([folder, models]) => (
                            <React.Fragment key={folder}>
                                <tr>
                                    <td colSpan="9" style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                                        {folder}
                                    </td>
                                </tr>
                                {models.map(model => (
                                    <tr key={model.id}>
                                        {/* Model Name */}
                                        <td>
                                            {editMode[model.id] ? (
                                                <>
                                                    <input
                                                        type="text"
                                                        value={editedName[model.id] || model.superModelName}
                                                        onChange={(e) =>
                                                            setEditedName((prevState) => ({
                                                                ...prevState,
                                                                [model.id]: e.target.value,
                                                            }))
                                                        }
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        style={{ marginLeft: '8px', color: 'green', cursor: 'pointer' }}
                                                        onClick={() => handleSaveName(model.id)}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    {model.superModelName}
                                                    <FontAwesomeIcon
                                                        icon={faPen}
                                                        style={{ marginLeft: '8px', cursor: 'pointer' }}
                                                        onClick={() => handleEditClick(model.id, model.superModelName)}
                                                    />
                                                </>
                                            )}
                                        </td>
                                        {/* Basis */}
                                        <td>{model.basis}</td>
                                        {/* Historical Periods */}
                                        <td>{model.histPeriods}</td>
                                        {/* Forecasting Periods */}
                                        <td>{model.forePeriods}</td>
                                        {/* Year End */}
                                        <td>
                                            {model.yearEnd
                                                ? format(parseISO(model.yearEnd), 'd MMMM')
                                                : 'No Date Set'}
                                        </td>
                                        {/* Profit and Loss */}
                                        <td>{renderModelToggle(model, 'Profit and Loss', model.profitAndLoss)}</td>
                                        {/* Balance Sheet */}
                                        <td>{renderModelToggle(model, 'Balance Sheet', model.balanceSheet)}</td>
                                        {/* Cash Flow */}
                                        <td>{renderModelToggle(model, 'Cash Flow', model.cashFlow)}</td>
                                        {/* Delete */}
                                        <td>{renderDeleteButton(model)}</td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ForecastingLandingPage;
