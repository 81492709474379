import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to read the spreadsheetId from URL
import { useAuth } from './authcontext';
import fetchSpreadsheetData from './fetchspreadsheetdata'; // Import the fetchSpreadsheetData function

function DataViewPage() {
  const { spreadsheetId } = useParams(); // Read spreadsheetId from URL
  const { spreadsheets, editedRows } = useAuth(); // Use spreadsheets and editedRows from context
  const [data, setData] = useState({ headers: [], rows: [] });
  const [currentSpreadsheet, setCurrentSpreadsheet] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null); // Handle errors

  useEffect(() => {
    // Find the spreadsheet using the spreadsheetId from the URL
    const spreadsheet = spreadsheets.find(sheet => sheet.id === parseInt(spreadsheetId));
    if (spreadsheet) {
      setCurrentSpreadsheet(spreadsheet);
    }
  }, [spreadsheets, spreadsheetId]);

  useEffect(() => {
    const fetchData = async () => {
      if (currentSpreadsheet) {
        try {
          // Fetch the spreadsheet data using fetchSpreadsheetData, applying edits and calculating custom columns
          const fetchedData = await fetchSpreadsheetData(currentSpreadsheet.id, currentSpreadsheet, editedRows, true, true);

          // Update the state with the filtered headers and processed rows
          setData({ headers: fetchedData.filteredHeaders, rows: fetchedData.processedRows });
        } catch (error) {
          console.error('Error fetching spreadsheet data:', error);
          setErrorMessage('An error occurred while fetching data.');
        }
      }
    };

    fetchData();
  }, [currentSpreadsheet, editedRows]);

  return (
    <div>
      <div className="homepage-top-area">
        <h1>Data View</h1>
        <h2>View your data here</h2>
      </div>
      {errorMessage && <div className="error">{errorMessage}</div>}
      {data.headers.length > 0 && data.rows.length > 0 ? (
        <div className='scrollable-table-container'>
          <table className='data-table'>
            <thead>
              <tr>
                {data.headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => <td key={cellIndex}>{cell}</td>)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
}

export default DataViewPage;
