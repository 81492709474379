import React from 'react';
import CustomPivotTableInModel from './custompivottableinmodel';
import { useAuth } from '../authcontext';

const ModelRenderer = ({ rowsData, tableHeaders, histPeriods, forePeriods, onRowClick }) => {
  const { pivottables, currentModel } = useAuth();

  const renderRow = (row, rowIndex) => {
    // **Check for Pivot Table Row**
    if (row.isPivot) {
      const pivotId = row.pivotTableId;
      const pivotData = pivottables.find(pivot => pivot.id === pivotId);

      if (!pivotData) {
        return (
          <tr key={`pivot-${pivotId}`}>
            <td colSpan={tableHeaders.length} style={{ textAlign: 'center', color: 'red' }}>
              Pivot table not found or loading...
            </td>
          </tr>
        );
      }

      return (
        <React.Fragment key={`pivot-${pivotId}`}>
          <CustomPivotTableInModel
            pivotData={pivotData}
            histPeriods={histPeriods}
            forePeriods={forePeriods}
            basis={currentModel.basis}
            onCellClick={(columnIndex) => onRowClick(row, rowIndex, columnIndex)} // Pass the column index when clicked
          />
        </React.Fragment>
      );
    }

    // **Handle Regular Row**
    return (
      <tr key={rowIndex}>
        {/* Row name column (first column) */}
        <td onClick={() => onRowClick(row, rowIndex, 0)}>{row.name}</td>
        {/* Driver column (second column) */}
        <td onClick={() => onRowClick(row, rowIndex, 1)}>{row.driver}</td>
        {/* Historical and Forecasting columns */}
        {Array.isArray(row.values) && row.values.map((value, index) => {
          const isForecastingColumn = index >= row.values.length - forePeriods;
          return (
            <td
              key={index}
              className={isForecastingColumn ? 'forecasting-column' : ''}
              onClick={() => onRowClick(row, rowIndex, index + 2)} // Adjust for the first two columns
            >
              {value}
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <table className='data-table data-table--model'>
      <thead>
        <tr>
          {tableHeaders.map((header, index) => {
            const isForecastingHeader = index >= tableHeaders.length - forePeriods;
            return (
              <th key={index} className={isForecastingHeader ? 'forecasting-column' : ''}>
                {header}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {Array.isArray(rowsData) && rowsData.map((row, rowIndex) => renderRow(row, rowIndex))}
      </tbody>
    </table>
  );
};

export default ModelRenderer;
