import React, {useState, useEffect} from 'react'
import api from '../api';
import { useNavigate } from 'react-router-dom';

function Registration() {
    const [inputs, setInputs] = useState({ email: '', password: '', confirmPassword: '', firstName: '', lastName: '' });
    const [submitted, setSubmitted] = useState(false);
    const navigate = useNavigate()
    let latestRequestToken = 0;

    useEffect(() => {
        // Function to warm up the email service
        const warmUpEmailService = async () => {
            try {
                await api.get('/emailconfigcheck');
                console.log('Email service warmed up successfully');
            } catch (error) {
                console.error('Error warming up email service:', error);
            }
        };

        warmUpEmailService();
    }, []);

    // Handler to navigate to the login page
    const handleLogin = () => {
        navigate('/');
    };

    // Handler to navigate to the reset password page
    const handleResetPassword = () => {
        navigate('/requestreset');
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(inputs.password !== inputs.confirmPassword) {
            alert('Passwords do not match.');
            return;
        }
        setSubmitted(true);

        const currentRequestToken = Date.now(); // Use the current timestamp as a unique identifier
        latestRequestToken = currentRequestToken; // Update the latest request token

        try {
            const response = await api.post('/register', inputs);
            if (latestRequestToken !== currentRequestToken) {
                // This was not the latest request, so ignore its response
                return;
            }
            // Check response status and message
            if (response.data.status === 'error') {
                alert(`Error: ${response.data.message}`);
            } else {
                // Handle success response
                console.log(response.data); // Handle response from Flask
                alert(`Success: ${response.data.message}`);
                // Optionally redirect the user or clear the form
            }
        } catch (error) {
            if (latestRequestToken !== currentRequestToken) {
                // This was not the latest request, so ignore its response
                return;
            }
            console.error('There was an error submitting the registration form:', error.response);
            // Here you can check if the error response contains specific messages
            if (error.response && error.response.data) {
                alert(`Failed to submit the registration form: ${error.response.data.message}`);
            } else {
                alert('Failed to submit the registration form. Please try again.');
            }
        } finally {
            if (latestRequestToken === currentRequestToken) {
                setSubmitted(false); // Reset submission state only if this is the latest request
            }
        }
    };

    return (
        <div className='login-page'>
            <div className='login-container'>
                <div className={`login-content registration-content`}>
                    <h1 style={{textAlign: 'center'}}>Register</h1>
                    <form onSubmit={handleSubmit}>
                        <input
                            name="email"
                            type="email"
                            value={inputs.email}
                            onChange={handleChange}
                            placeholder="Email"
                            required
                        />
                        <div className='input-two-wide'>
                        <input
                            name="firstName"
                            type="text"
                            value={inputs.firstName}
                            onChange={handleChange}
                            placeholder="First Name"
                            required
                        />
                        <input
                            name="lastName"
                            type="text"
                            value={inputs.lastName}
                            onChange={handleChange}
                            placeholder="Last Name"
                            required
                        />
                        </div>
                        <div className='input-two-wide'>
                        <input
                            name="password"
                            type="password"
                            value={inputs.password}
                            onChange={handleChange}
                            placeholder="Password"
                            required
                        />
                        <input
                            name="confirmPassword"
                            type="password"
                            value={inputs.confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirm Password"
                            required
                        />
                        </div>
                        <button type="submit">Register</button>
                    </form>
                    {submitted && <p>Registration Submitted</p>}
                    <div className="button-group">
                        <button onClick={handleLogin} className="half-width-button">Log In</button>
                        <button onClick={handleResetPassword} className="half-width-button">Reset your password</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Registration;