import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authcontext';
import api from './api';

function Navigation() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found in localStorage');
      }

      console.log('Sending logout request with token:', token);  // Debugging log

      await api.post('/logout', {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      console.log('Logout response: success');  // Debugging log

      logout();
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      navigate('/');  // Redirect to login page after logout
    } catch (error) {
      console.error('Logout failed:', error);
      alert('Logout failed. Please try again.');
      // Optionally force logout by clearing tokens and redirecting
      logout();
      navigate('/');
    }
  };

  return (
    <nav>
      <div className='nav-dropdown-section'>
        <div className="nav-dropdown">
          <button className='nav-button' onClick={() => navigate('/home')}>Home</button>
        </div>
        <div className="nav-dropdown">
          <button className='nav-button' onClick={() => navigate('/datainput')}>Data Cleansing</button>
        </div>
        <div className="nav-dropdown">
          <button className='nav-button' onClick={() => navigate('/dataanalysis')}>Data Analysis</button>
        </div>
        <div className="nav-dropdown">
          <button className='nav-button' onClick={() => navigate('/forecastinglanding')}>Forecasting</button>
        </div>
        <div className="nav-dropdown">
          <button className='nav-button' onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
