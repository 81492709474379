import { create, all } from 'mathjs';

const math = create(all, {});

// Helper to replace IF statements with ternary operators
const replaceIF = (formula) => {
    return formula.replace(/IF\s*\(([^,]+),([^,]+),([^)]+)\)/gi, (match, condition, valueIfTrue, valueIfFalse) => {
        // Replace '=' with '===' and '<>' with '!=='
        let jsCondition = condition.trim().replace(/([^<>=]+)=([^=]+)/g, '$1 === $2').replace(/<>/g, '!==');
        return `(${jsCondition} ? ${valueIfTrue.trim()} : ${valueIfFalse.trim()})`;
    });
};

// Helper to replace logical operators AND, OR with &&, ||
const replaceLogicalOperators = (formula) => {
    return formula.replace(/AND/gi, '&&').replace(/OR/gi, '||');
};

// Helper function to escape special regex characters in a string
const escapeRegExp = (string) => {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const evaluateSumFormula = (formula, rowData, currentPeriod) => {
  console.log('Original Formula:', formula);

  // Replace IF statements with ternary operators
  let processedFormula = replaceIF(formula);
  console.log('After replaceIF:', processedFormula);

  // Replace logical operators AND, OR with &&, ||
  processedFormula = replaceLogicalOperators(processedFormula);
  console.log('After replaceLogicalOperators:', processedFormula);

  // Extract row names enclosed in single quotes
  const rowNames = processedFormula.match(/'([^']+)'/g)?.map(name => name.replace(/'/g, '')) || [];
  console.log('Row Names found:', rowNames);

  // Replace row names with their corresponding values for the current period
  rowNames.forEach(rowName => {
    if (!(rowName in rowData)) {
      throw new Error(`Row "${rowName}" does not exist.`);
    }
    const value = parseFloat(rowData[rowName][currentPeriod]);
    if (isNaN(value)) {
      throw new Error(`Invalid numeric value for row "${rowName}" in period "${currentPeriod}".`);
    }
    // Escape special characters in rowName to safely use in RegExp
    const escapedRowName = escapeRegExp(rowName);
    // Create a regex that matches the exact quoted row name
    const regex = new RegExp(`'${escapedRowName}'`, 'g');
    // Replace all occurrences of the quoted row name with its numeric value
    processedFormula = processedFormula.replace(regex, value);
    console.log(`Replaced '${rowName}' with ${value}:`, processedFormula);
  });

  console.log('Final Processed Formula:', processedFormula);

  try {
    const result = math.evaluate(processedFormula);
    console.log('Evaluation Result:', result);
    return result;
  } catch (error) {
    console.error('Evaluation Error:', error);
    throw new Error(`Error evaluating formula: ${error.message}`);
  }
};
