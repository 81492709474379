import React, { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import api from './api';
import { useAuth } from './authcontext';
import * as XLSX from 'xlsx';

function DataInputPage() {
    const { currentOrg, spreadsheets, setCurrentSpreadsheet, userData } = useAuth();
    const [suborganisation, setSuborganisation] = useState('');
    const [suborganisationFocus, setSuborganisationFocus] = useState(false);
    const [editableSpreadsheetId, setEditableSpreadsheetId] = useState(null);
    const [editedSpreadsheetName, setEditedSpreadsheetName] = useState('');
    const [editableSubOrgId, setEditableSubOrgId] = useState(null);
    const [editedSubOrgName, setEditedSubOrgName] = useState('');
    const [sheetsInfo, setSheetsInfo] = useState([]);
    const [fileName, setFileName] = useState('');
    const [expandedSuborgs, setExpandedSuborgs] = useState({}); // State to track expanded/collapsed suborganisations
    const [selectedFile, setSelectedFile] = useState(null);

    const fileInputRef = useRef(null);
    const inputRef = useRef(null);
    const navigate = useNavigate();

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
    
        if (!file) {
            // User clicked cancel on file selection, retain the previous file
            console.log('File selection was canceled, keeping the previously selected file.');
            return;
        }
    
        // If a new file is selected, proceed with reading and setting the file
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetNames = workbook.SheetNames;
    
            if (sheetNames.length === 1) {
                setSheetsInfo([{
                    sheet_name: sheetNames[0],
                    display_name: file.name.replace(/\.[^/.]+$/, ""),
                    selected: true,
                    dataLayout: 'Table'
                }]);
            } else {
                const initialSheetsInfo = sheetNames.map(sheetName => ({
                    sheet_name: sheetName,
                    display_name: sheetName,
                    selected: false
                }));
                setSheetsInfo(initialSheetsInfo);
            }
        };
    
        reader.onerror = (e) => {
            console.error('Error reading file:', e);
        };
    
        reader.readAsArrayBuffer(file);
        setFileName(file.name);  // Update the fileName state to the newly selected file
        setSelectedFile(file);  // Update the selectedFile state to the newly selected file
    };       

    const handleChooseFileClick = () => {
        fileInputRef.current.click();
    };

    const handleToggleSuborg = (subOrg) => {
        setExpandedSuborgs(prevState => ({
            ...prevState,
            [subOrg]: !prevState[subOrg]  // Toggle the expanded state of the suborganisation
        }));
    };

    const handleSheetSelectionChange = (index) => {
        const updatedSheetsInfo = [...sheetsInfo];
        updatedSheetsInfo[index].selected = !updatedSheetsInfo[index].selected;
        setSheetsInfo(updatedSheetsInfo);
    };

    const handleDisplayNameChange = (index, newName) => {
        const updatedSheetsInfo = [...sheetsInfo];
        updatedSheetsInfo[index].display_name = newName;
        setSheetsInfo(updatedSheetsInfo);
    };

    const handleSaveEditedSpreadsheetName = async (spreadsheetId) => {
        const token = localStorage.getItem('token');
        try {
            await api.put(`/spreadsheets/${spreadsheetId}`, {
                newName: editedSpreadsheetName
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setEditableSpreadsheetId(null);
            setEditedSpreadsheetName('');
            window.location.reload();
        } catch (error) {
            console.error('Error updating spreadsheet name:', error);
        }
    };

    const handleEditSpreadsheetName = (spreadsheetId, currentName) => {
        setEditableSpreadsheetId(spreadsheetId);
        setEditedSpreadsheetName(currentName);
    };

    const handleDataLayoutChange = (index, newLayout) => {
        const updatedSheetsInfo = [...sheetsInfo];
        updatedSheetsInfo[index].dataLayout = newLayout;
        setSheetsInfo(updatedSheetsInfo);
    };

    const handleSpreadsheetClick = (spreadsheet) => {
        setCurrentSpreadsheet(spreadsheet);
        navigate(`/dataview/${spreadsheet.id}`); // Navigate to /dataview/:spreadsheetId
      };

    const handleSaveEditedSubOrgName = async (spreadsheetId) => {
        const token = localStorage.getItem('token');
        try {
            await api.put(`/spreadsheets/${spreadsheetId}/suborganisation`, {
                newSubOrgName: editedSubOrgName
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setEditableSubOrgId(null);
            setEditedSubOrgName('');
            window.location.reload();
        } catch (error) {
            console.error('Error updating suborganisation name:', error);
        }
    };

    const handleEditSubOrgName = (spreadsheetId, currentName) => {
        setEditableSubOrgId(spreadsheetId);
        setEditedSubOrgName(currentName);
    };

    const handleDeleteSpreadsheet = async (spreadsheetId) => {
        const token = localStorage.getItem('token');
        try {
            await api.delete(`/spreadsheets/${spreadsheetId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            window.location.reload();
        } catch (error) {
            console.error('Error deleting spreadsheet:', error);
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            console.error('No file selected for upload.');
            alert('Please select a file to upload.');
            return; // Prevent upload if no file is selected
        }
    
        const selectedSheets = sheetsInfo.filter(sheet => sheet.selected);
        if (selectedSheets.length === 0) {
            alert('Please select at least one sheet to upload.');
            return;
        }
    
        const formData = new FormData();
        formData.append('file', selectedFile);  // Use the selected file from state
        formData.append('suborganisation', suborganisation || '');  // Use suborganisation or default to empty string
        formData.append('organisation_id', currentOrg.id);
        formData.append('sheets_info', JSON.stringify(sheetsInfo));
    
        const token = localStorage.getItem('token');
    
        try {
            const response = await api.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });
    
            // Ensure response and spreadsheet_data are valid before proceeding
            if (response && response.data && response.data.status === 'success') {
                const { spreadsheet_data } = response.data;
    
                if (Array.isArray(spreadsheet_data) && spreadsheet_data.length > 0) {
                    const spreadsheetIds = spreadsheet_data.map(sheet => sheet.spreadsheet_id);
                    const spreadsheetDataEncoded = encodeURIComponent(JSON.stringify(spreadsheet_data));
    
                    if (spreadsheetIds.length === 1) {
                        // Direct to column selection for a single sheet with spreadsheet data in the URL
                        navigate(`/columnselection/${spreadsheetIds[0]}?data=${spreadsheetDataEncoded}`);
                    } else {
                        // Navigate to the first sheet with all sheet ids and data in the URL for multiple sheets
                        navigate(`/columnselection/${spreadsheetIds[0]}?all=${spreadsheetIds.join(',')}&data=${spreadsheetDataEncoded}`);
                    }
                } else {
                    console.error('Unexpected response format or missing spreadsheet data:', response.data);
                    alert('There was an issue with the upload. Please try again.');
                }
            } else if (response && response.data) {
                // **Known error**: Show the error message returned by the backend
                console.error('Upload failed:', response.data);
                alert(`Error uploading file: ${response.data.error || 'Unknown error'}`);
            } else {
                console.error('Unexpected response format:', response);
                alert('Error uploading file: Unknown error');
            }
        } catch (error) {
            // Handle specific backend error responses
            if (error.response) {
                console.error('Server responded with a status:', error.response.status);
                
                // **Known error from backend**: Show a more user-friendly message
                if (error.response.data && error.response.data.error) {
                    alert(`Error uploading file: ${error.response.data.error}`);
                } else {
                    // **Unknown backend error**: Fallback to general error message
                    alert('Error uploading file: Unknown error occurred during file upload.');
                }
            } else if (error.request) {
                console.error('No response received:', error.request);
                alert('Error uploading file: No response received from server.');
            } else {
                console.error('Error setting up request:', error.message);
                alert('Error uploading file: ' + error.message);
            }
        }
    };    

    const handleEditColumns = (spreadsheetId) => {
        navigate(`/columnselection/${spreadsheetId}`);
    };

    // Sort and group spreadsheets by suborganisation and uploadDate
    const groupedSpreadsheets = spreadsheets
        .filter(s => s.organisationId === currentOrg.id)
        .sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)) // Sort by uploadDate (newest to oldest)
        .reduce((groups, spreadsheet) => {
            const subOrg = spreadsheet.suborganisation || 'No Suborganisation';
            if (!groups[subOrg]) {
                groups[subOrg] = [];
            }
            groups[subOrg].push(spreadsheet);
            return groups;
        }, {});

    return (
        <div>
            <div>
                <h1>Data Input</h1>
            </div>
            <div className="input-group">
                <label>Upload File:</label>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    style={{ display: 'none' }}
                />
                <label className="custom-file-upload" onClick={handleChooseFileClick}>
                    Choose File
                </label>
                {fileName && <span>{fileName}</span>}
            </div>
            <div className="input-section">
            {fileName && (
                <div className="input-group">
                    <label>Suborganisation Name:</label>
                    <input
                        type="text"
                        value={suborganisation}
                        onChange={(e) => setSuborganisation(e.target.value)}
                        onFocus={() => setSuborganisationFocus(true)}
                        onBlur={() => setSuborganisationFocus(false)}
                        placeholder="Enter suborganisation name (optional)"
                    />
                    {suborganisationFocus && (
                        <ul className="dropdown">
                            {Object.keys(groupedSpreadsheets).map((org, index) => (
                                <li key={index} onMouseDown={() => setSuborganisation(org)}>{org}</li>
                            ))}
                        </ul>
                    )}
                </div>
                )}

                {sheetsInfo.length > 0 && (
                    <div className="input-group">
                        <label>Sheets:</label>
                        {sheetsInfo.map((sheet, index) => (
                            <div key={index} className="sheet-selection">
                                {sheetsInfo.length > 1 ? (
                                    <>
                                        <input
                                            type="checkbox"
                                            checked={sheet.selected || false}
                                            onChange={() => handleSheetSelectionChange(index)}
                                        />
                                        <span>{sheet.sheet_name}</span>
                                    </>
                                ) : (
                                    <span>{sheet.sheet_name}</span>
                                )}
                                {sheet.selected && (
                                    <>
                                        <input
                                            type="text"
                                            value={sheet.display_name}
                                            onChange={(e) => handleDisplayNameChange(index, e.target.value)}
                                            placeholder="Enter display name"
                                        />
                                        <select
                                            value={sheet.dataLayout}
                                            onChange={(e) => handleDataLayoutChange(index, e.target.value)}
                                            style={{
                                                fontSize: '14px',
                                                padding: '8px',
                                                width: '150px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                                borderRadius: '4px',
                                                border: '1px solid #ccc',
                                            }}
                                        >
                                            <option value="Table">Table</option>
                                            <option value="Time Series">Time Series</option>
                                        </select>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                )}

                {fileName && (
                    <button className="custom-file-upload" onClick={handleUpload}>
                        Upload
                    </button>
                )}
            </div>
            <h3>Uploaded Spreadsheets</h3>
            <div className='scrollable-table-container'>
                <table className='data-table'>
                    <thead>
                        <tr>
                            <th>Spreadsheet Name</th>
                            <th>Suborganisation Name</th>
                            <th>Original File Name</th>
                            <th>Edit Columns</th>
                            <th>Delete</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(groupedSpreadsheets).map(([subOrg, spreadsheets]) => (
                            <React.Fragment key={subOrg}>
                                <tr key={`suborg-${subOrg}`} onClick={() => handleToggleSuborg(subOrg)}>
                                    <td colSpan="5" style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', cursor: 'pointer' }}>
                                        <FontAwesomeIcon
                                            icon={expandedSuborgs[subOrg] ? faChevronDown : faChevronRight}
                                            style={{ marginRight: '8px' }}
                                        />
                                        {subOrg}
                                    </td>
                                </tr>
                                {expandedSuborgs[subOrg] && spreadsheets.map(spreadsheet => (
                                    <tr key={spreadsheet.id}>
                                        <td>
                                            {editableSpreadsheetId === spreadsheet.id ? (
                                                <div className="spreadsheet-name-container">
                                                    <input
                                                        ref={inputRef}
                                                        type="text"
                                                        value={editedSpreadsheetName}
                                                        onChange={(e) => setEditedSpreadsheetName(e.target.value)}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="icon"
                                                        onClick={() => handleSaveEditedSpreadsheetName(spreadsheet.id)}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="spreadsheet-name-container">
                                                    <Link to={`/dataview/${spreadsheet.id}`} onClick={() => handleSpreadsheetClick(spreadsheet)}>
                                                        {spreadsheet.newName}
                                                    </Link>
                                                    <FontAwesomeIcon
                                                        icon={faPen}
                                                        className="icon"
                                                        onClick={() => handleEditSpreadsheetName(spreadsheet.id, spreadsheet.newName)}
                                                    />
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            {editableSubOrgId === spreadsheet.id ? (
                                                <div className="spreadsheet-name-container">
                                                    <input
                                                        ref={inputRef}
                                                        type="text"
                                                        value={editedSubOrgName}
                                                        onChange={(e) => setEditedSubOrgName(e.target.value)}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        className="icon"
                                                        onClick={() => handleSaveEditedSubOrgName(spreadsheet.id)}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="spreadsheet-name-container">
                                                    {spreadsheet.suborganisation}
                                                    <FontAwesomeIcon
                                                        icon={faPen}
                                                        className="icon"
                                                        onClick={() => handleEditSubOrgName(spreadsheet.id, spreadsheet.suborganisation)}
                                                    />
                                                </div>
                                            )}
                                        </td>
                                        <td>{spreadsheet.originalName}</td>
                                        <td>
                                            <a onClick={() => handleEditColumns(spreadsheet.id)} className="edit-link">
                                                Edit
                                            </a>
                                        </td>
                                        <td>
                                            <a onClick={() => handleDeleteSpreadsheet(spreadsheet.id)} className="delete-link">
                                                Delete
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default DataInputPage;
