import moment from 'moment';

export const calculatePeriods = (basis, currentDate, histPeriods, forePeriods, yearEnd) => {
    const periods = [];
    const currentMoment = moment(currentDate);
    const totalPeriods = parseInt(histPeriods, 10) + parseInt(forePeriods, 10);

    const [yearEndDay, yearEndMonth] = yearEnd.split('-').reverse().map(Number);

    // Determine the correct fiscal year start based on the current date
    let fiscalYearStart = moment({ year: currentMoment.year(), month: yearEndMonth - 1, day: yearEndDay }).add(1, 'day');

    if (currentMoment.isBefore(fiscalYearStart)) {
        fiscalYearStart.subtract(1, 'year');
    }

    let fiscalYear = fiscalYearStart.year();

    if (basis === 'Yearly') {
        for (let i = -histPeriods; i < forePeriods; i++) {
            const startYear = fiscalYear + i;
            const fyStart = moment({ year: startYear, month: yearEndMonth - 1, day: yearEndDay }).add(1, 'day');
            const fyEnd = moment({ year: startYear + 1, month: yearEndMonth - 1, day: yearEndDay });
            const yearLabel = fyEnd.year().toString();
            periods.push({ label: yearLabel, startDate: fyStart, endDate: fyEnd });
        }
    } else if (basis === 'Quarterly') {
        const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
        const quarterStartMonths = [0, 3, 6, 9];

        let currentQuarterIndex = -1;
        for (let i = 0; i < 4; i++) {
            const quarterStart = fiscalYearStart.clone().add(quarterStartMonths[i], 'months');
            const quarterEnd = quarterStart.clone().add(3, 'months').subtract(1, 'day');
            if (currentMoment.isBetween(quarterStart, quarterEnd, null, '[)')) {
                currentQuarterIndex = i;
                break;
            }
        }

        if (currentQuarterIndex === -1) {
            currentQuarterIndex = 0;
        }

        const startQuarterIndex = (currentQuarterIndex + 4 - histPeriods % 4) % 4;
        fiscalYearStart = fiscalYearStart.clone().subtract(Math.floor((histPeriods - startQuarterIndex - 1) / 4), 'years');
        let fiscalYearEnd = fiscalYearStart.clone().add(1, 'year').subtract(1, 'day')
        fiscalYear = fiscalYearEnd.year()

        for (let i = 0; i < totalPeriods; i++) {
            const quarterIndex = (startQuarterIndex + i) % 4;

            const startYearAdjustment = Math.floor((startQuarterIndex + i) / 4);

            const quarterStart = fiscalYearStart.clone().add(quarterStartMonths[quarterIndex], 'months').add(startYearAdjustment - 1, 'years');
            const quarterEnd = quarterStart.clone().add(3, 'months').subtract(1, 'day');

            const quarterLabel = `${quarters[quarterIndex]} ${fiscalYear - 1}`;

            periods.push({ label: quarterLabel, startDate: quarterStart, endDate: quarterEnd });

            if (quarterIndex === 3) {
                fiscalYear++;
            }
        }
    } else if (basis === 'Monthly') {
        const startMoment = currentMoment.clone().startOf('month').subtract(histPeriods, 'months');
        for (let i = 0; i < totalPeriods; i++) {
            const periodStart = startMoment.clone().startOf('month');
            const periodEnd = startMoment.clone().endOf('month');
            periods.push({ label: periodStart.format('MM/YYYY'), startDate: periodStart, endDate: periodEnd });
            startMoment.add(1, 'month');
        }
    }

    return periods;
};
