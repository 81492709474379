import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

function DataSidebar() {
  const location = useLocation();

  // Determine the current page
  const isActive = (path) => location.pathname === path;

  return (
    <div className="sidebar">
      <NavLink
        to="/datainput"
        className={`sidebar-button ${isActive('/datainput') ? 'active' : ''}`}
      >
        Input Data
      </NavLink>

      <NavLink
        to="/columnmapping"
        className={`sidebar-button ${isActive('/columnmapping') ? 'active' : ''}`}
      >
        Column Mapping
      </NavLink>

      <NavLink
        to="/rowmapping"
        className={`sidebar-button ${isActive('/rowmapping') ? 'active' : ''}`}
      >
        Row Mapping
      </NavLink>

      <NavLink
        to="/customcolumns"
        className={`sidebar-button ${isActive('/customcolumns') ? 'active' : ''}`}
      >
        Custom Columns
      </NavLink>
    </div>
  );
}

export default DataSidebar;
