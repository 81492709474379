import React, { useState, useEffect } from 'react';
import { useAuth } from '../authcontext';
import AddRowForm from './addrow';
import api from '../api'; // Assuming you have an API utility for making requests
import { useParams, useNavigate } from 'react-router-dom'; 

const ForecastingPage = () => {
    const { modelId } = useParams(); // Get the modelId from the URL
    const { currentModel, setCurrentModel, spreadsheets, models, isLoading: isModelsLoading } = useAuth();
    const [tableHeaders, setTableHeaders] = useState([]);
    const [rowsData, setRowsData] = useState([]);
    const [showAddRowPopup, setShowAddRowPopup] = useState(false);
    const [showDetailedRowForm, setShowDetailedRowForm] = useState(false); // Controls detailed row form
    const [showFormulaBuildForm, setShowFormulaBuildForm] = useState(false); // Controls formula build form
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null); // State to hold the clicked row data
    const [detailedRowName, setDetailedRowName] = useState(''); // State for the custom row name
    const [formulaBuildName, setFormulaBuildName] = useState(''); // State for the formula build name
    const navigate = useNavigate();

    // Fetch the model whenever the modelId changes and models are loaded
    useEffect(() => {
        // Fetch the model only if modelId is present and models have been loaded
        if (modelId && !isModelsLoading && models.length > 0) {
            fetchModel(modelId);
        } else if (!isModelsLoading && models.length === 0) {
            // Optionally fetch the models again if not loaded (depending on context)
            console.warn('No models available.');
        }
    }, [modelId, isModelsLoading, models]);
       

    const fetchModel = async (modelId) => {
        try {
            setIsLoading(true);
            const model = models.find((m) => m.id === parseInt(modelId, 10));

            if (model) {
                setCurrentModel(model); // Set the new model in context
                const histHeaders = getHeadersByBasis(model, 'Hist');
                const foreHeaders = getHeadersByBasis(model, 'Fore');

                const headers = ['Row name', 'Driver', ...histHeaders.slice(-model.histPeriods), ...foreHeaders.slice(0, model.forePeriods)];
                setTableHeaders(headers);

                processRowsData(model, model.histPeriods, model.forePeriods);
            } else {
                console.error('Model not found for modelId:', modelId);
            }
        } catch (error) {
            console.error('Error fetching model:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const getHeadersByBasis = (model, type) => {
        let headers = [];
        if (!model) {
            console.error('Model is undefined');
            return headers;
        }
    
        try {
            let rawHeaders;
            switch (model.basis) {
                case 'Yearly':
                    rawHeaders = type === 'Hist' ? model.annualHistHeaders : model.annualForeHeaders;
                    break;
                case 'Quarterly':
                    rawHeaders = type === 'Hist' ? model.quarterlyHistHeaders : model.quarterlyForeHeaders;
                    break;
                case 'Monthly':
                    rawHeaders = type === 'Hist' ? model.monthlyHistHeaders : model.monthlyForeHeaders;
                    break;
                default:
                    console.error('Invalid basis specified in the model');
                    return [];
            }
    
            headers = JSON.parse(rawHeaders);
    
            if (!Array.isArray(headers)) {
                console.error('Headers are not an array:', headers);
                return [];
            }
    
            return headers.map(header => Array.isArray(header) ? header[0] : header.label || '');
        } catch (error) {
            console.error('Error parsing headers:', error);
            return [];
        }
    };

    const processRowsData = async (model, histPeriodsCount, forePeriodsCount) => {
        if (!model) {
            console.error('Model is undefined');
            setRowsData([]);
            return;
        }
    
        console.log('Processing rows data...');
        setIsLoading(true);
        const rows = [];
    
        const {
            rowNames = [],
            drivers = [],
            annualHistRows = [],
            quarterlyHistRows = [],
            monthlyHistRows = [],
            annualForeRows = [],
            quarterlyForeRows = [],
            monthlyForeRows = [],
        } = model;
    
        if (!Array.isArray(rowNames) || rowNames.length === 0) {
            console.warn('No rows found in the model');
            setRowsData([]); 
            setIsLoading(false); 
            return;
        }
    
        let histRowsData, foreRowsData;
        switch (model.basis) {
            case 'Yearly':
                histRowsData = JSON.parse(annualHistRows);
                foreRowsData = JSON.parse(annualForeRows);
                break;
            case 'Quarterly':
                histRowsData = JSON.parse(quarterlyHistRows);
                foreRowsData = JSON.parse(quarterlyForeRows);
                break;
            case 'Monthly':
                histRowsData = JSON.parse(monthlyHistRows);
                foreRowsData = JSON.parse(monthlyForeRows);
                break;
            default:
                console.error('Invalid basis specified in the model');
                setRowsData([]);
                setIsLoading(false);
                return;
        }
    
        // Define the driver types that are stored as arrays
        const arrayStoredDrivers = ['Fixed Growth', 'Percent', 'Percent of Another Row', 'Formula of Rows'];
    
        // **Updated For Loop Starts Here**
        for (let i = 0; i < rowNames.length; i++) {
            const rowName = rowNames[i];
    
            // **Skip Pivot Table Rows**
            if (Array.isArray(rowName) && rowName[0] === 'Pivot') {
                console.log(`Skipping pivot row at index ${i}:`, rowName);
                continue; // Skip to the next iteration
            }
    
            // **Extract Driver Name**
            let driverName = '';
            if (Array.isArray(drivers[i]) && arrayStoredDrivers.includes(drivers[i][0])) {
                driverName = drivers[i][0]; // Extract only the driver name
            } else {
                driverName = drivers[i] || ''; // Use the driver as-is if it's a string
            }
    
            const rowData = {
                name: rowName || '',
                driver: driverName,
                values: [],
            };
    
            // **Process Historical Data**
            if (histRowsData && Array.isArray(histRowsData[i])) {
                const histValues = histRowsData[i].slice(-histPeriodsCount);
                rowData.values.push(...histValues);
            }
    
            // **Process Forecast Data Directly from foreRowsData**
            if (foreRowsData && Array.isArray(foreRowsData[i])) {
                const foreValues = foreRowsData[i].slice(0, forePeriodsCount);
                rowData.values.push(...foreValues);
            }
    
            rows.push(rowData);
        }
        // **Updated For Loop Ends Here**
    
        setRowsData(rows);
        setIsLoading(false);
    };    
        

    const handleRowClick = (row, rowIndex) => {
        // Ensure the supportingAssumptionModelIds is parsed correctly as JSON
        let supportingAssumptionModelIds = [];
      
        try {
            supportingAssumptionModelIds = JSON.parse(currentModel.supportingAssumptionModelIds);
        } catch (error) {
            console.error('Error parsing supportingAssumptionModelIds:', error);
        }
    
        // Check if the driver indicates a related model type
        if (row.driver === 'Supporting Assumptions' || row.driver === 'Formula Build') {
            const relatedModelId = supportingAssumptionModelIds?.[rowIndex]; // Safely access the ID using rowIndex
            if (relatedModelId) {
                // Determine the route suffix based on the driver
                const routeSuffix = row.driver === 'Supporting Assumptions' ? 'sa' : 'fb';
                navigate(`/forecasting/${relatedModelId}/${routeSuffix}`);
            } else {
                console.warn(`No related model ID found for this row with driver "${row.driver}"`);
            }
        } else {
            const modelBasis = currentModel.basis;

            // Extract historical and forecast data based on model's basis
            let historicalData = [];
            let forecastData = [];
            let forecastValues = [];
        
            switch (modelBasis) {
                case 'Yearly':
                    historicalData = JSON.parse(currentModel.annualHistRows)[rowIndex] || [];
                    forecastData = JSON.parse(currentModel.annualForeRows)[rowIndex] || [];
                    forecastValues = JSON.parse(currentModel.annualForeValues)[rowIndex] || [];
                    break;
                case 'Quarterly':
                    historicalData = JSON.parse(currentModel.quarterlyHistRows)[rowIndex] || [];
                    forecastData = JSON.parse(currentModel.quarterlyForeRows)[rowIndex] || [];
                    forecastValues = JSON.parse(currentModel.quarterlyForeValues)[rowIndex] || [];
                    break;
                case 'Monthly':
                    historicalData = JSON.parse(currentModel.monthlyHistRows)[rowIndex] || [];
                    forecastData = JSON.parse(currentModel.monthlyForeRows)[rowIndex] || [];
                    forecastValues = JSON.parse(currentModel.monthlyForeValues)[rowIndex] || [];
                    break;
                default:
                    console.warn('Invalid basis type');
                    return;
            }

            console.log('rowindex: ', rowIndex)
        
            // Pass the data to the AddRowForm component
            setSelectedRowData({
                ...row,
                rowIndex, // Store the row index for updates
                historicalData, // Pass historical data for initial load
                forecastData, // Pass forecast data for initial load
                forecastValues, // Pass underlying values as well (like percent increases and growth amounts)
                selectedSpreadsheetId: currentModel.sourceFile?.[rowIndex]?.[0] || '',
                selectedCategory: currentModel.categories?.[rowIndex] || '',
                selectedCategoryValue: currentModel.rowVariables?.[rowIndex] || '',
                selectedDateColumn: currentModel.dateColumn?.[rowIndex] || '',
                selectedVariable: currentModel.groupedOver?.[rowIndex] || '',
                sourceFile: currentModel.sourceFile?.[rowIndex] || [],
            });
        
            // Ensure only the edit popup opens
            setShowAddRowPopup(false); // Ensure "Add Simplified Row" popup is closed
        }
    };

    const closePopup = () => {
        setSelectedRowData(null); // Close the popup
    };

    const handleRowAdded = () => {
        setShowAddRowPopup(false);
        setShowDetailedRowForm(false);
        window.location.reload();  // Force page reload to fetch new data
    };

    // API Call to create a new detailed model
    const createDetailedModel = async (modelType) => {
        try {
            // Determine the row name based on the model type
            const rowName = modelType === 'Formula Build' ? formulaBuildName : detailedRowName;

            // Validate that rowName is provided
            if (!rowName.trim()) {
                alert('Please enter a row name.');
                return;
            }

            const response = await api.post('/models', {
                organisationId: currentModel.organisationId,
                superModelId: currentModel.superModelId, // This links the new model to the current model
                modelName: rowName,
                modelType: modelType,
                basis: currentModel.basis,
                histPeriods: currentModel.histPeriods,
                forePeriods: currentModel.forePeriods,
                yearEnd: currentModel.yearEnd,
                templateUrl: currentModel.templateUrl,
                annualHistHeaders: currentModel.annualHistHeaders,
                quarterlyHistHeaders: currentModel.quarterlyHistHeaders,
                monthlyHistHeaders: currentModel.monthlyHistHeaders,
                annualForeHeaders: currentModel.annualForeHeaders,
                quarterlyForeHeaders: currentModel.quarterlyForeHeaders,
                monthlyForeHeaders: currentModel.monthlyForeHeaders,
            });
            if (response.status === 201) {
                console.log(`${modelType} model created successfully`);

                const newModelId = response.data.model.id;

                // Add a new row to the current model with padding and the new model ID
                await addDetailedRowToCurrentModel(newModelId, modelType);
                handleRowAdded(); // Optionally reload the rows after creation

                // Navigate based on the model type
                if (modelType === 'Supporting Assumptions') {
                    navigate(`/forecasting/${newModelId}/sa`);
                } else if (modelType === 'Formula Build') {
                    navigate(`/forecasting/${newModelId}/fb`);
                }

                window.location.reload();
            } else {
                console.error('Failed to create detailed model');
            }
        } catch (error) {
            console.error('Error creating detailed model:', error);
        }
    };     

    const addDetailedRowToCurrentModel = async (supportingAssumptionId, drivers) => {
        try {
            // Padding for data (10 for annual, 40 for quarterly, 120 for monthly)
            const emptyAnnualData = Array(10).fill('');
            const emptyQuarterlyData = Array(40).fill('');
            const emptyMonthlyData = Array(120).fill('');
    
            // Prepare the row data with padding
            const rowData = {
                rowNames: [drivers === 'Supporting Assumptions' ? detailedRowName : formulaBuildName], // Use the entered detailed row name
                rowVariables: ['None'],
                categories: ['None'],
                groupedOver: 'None',
                dateColumn: 'None',
                sourceFile: ['None'],
                drivers: [drivers], // Set the driver to "Supporting Assumptions"
                supportingAssumptionModelIds: [String(supportingAssumptionId)], // Set the new supporting assumption model ID
                annualHistRows: [emptyAnnualData],
                quarterlyHistRows: [emptyQuarterlyData],
                monthlyHistRows: [emptyMonthlyData],
                annualForeRows: [emptyAnnualData],
                quarterlyForeRows: [emptyQuarterlyData],
                monthlyForeRows: [emptyMonthlyData],
                annualForeValues: [emptyAnnualData],
                quarterlyForeValues: [emptyQuarterlyData],
                monthlyForeValues: [emptyMonthlyData],                
            };
    
            // Send the new row to the backend to add it to the current model
            const response = await api.put(`/models/${currentModel.id}/add-row`, rowData);
    
            if (response.status === 200) {
                console.log('Detailed row added successfully');
            } else {
                console.error('Failed to add detailed row to model:', response.data);
            }
        } catch (error) {
            console.error('Error adding detailed row:', error);
        }
    };

    const handleSave = async (updatedData) => {
        try {
          console.log('handleSave called with data:', JSON.stringify(updatedData, null, 2));
      
          const {
            rowIndex,
            rowName,
            driver,
            category,
            categoryValue,
            groupedOver,
            dateColumn,
            sourceFile,
            updatedHistoricalValues,
            updatedForecastValues,
            updatedForecastRows,
            basis,
          } = updatedData;
      
          // Validate that all required fields are present
          const requiredFields = ['rowName', 'category', 'categoryValue', 'groupedOver', 'dateColumn', 'sourceFile'];
          const missingFields = requiredFields.filter(field => !updatedData[field]);
      
          if (missingFields.length > 0) {
            console.error(`Missing required fields: ${missingFields.join(', ')}`);
            alert(`Missing required fields: ${missingFields.join(', ')}`);
            return;
          }
      
          const payload = {
            rowIndex,
            rowName,
            driver,
            category,
            categoryValue,
            groupedOver,
            dateColumn,
            sourceFile,
            updatedHistoricalValues,
            updatedForecastValues,
            updatedForecastRows,
            basis,
          };
      
          console.log("Data being sent to backend:", JSON.stringify(payload, null, 2));
      
          const response = await api.put(`/model/update-forecasting-row/${currentModel.id}`, payload, {
            headers: {
              'Content-Type': 'application/json',
              // Include Authorization header if required
              // 'Authorization': `Bearer ${token}`,
            },
          });
      
          if (response.status === 200) {
            console.log('Row data successfully updated:', response.data);
            closePopup();
            fetchModel(currentModel.id); // Refresh model data
            window.location.reload()
          } else {
            console.error('Failed to save changes:', response.data);
            alert(`Failed to save changes: ${response.data.message}`);
          }
        } catch (error) {
          console.error('Error saving changes:', error);
          alert('An error occurred while saving changes.');
        }
      };      

    const toggleShowRowForm = () => {
        setShowAddRowPopup(!showAddRowPopup);
        setShowDetailedRowForm(false); // Hide detailed row form when showing simplified form
        setShowFormulaBuildForm(false)
        setSelectedRowData(null); // Ensure editing form is not open
    };

    const toggleShowDetailedRowForm = () => {
        setShowDetailedRowForm(!showDetailedRowForm);
        setShowFormulaBuildForm(false)
        setShowAddRowPopup(false); // Hide simplified row form when showing detailed form
        setSelectedRowData(null);
    };  

    const toggleShowBuildFormulaForm = () => {
        setShowFormulaBuildForm(!showFormulaBuildForm)
        setShowDetailedRowForm(false);
        setShowAddRowPopup(false); // Hide simplified row form when showing detailed form
    };     

    return (
        <div>
            {currentModel && (
                <>
                    <h2>{`${currentModel.modelName} - ${currentModel.modelType}`}</h2>
                    <div className="button-container">
                    <button onClick={toggleShowRowForm}>Add Simplified Row</button>
                    <button onClick={toggleShowDetailedRowForm}>Add Detailed Row</button>
                    <button onClick={toggleShowBuildFormulaForm}>Build Formula Row</button>
                    </div>

                    {/* Popup for adding a simplified row */}
                    {showAddRowPopup && !selectedRowData && (
                        <div className="popup-overlay">
                                <button className="popup-close" onClick={() => setShowAddRowPopup(false)}>×</button>
                                <AddRowForm
                                    currentModel={currentModel}
                                    spreadsheets={spreadsheets}
                                    onRowAdded={handleRowAdded}
                                    onClose={() => setShowAddRowPopup(false)}
                                    isEditing={false}
                                />
                            </div>
                    )}

                    {showDetailedRowForm && (
                        <div>
                            <input 
                                type="text" 
                                placeholder="Enter detailed row name" 
                                value={detailedRowName}
                                onChange={(e) => setDetailedRowName(e.target.value)}
                            />
                            <button onClick={() => createDetailedModel('Supporting Assumptions')}>
                                Confirm
                            </button>
                        </div>
                    )}

                    {showFormulaBuildForm && (
                        <div>
                            <input 
                                type="text" 
                                placeholder="Enter formula build row name" 
                                value={formulaBuildName}
                                onChange={(e) => setFormulaBuildName(e.target.value)}
                            />
                            <button onClick={() => createDetailedModel('Formula Build')}>
                                Confirm
                            </button>
                        </div>
                    )}

                    {isLoading ? (
                        <p>Loading data...</p>
                    ) : (
                        <div className='scrollable-table-container'>
                            <table className='data-table data-table--model'>
                                <thead>
                                    <tr>
                                        {tableHeaders.map((header, index) => {
                                            const isForecastingColumn = index >= tableHeaders.length - currentModel.forePeriods;
                                            return (
                                                <th 
                                                    key={index} 
                                                    className={isForecastingColumn ? 'forecasting-column' : ''}
                                                >
                                                    {header}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(rowsData) && rowsData.length > 0 ? (
                                        rowsData.map((row, rowIndex) => (
                                            <tr 
                                                key={rowIndex} 
                                                onClick={() => handleRowClick(row, rowIndex)} // Pass row and rowIndex
                                            >
                                                <td>{row.name}</td>
                                                <td>{row.driver}</td>
                                                {row.values.map((value, valueIndex) => {
                                                    const isForecastingColumn = valueIndex >= row.values.length - currentModel.forePeriods;
                                                    return (
                                                        <td 
                                                            key={valueIndex} 
                                                            className={isForecastingColumn ? 'forecasting-column' : ''}
                                                        >
                                                            {value}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={tableHeaders.length}>No data available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </>
            )}

            {/* Popup for editing row */}
            {selectedRowData && (
                <div className="popup-overlay">
                    <AddRowForm
                    currentModel={currentModel}
                    spreadsheets={spreadsheets}
                    onRowAdded={handleRowAdded}
                    onClose={closePopup}
                    isEditing={true} // This is true when editing an existing row
                    rowData={selectedRowData}
                    rowIndex={selectedRowData.rowIndex}
                    onSave={handleSave}
                    />
                </div>
            )}
        </div>
    );
};

export default ForecastingPage;
