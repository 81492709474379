import React, { useState, useEffect } from 'react';
import api from '../api';
import { useNavigate } from 'react-router-dom';

function RequestReset() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate()

    useEffect(() => {
        // Function to warm up the email service
        const warmUpEmailService = async () => {
            try {
                await api.get('/emailconfigcheck');
                console.log('Email service warmed up successfully');
            } catch (error) {
                console.error('Error warming up email service:', error);
            }
        };

        warmUpEmailService();
    }, []);

    // Handler to navigate to the register page
    const handleLogin = () => {
        navigate('/');
    };

    // Handler to navigate to the reset password page
    const handleRegister = () => {
        navigate('/register');
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/requestreset', { email });
            setMessage(response.data.message); // Display success message
        } catch (error) {
            setMessage(error.response.data.message); // Display error message
        }
    };

    return (
        <div className="login-page">
            <div className='login-container'>
            <div className='login-content'>
            <h2 style={{textAlign:'center'}}>Reset Password</h2>
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">Email:</label>
                <input 
                    id="email" 
                    type="email" 
                    value={email} 
                    onChange={e => setEmail(e.target.value)} 
                    required 
                />
                <button type="submit">Send Reset Link</button>
            </form>
            <div className="button-group">
                <button onClick={handleLogin} className="half-width-button">Log In</button>
                <button onClick={handleRegister} className="half-width-button">Register</button>
            </div>
            {message && <p>{message}</p>}
            </div>
            </div>
        </div>
    );
}

export default RequestReset;
