import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from './authcontext';
import api from './api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCheck } from '@fortawesome/free-solid-svg-icons';
import { setYear, getMonth, getDate, parseISO, format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker from 'react-datepicker';



const Homepage = () => {
    const { organisations, setOrganisations, setCurrentOrg, currentOrg } = useAuth(); // Updated to only use necessary variables
    const [newOrgName, setNewOrgName] = useState('');
    const [message, setMessage] = useState('');
    const [editableOrgId, setEditableOrgId] = useState(null);
    const [editingField, setEditingField] = useState('');
    const [editedOrgDefaults, setEditedOrgDefaults] = useState({
        defaultBasis: '',
        defaultHistPeriods: '',
        defaultForePeriods: '',
        defaultYearEnd: null,
    });
    const [editedOrgName, setEditedOrgName] = useState('');
    const inputRef = useRef(null);
    const [newOrgDefaults, setNewOrgDefaults] = useState({
        defaultBasis: '',
        defaultHistPeriods: '',
        defaultForePeriods: '',
        defaultYearEnd: null, 
    });    
    

    useEffect(() => {
        console.log('Organisations updated:', organisations);
    }, [organisations]);

    useEffect(() => {
        console.log('CurrentOrg updated:', currentOrg);
    }, [currentOrg]);

    // Automatically set the first organisation as currentOrg if none is set
    useEffect(() => {
        if (organisations?.length > 0 && !currentOrg) {
            setCurrentOrg(organisations[0]);
        }
    }, [organisations, currentOrg, setCurrentOrg]);

    const handleAddOrganisation = async () => {
        // Check if all required fields are filled
        if (
            !newOrgName.trim() ||
            !newOrgDefaults.defaultBasis ||
            !newOrgDefaults.defaultHistPeriods ||
            !newOrgDefaults.defaultForePeriods ||
            !newOrgDefaults.defaultYearEnd
        ) {
            setMessage('Please fill in all fields before adding an organisation.');
            return;
        }

        // Check if the organization name already exists
        const existingOrg = organisations.find(
            org => org.name.toLowerCase() === newOrgName.toLowerCase()
        );
        if (existingOrg) {
            setMessage('An organisation with this name already exists. Please choose a different name.');
            return;
        }

        // Adjust the selected date to have the correct year based on the current date
        const today = new Date();
        const selectedDate = new Date(newOrgDefaults.defaultYearEnd);
        let year = today.getFullYear();

        // Create a date with the selected month and day in the current year
        let selectedDateThisYear = new Date(year, getMonth(selectedDate), getDate(selectedDate));

        // If the selected date is after today, use the previous year
        if (selectedDateThisYear > today) {
            year = year - 1; // Use previous year
            selectedDateThisYear = new Date(year, getMonth(selectedDate), getDate(selectedDate));
        }

        const defaultYearEnd = selectedDateThisYear.toISOString().split('T')[0];

        try {
            const response = await api.post('/addorganisation', {
                name: newOrgName,
                defaultBasis: newOrgDefaults.defaultBasis,
                defaultHistPeriods: newOrgDefaults.defaultHistPeriods,
                defaultForePeriods: newOrgDefaults.defaultForePeriods,
                defaultYearEnd: defaultYearEnd,
            });
            setMessage(response.data.message);
    
            // Assuming the backend returns the new organisation
            const newOrganisation = response.data.organisation;
    
            // Set currentOrg to the new organisation
            setCurrentOrg(newOrganisation);
    
            // Clear the new organization name and defaults input
            setNewOrgName('');
            setNewOrgDefaults({
                defaultBasis: '',
                defaultHistPeriods: '',
                defaultForePeriods: '',
                defaultYearEnd: null,
            });
    
            // Reload the page to refresh the context (optional)
            window.location.reload();

        } catch (error) {
            console.error('Error adding organisation:', error);
            setMessage('Failed to add organisation.');
        }
    };

    const handleSelectOrg = (event) => {
        const selectedOrgId = event.target.value;
        const selectedOrg = organisations.find(org => org.id === parseInt(selectedOrgId));
        setCurrentOrg(selectedOrg);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                if (editableOrgId !== null) {
                    handleSaveEditedOrgField(editableOrgId, editingField);
                }
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [editableOrgId, editingField, editedOrgName, editedOrgDefaults]);    

    if (!organisations) {
        return <p>Loading...</p>;
    }

    const handleEditOrgField = (orgId, field, currentValue) => {
        setEditableOrgId(orgId);
        setEditingField(field);
        if (field === 'name') {
            setEditedOrgName(currentValue);
        } else if (field === 'defaultYearEnd') {
            // Parse the date string to a Date object
            setEditedOrgDefaults(prev => ({ ...prev, [field]: parseISO(currentValue) }));
        } else {
            setEditedOrgDefaults(prev => ({ ...prev, [field]: currentValue }));
        }
    };    

    const handleSaveEditedOrgField = async (orgId, field) => {
        let updatedData = {};
    
        if (field === 'name') {
            updatedData.name = editedOrgName;
        } else if (field === 'defaultYearEnd') {
            // Adjust the selected date to have the correct year based on the current date
            const today = new Date();
            const selectedDate = new Date(editedOrgDefaults.defaultYearEnd);
            let year = today.getFullYear();
    
            // Create a date with the selected month and day in the current year
            let selectedDateThisYear = new Date(year, getMonth(selectedDate), getDate(selectedDate));
    
            // If the selected date is after today, use the previous year
            if (selectedDateThisYear > today) {
                year = year - 1; // Use previous year
                selectedDateThisYear = new Date(year, getMonth(selectedDate), getDate(selectedDate));
            }
    
            const defaultYearEnd = selectedDateThisYear.toISOString().split('T')[0];
    
            updatedData.defaultYearEnd = defaultYearEnd;
        } else {
            updatedData[field] = editedOrgDefaults[field];
        }
    
        try {
            await api.put(`/organisations/${orgId}`, updatedData);
    
            // Update the organisations state
            setOrganisations(prevOrgs =>
                prevOrgs.map(org =>
                    org.id === orgId ? { ...org, ...updatedData } : org
                )
            );
    
            // Update currentOrg if necessary
            if (currentOrg?.id === orgId) {
                setCurrentOrg(prevOrg => ({ ...prevOrg, ...updatedData }));
            }
    
            window.location.reload();
        } catch (error) {
            console.error('Error updating organisation:', error);
        }
    
        // Reset editing states
        setEditableOrgId(null);
        setEditingField('');
        setEditedOrgName('');
        setEditedOrgDefaults({
            defaultBasis: '',
            defaultHistPeriods: '',
            defaultForePeriods: '',
            defaultYearEnd: null,
        });
    };    

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <input
            onClick={onClick}
            ref={ref}
            value={value}
            onChange={() => {}}
            placeholder="Select Day and Month"
            readOnly
        />
    ));    
    

    return (
        <div>
            <h1>Welcome to the Homepage</h1>
            <div>
                {organisations.length > 0 && (
                    <div>
                        <h2>Select an Organisation</h2>
                        <select onChange={handleSelectOrg} value={currentOrg?.id || ''}>
                            <option value="" disabled>Select your organisation</option>
                            {organisations.map(org => (
                                <option key={org.id} value={org.id}>{org.name}</option>
                            ))}
                        </select>
                    </div>
                )}
                <h2>Add a New Organisation</h2>
                <input
                    type="text"
                    value={newOrgName}
                    onChange={(e) => setNewOrgName(e.target.value)}
                    placeholder="Enter organisation name"
                />
                <div>
                    <label>Default Basis:</label>
                    <select
                        name="defaultBasis"
                        value={newOrgDefaults.defaultBasis}
                        onChange={(e) =>
                            setNewOrgDefaults({ ...newOrgDefaults, defaultBasis: e.target.value })
                        }
                    >
                        <option value="">Select Basis</option>
                        <option value="Yearly">Yearly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Monthly">Monthly</option>
                    </select>
                </div>
                <div>
                    <label>Historical Periods:</label>
                    <input
                        type="number"
                        name="defaultHistPeriods"
                        value={newOrgDefaults.defaultHistPeriods}
                        onChange={(e) =>
                            setNewOrgDefaults({ ...newOrgDefaults, defaultHistPeriods: e.target.value })
                        }
                        min="1"
                    />
                </div>
                <div>
                    <label>Forecasting Periods:</label>
                    <input
                        type="number"
                        name="defaultForePeriods"
                        value={newOrgDefaults.defaultForePeriods}
                        onChange={(e) =>
                            setNewOrgDefaults({ ...newOrgDefaults, defaultForePeriods: e.target.value })
                        }
                        min="1"
                    />
                </div>
                <div>
                    <label>Financial Year End:</label>
                    <ReactDatePicker
                        selected={newOrgDefaults.defaultYearEnd}
                        onChange={(date) =>
                            setNewOrgDefaults({
                                ...newOrgDefaults,
                                defaultYearEnd: date,
                            })
                        }
                        dateFormat="dd MMMM"
                        showMonthDropdown
                        showYearDropdown={false}
                        placeholderText="Select Day and Month"
                        dropdownMode="select"
                        showPopperArrow={false}
                        isClearable
                        customInput={<CustomInput />}
                    />
                </div>
                <button onClick={handleAddOrganisation}>Add Organisation</button>
                {message && <p>{message}</p>}
            </div>
            {organisations.length > 0 && (
                <div>
                    <h2>Your Organisations</h2>
                    <div className='scrollable-table-container'>
                        <table className='data-table'>
                            <thead>
                                <tr>
                                    <th>Organisation Name</th>
                                    <th>Default Basis</th>
                                    <th>Historical Periods</th>
                                    <th>Forecasting Periods</th>
                                    <th>Financial Year End</th>
                                </tr>
                            </thead>
                            <tbody>
                                {organisations.map(org => (
                                    <tr key={org.id}>
                                        {/* Organisation Name */}
                                        <td>
                                            {editableOrgId === org.id && editingField === 'name' ? (
                                                <div className="org-name-container">
                                                    <input
                                                        ref={inputRef}
                                                        type="text"
                                                        value={editedOrgName}
                                                        onChange={(e) => setEditedOrgName(e.target.value)}
                                                    />
                                                    <FontAwesomeIcon 
                                                        icon={faCheck} 
                                                        className="icon" 
                                                        onClick={() => handleSaveEditedOrgField(org.id, 'name')} 
                                                    />
                                                </div>
                                            ) : (
                                                <div className="org-name-container">
                                                    <span>{org.name}</span>
                                                    <FontAwesomeIcon 
                                                        icon={faPen} 
                                                        className="icon" 
                                                        onClick={() => handleEditOrgField(org.id, 'name', org.name)} 
                                                    />
                                                </div>
                                            )}
                                        </td>
                                        {/* Default Basis */}
                                        <td>
                                            {editableOrgId === org.id && editingField === 'defaultBasis' ? (
                                                <div className="org-name-container">
                                                    <select
                                                        value={editedOrgDefaults.defaultBasis}
                                                        onChange={(e) =>
                                                            setEditedOrgDefaults(prev => ({ ...prev, defaultBasis: e.target.value }))
                                                        }
                                                    >
                                                        <option value="">Select Basis</option>
                                                        <option value="Yearly">Yearly</option>
                                                        <option value="Quarterly">Quarterly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select>
                                                    <FontAwesomeIcon 
                                                        icon={faCheck} 
                                                        className="icon" 
                                                        onClick={() => handleSaveEditedOrgField(org.id, 'defaultBasis')} 
                                                    />
                                                </div>
                                            ) : (
                                                <div className="org-name-container">
                                                    <span>{org.defaultBasis}</span>
                                                    <FontAwesomeIcon 
                                                        icon={faPen} 
                                                        className="icon" 
                                                        onClick={() => handleEditOrgField(org.id, 'defaultBasis', org.defaultBasis)} 
                                                    />
                                                </div>
                                            )}
                                        </td>
                                        {/* Historical Periods */}
                                        <td>
                                            {editableOrgId === org.id && editingField === 'defaultHistPeriods' ? (
                                                <div className="org-name-container">
                                                    <input
                                                        type="number"
                                                        value={editedOrgDefaults.defaultHistPeriods}
                                                        onChange={(e) =>
                                                            setEditedOrgDefaults(prev => ({ ...prev, defaultHistPeriods: e.target.value }))
                                                        }
                                                        min="1"
                                                    />
                                                    <FontAwesomeIcon 
                                                        icon={faCheck} 
                                                        className="icon" 
                                                        onClick={() => handleSaveEditedOrgField(org.id, 'defaultHistPeriods')} 
                                                    />
                                                </div>
                                            ) : (
                                                <div className="org-name-container">
                                                    <span>{org.defaultHistPeriods}</span>
                                                    <FontAwesomeIcon 
                                                        icon={faPen} 
                                                        className="icon" 
                                                        onClick={() => handleEditOrgField(org.id, 'defaultHistPeriods', org.defaultHistPeriods)} 
                                                    />
                                                </div>
                                            )}
                                        </td>
                                        {/* Forecasting Periods */}
                                        <td>
                                            {editableOrgId === org.id && editingField === 'defaultForePeriods' ? (
                                                <div className="org-name-container">
                                                    <input
                                                        type="number"
                                                        value={editedOrgDefaults.defaultForePeriods}
                                                        onChange={(e) =>
                                                            setEditedOrgDefaults(prev => ({ ...prev, defaultForePeriods: e.target.value }))
                                                        }
                                                        min="1"
                                                    />
                                                    <FontAwesomeIcon 
                                                        icon={faCheck} 
                                                        className="icon" 
                                                        onClick={() => handleSaveEditedOrgField(org.id, 'defaultForePeriods')} 
                                                    />
                                                </div>
                                            ) : (
                                                <div className="org-name-container">
                                                    <span>{org.defaultForePeriods}</span>
                                                    <FontAwesomeIcon 
                                                        icon={faPen} 
                                                        className="icon" 
                                                        onClick={() => handleEditOrgField(org.id, 'defaultForePeriods', org.defaultForePeriods)} 
                                                    />
                                                </div>
                                            )}
                                        </td>
                                        {/* Financial Year End */}
                                        <td>
                                            {editableOrgId === org.id && editingField === 'defaultYearEnd' ? (
                                                <div className="org-name-container">
                                                    <ReactDatePicker
                                                        selected={editedOrgDefaults.defaultYearEnd}
                                                        onChange={(date) =>
                                                            setEditedOrgDefaults(prev => ({ ...prev, defaultYearEnd: date }))
                                                        }
                                                        dateFormat="dd MMMM"
                                                        showMonthDropdown
                                                        showYearDropdown={false}
                                                        placeholderText="Select Day and Month"
                                                        dropdownMode="select"
                                                        showPopperArrow={false}
                                                        isClearable
                                                        customInput={<CustomInput />}
                                                    />
                                                    <FontAwesomeIcon 
                                                        icon={faCheck} 
                                                        className="icon" 
                                                        onClick={() => handleSaveEditedOrgField(org.id, 'defaultYearEnd')} 
                                                    />
                                                </div>
                                            ) : (
                                                <div className="org-name-container">
                                                    <span>
                                                    {org.defaultYearEnd
                                                        ? format(parseISO(org.defaultYearEnd), 'd MMMM')
                                                        : 'No Date Set'}
                                                    </span>
                                                    <FontAwesomeIcon 
                                                        icon={faPen} 
                                                        className="icon" 
                                                        onClick={() => handleEditOrgField(org.id, 'defaultYearEnd', org.defaultYearEnd)} 
                                                    />
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Homepage;
