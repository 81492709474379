import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import api from '../api';
import SpreadsheetSelector from './spreadsheetselector';
import fetchSpreadsheetData from '../fetchspreadsheetdata';
import * as XLSX from 'xlsx';
import { useAuth } from '../authcontext'; // Import the useAuth hook
import FormulaValidator from '../formulavalidator';
import { evaluateSumFormula } from './evaluatesumformula';

const AddRowForm = ({
  currentModel,
  spreadsheets,
  onRowAdded,
  onClose,
  isEditing,
  rowData,
  rowIndex,
  onSave,
}) => {
  // State variables
  const [isHistorical, setIsHistorical] = useState(true);
  const [isConsolidated, setIsConsolidated] = useState(false);
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState('');
  const [processedData, setProcessedData] = useState(null);
  const [spreadsheetData, setSpreadsheetData] = useState(null);
  const [selectedSpreadsheets, setSelectedSpreadsheets] = useState([]); // For consolidated mode
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedVariable, setSelectedVariable] = useState('');
  const [selectedDateColumn, setSelectedDateColumn] = useState('');
  const [categoryValues, setCategoryValues] = useState([]);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState('');
  const [selectedDriver, setSelectedDriver] = useState('Fixed Amount');
  const [rowName, setRowName] = useState('');
  const [forecastInputs, setForecastInputs] = useState({});
  const [forecastAmounts, setForecastAmounts] = useState({});
  const [historicalAmounts, setHistoricalAmounts] = useState({});
  const [allHistoricalAmounts, setAllHistoricalAmounts] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const [isEditingSetup, setIsEditingSetup] = useState(false); // New state for Edit Setup Options
  const [selectedReferenceRow, setSelectedReferenceRow] = useState(''); // New State for Reference Row
  const [referenceRowForecastValues, setReferenceRowForecastValues] = useState([]); // New state for reference row forecast values
  const [sumFormulaInput, setSumFormulaInput] = useState(''); // To hold the user's formula input
  const [sumValidationMessage, setSumValidationMessage] = useState(null); // To display validation results
  const [sumFormulaToValidate, setSumFormulaToValidate] = useState(''); // To trigger validation
  const [calculatedHistoricalValues, setCalculatedHistoricalValues] = useState({});
  const [calculatedForecastValues, setCalculatedForecastValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPeriodOption, setSelectedPeriodOption] = useState('Previous period'); // Default to 'Previous period'
  const [specificPeriod, setSpecificPeriod] = useState(''); // To hold the user-entered specific period

  const { editedRows } = useAuth(); // Get editedRows from useAuth

  // Utility function for shallow comparison
  const isObjectShallowEqual = useCallback((obj1, obj2) => {
    if (obj1 === obj2) return true;
    if (typeof obj1 !== typeof obj2) return false;
    if (typeof obj1 !== 'object' || obj1 === null || obj2 === null) return false;
    
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    
    if (keys1.length !== keys2.length) {
      console.log('Different number of keys:', keys1.length, keys2.length);
      return false;
    }
    
    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        console.log(`Difference found at key "${key}":`, obj1[key], obj2[key]);
        return false;
      }
    }
    
    return true;
  }, []);  

  // Helper Functions

  // 1. Helper function to parse Excel dates
  const parseExcelDate = useCallback((excelDate) => {
    if (typeof excelDate === 'number') {
      const date = XLSX.SSF.parse_date_code(excelDate);
      if (date) {
        return moment({ y: date.y, M: date.m - 1, d: date.d });
      }
    } else if (typeof excelDate === 'string') {
      const parsedDate = moment(excelDate, ['DD/MM/YYYY', 'YYYY-MM-DD']);
      if (parsedDate.isValid()) {
        return parsedDate;
      }
    }
    return moment.invalid(); // Return an invalid moment if parsing fails
  }, []);

    // 9. Handler to update spreadsheets data
    const loadSpreadsheetData = useCallback(
      async (spreadsheetId) => {
        const currentSpreadsheet = spreadsheets.find((s) => s.id === spreadsheetId);
        if (!currentSpreadsheet) {
          console.error('Spreadsheet not found:', spreadsheetId);
          return;
        }
        const data = await fetchSpreadsheetData(
          spreadsheetId,
          currentSpreadsheet,
          editedRows,
          true,
          true
        );
        console.log('Fetched processed data:', data); // Add this log
        setProcessedData(data);
        setSpreadsheetData(data.processedRows);
      },
      [spreadsheets, editedRows, fetchSpreadsheetData]
    );

  // 2. Helper function to calculate sum for a period
  const calculateSumForPeriod = useCallback(
    (startDate, endDate, processedData, categoryValue = selectedCategoryValue) => {
      if (
        !processedData ||
        !selectedCategory ||
        !selectedVariable ||
        !selectedDateColumn
      ) {
        console.log('Missing required data or selections');
        return 0;
      }

      const categoryIndex = processedData.filteredHeaders.indexOf(selectedCategory);
      const variableIndex = processedData.filteredHeaders.indexOf(selectedVariable);
      const dateIndex = processedData.filteredHeaders.indexOf(selectedDateColumn);

      if (categoryIndex === -1 || variableIndex === -1 || dateIndex === -1) {
        console.error('Invalid indices for category/variable/date');
        return 0;
      }

      // Parse startDate and endDate into moment objects with strict parsing
      const periodStartDate = moment(startDate, ['DD/MM/YYYY', 'YYYY-MM-DD'], true);
      const periodEndDate = moment(endDate, ['DD/MM/YYYY', 'YYYY-MM-DD'], true);

      if (!periodStartDate.isValid() || !periodEndDate.isValid()) {
        console.error('Invalid period startDate or endDate');
        return 0;
      }

      const sum = processedData.processedRows.reduce((total, row, rowIndex) => {
        const rowDate = parseExcelDate(row[dateIndex]);

        const isInPeriod =
          rowDate.isValid() &&
          rowDate.isBetween(periodStartDate, periodEndDate, undefined, '[]');

        let categoryMatch = true;
        if (categoryValue && categoryValue !== 'Total') {
          categoryMatch = row[categoryIndex] === categoryValue;
        }

        if (categoryMatch && isInPeriod) {
          const value = parseFloat(row[variableIndex]);
          if (!isNaN(value)) {
            return total + value;
          } else {
            console.warn(`Invalid value at row index ${rowIndex}:`, row[variableIndex]);
          }
        }

        return total;
      }, 0);
      return sum;
    },
    [parseExcelDate, selectedCategory, selectedVariable, selectedDateColumn, selectedCategoryValue]
  );
  
// Helper function to recalculate forecast amounts
const recalculateForecastAmounts = useCallback(
  (inputs, periods) => {
    const updatedForecastAmounts = {};
    const offset = getPeriodsToLookBack();
    console.log('offset: ', offset)
    console.log('allhistamounts: ', allHistoricalAmounts)

    // Loop through all periods to calculate forecast amounts
    for (let i = 0; i < periods.length; i++) {
      const currentPeriodLabel = periods[i];

      if (i < currentModel.histPeriods) {
        // Skip historical periods
        continue;
      }

      // Determine the index of the period to look back
      const lookBackIndex = i - offset;

      let baseAmount = 0;
      if (lookBackIndex < 0) {
        console.warn(`Look-back index ${lookBackIndex} is out of bounds. Using 0 as base amount.`);
        baseAmount = 0;
      } else {
        const basePeriodLabel = periods[lookBackIndex] || '';
        baseAmount = parseFloat(allHistoricalAmounts[basePeriodLabel] || forecastAmounts[basePeriodLabel] || 0);
      }

      if (selectedDriver === 'Fixed Amount') {
        const inputAmount = parseFloat(inputs[currentPeriodLabel]) || 0;
        updatedForecastAmounts[currentPeriodLabel] = inputAmount.toFixed(2);
      } else if (selectedDriver === 'Fixed Growth') {
        const growthAmount = parseFloat(inputs[currentPeriodLabel]) || 0;
        const newAmount = baseAmount + growthAmount;
        updatedForecastAmounts[currentPeriodLabel] = newAmount.toFixed(2);
      } else if (selectedDriver === 'Percent') {
        const percentageIncrease = parseFloat(inputs[currentPeriodLabel]) || 0;
        const newAmount = baseAmount + (baseAmount * percentageIncrease) / 100;
        updatedForecastAmounts[currentPeriodLabel] = newAmount.toFixed(2);
      } else if (selectedDriver === 'Percent of Another Row') {
        // **Handling "Percent of Another Row" Driver**
        if (referenceRowForecastValues && referenceRowForecastValues[i - currentModel.histPeriods] !== undefined) {
          const referenceForecastValue = parseFloat(referenceRowForecastValues[i - currentModel.histPeriods]) || 0;
          const percentValue = parseFloat(inputs[currentPeriodLabel]) || 0;
          const calculatedAmount = (referenceForecastValue * percentValue) / 100;
          updatedForecastAmounts[currentPeriodLabel] = calculatedAmount.toFixed(2);
        } else {
          console.warn(`Reference forecast value for period "${currentPeriodLabel}" is not available.`);
          updatedForecastAmounts[currentPeriodLabel] = '0.00';
        }
      }

      // Additional drivers can be handled here
    }

    // Update the forecastAmounts state
    setForecastAmounts((prevForecastAmounts) => {
      if (!isObjectShallowEqual(updatedForecastAmounts, prevForecastAmounts)) {
        console.log('Updating forecastAmounts from:', prevForecastAmounts, 'to:', updatedForecastAmounts);
        return updatedForecastAmounts;
      }
      console.log('forecastAmounts unchanged, no update needed.');
      return prevForecastAmounts;
    });
  },
  [
    currentModel.histPeriods,
    allHistoricalAmounts, // Updated dependency
    forecastAmounts,
    isObjectShallowEqual,
    selectedDriver,
    referenceRowForecastValues
  ]
);

  // 5. Helper function to recalculate historical amounts
  const recalculateHistoricalAmounts = useCallback(
    (newCategoryValue) => {
      if (processedData && currentModel.histPeriods) {
        let histHeaders = [];

        switch (currentModel.basis) {
          case 'Yearly':
            histHeaders = JSON.parse(currentModel.annualHistHeaders || '[]').slice(-currentModel.histPeriods);
            break;
          case 'Quarterly':
            histHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]').slice(-currentModel.histPeriods);
            break;
          case 'Monthly':
            histHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]').slice(-currentModel.histPeriods);
            break;
          default:
            console.error('Invalid model basis');
            return;
        }

        const tempHistoricalAmounts = {};
        histHeaders.forEach((header) => {
          const sum = calculateSumForPeriod(
            header[1],
            header[2],
            processedData,
            newCategoryValue
          );
          tempHistoricalAmounts[header[0]] = sum; // ✅ Corrected key usage
        });

        // Compare before setting state
        if (!isObjectShallowEqual(tempHistoricalAmounts, historicalAmounts)) {
          setHistoricalAmounts(tempHistoricalAmounts);
        }
      }
    },
    [processedData, currentModel.histPeriods, currentModel.basis, calculateSumForPeriod, historicalAmounts, isObjectShallowEqual]
  );

  // 6. Handler function for category value changes
  const handleCategoryValueChange = useCallback(
    (value) => {
      if (selectedCategoryValue !== value) {
        setSelectedCategoryValue(value);
        recalculateHistoricalAmounts(value);
      }
    },
    [selectedCategoryValue, recalculateHistoricalAmounts]
  );

  // 7. Handler for forecast input changes
  const handleForecastInputChange = useCallback(
    (period, value, periods) => {
      const parsedValue = parseFloat(value);
      console.log(`Entered forecast value for ${period}:`, parsedValue);
  
      setForecastInputs((prevState) => {
        const updatedInputs = {
          ...prevState,
          [period]: isNaN(parsedValue) ? '' : parsedValue, // Allow empty string for flexibility
        };
  
        console.log('Updated forecast inputs:', updatedInputs);
  
        // Recalculate forecast amounts whenever inputs change
        recalculateForecastAmounts(updatedInputs, periods);
  
        return updatedInputs;
      });
    },
    [recalculateForecastAmounts]
  );  

  // 8. Handler to update historical amounts when edited
  const handleHistoricalAmountChange = useCallback((label, value) => {
    const parsedValue = isNaN(parseFloat(value)) ? '' : parseFloat(value);
    setHistoricalAmounts((prevState) => {
      if (prevState[label] === parsedValue) return prevState; // Prevent update if value is same
      return {
        ...prevState,
        [label]: parsedValue,
      };
    });
  }, []);  
  

// Update this function within your component
const calculateSumBasedOnFormula = useCallback(
  (formula, basis) => { // Added 'basis' parameter
    if (!currentModel || Object.keys(currentModel).length === 0) {
      console.error('Current model data is not available for formula evaluation.');
      return { calculatedHistoricalValues: {}, calculatedForecastValues: {} };
    }

    // Function to extract data based on type and basis
    const extractData = (type, basis) => {
      let rows = {};
      const rowNames = currentModel.rowNames; // Array of row names
    
      rowNames.forEach((rowName, index) => {
        // **Skip Pivot Table Rows**
        if (Array.isArray(rowName) && rowName[0] === 'Pivot') {
          console.log(`Skipping pivot row at index ${index}:`, rowName);
          return; // Skip to the next iteration
        }

        let data = [];
        if (type === 'historical') {
          switch (basis) { // Use the passed 'basis' parameter
            case 'Annual':
              data = JSON.parse(currentModel.annualHistRows || '[]')[index] || [];
              break;
            case 'Quarterly':
              data = JSON.parse(currentModel.quarterlyHistRows || '[]')[index] || [];
              break;
            case 'Monthly':
              data = JSON.parse(currentModel.monthlyHistRows || '[]')[index] || [];
              break;
            default:
              console.error('Invalid basis');
              data = [];
          }
        } else if (type === 'forecast') {
          switch (basis) { // Use the passed 'basis' parameter
            case 'Annual':
              data = JSON.parse(currentModel.annualForeRows || '[]')[index] || [];
              break;
            case 'Quarterly':
              data = JSON.parse(currentModel.quarterlyForeRows || '[]')[index] || [];
              break;
            case 'Monthly':
              data = JSON.parse(currentModel.monthlyForeRows || '[]')[index] || [];
              break;
            default:
              console.error('Invalid basis');
              data = [];
          }
        }
        
        // Sanitize data: ensure all values are numeric
        rows[rowName] = Array.isArray(data)
          ? data.map(value => {
              const parsed = parseFloat(value);
              return isNaN(parsed) ? 0 : parsed;
            })
          : [];
      });
    
      return rows;
    };            

    // Extract historical and forecast data based on the provided basis
    const allHistoricalData = extractData('historical', basis); // Pass the basis as an argument
    const allForecastData = extractData('forecast', basis); // Pass the basis as an argument

    console.log('allhistdata: ', allHistoricalData);
    console.log('allforedata: ', allForecastData);

    // Evaluate formula for all historical periods
    const newCalculatedHistoricalValues = {};
    const histPeriodCount = Object.values(allHistoricalData)[0]?.length || 0; // Determine the number of historical periods

    for (let i = 0; i < histPeriodCount; i++) {
      try {
        const result = evaluateSumFormula(formula, allHistoricalData, i);
        newCalculatedHistoricalValues[i] = result.toFixed(2);
      } catch (err) {
        console.error(`Error evaluating formula for historical period ${i + 1}:`, err.message);
        newCalculatedHistoricalValues[i] = 'Error';
      }
    }

    // Evaluate formula for all forecast periods
    const newCalculatedForecastValues = {};
    const forePeriodCount = Object.values(allForecastData)[0]?.length || 0; // Determine the number of forecast periods

    for (let i = 0; i < forePeriodCount; i++) {
      try {
        const result = evaluateSumFormula(formula, allForecastData, i);
        newCalculatedForecastValues[i] = result.toFixed(2);
      } catch (err) {
        console.error(`Error evaluating formula for forecast period ${i + 1}:`, err.message);
        newCalculatedForecastValues[i] = 'Error';
      }
    }

    console.log('newhistvalues: ', newCalculatedHistoricalValues);
    console.log('newforevalues: ', newCalculatedForecastValues);

    // **Return** the calculated values instead of setting state
    return {
      calculatedHistoricalValues: newCalculatedHistoricalValues,
      calculatedForecastValues: newCalculatedForecastValues,
    };
  },
  [currentModel, evaluateSumFormula]
);

const handleValidationResult = useCallback(
  (error, correctedFormula) => {
    if (error) {
      setSumValidationMessage(error);
      setCalculatedHistoricalValues({});
      setCalculatedForecastValues({});
    } else {
      setSumValidationMessage("Formula is valid!");
      const sanitizedFormula = correctedFormula.replace(/"/g, "'");
      
      // **Call calculateSumBasedOnFormula and capture the returned values**
      const { calculatedHistoricalValues, calculatedForecastValues } = calculateSumBasedOnFormula(sanitizedFormula, currentModel.basis);
      
      // **Slice the calculated values based on histPeriods and forePeriods**
      const slicedHistoricalValues = {};
      const slicedForecastValues = {};
      
      // Slice historical values: take the last 'histPeriods' entries
      Object.keys(calculatedHistoricalValues).forEach(key => {
        const index = parseInt(key, 10);
        if (index >= (Object.keys(calculatedHistoricalValues).length - currentModel.histPeriods)) {
          slicedHistoricalValues[index] = calculatedHistoricalValues[key];
        }
      });
      
      // Slice forecast values: take the first 'forePeriods' entries
      Object.keys(calculatedForecastValues).forEach(key => {
        const index = parseInt(key, 10);
        if (index < currentModel.forePeriods) {
          slicedForecastValues[index] = calculatedForecastValues[key];
        }
      });
      
      // **Set the sliced values to state**
      setCalculatedHistoricalValues(slicedHistoricalValues);
      setCalculatedForecastValues(slicedForecastValues);
    }
    // Reset sumFormulaToValidate to prevent re-validation
    setSumFormulaToValidate('');
  },
  [calculateSumBasedOnFormula, currentModel.histPeriods, currentModel.forePeriods, currentModel.basis]
);
  


const generateTableRows = useCallback(
  (periods) => {
    const isSumOfRows = selectedDriver === 'Formula of Rows' && sumValidationMessage === "Formula is valid!";
    console.log(`Generating table rows. isSumOfRows: ${isSumOfRows}`);

    // Determine if the current driver is "Formula of Rows" and slice historical values accordingly
    const histValues = isSumOfRows
      ? Object.values(calculatedHistoricalValues).slice(-currentModel.histPeriods)
      : null;

    console.log('histValues: ', histValues);

    const growthRow = []; // Row for the input fields (growth or percentage)
    const amountRow = []; // Row for the calculated forecast amounts

    periods.forEach((label, i) => {
      console.log(`Period Label: ${label}, Forecast Input: ${forecastInputs[label]}`);

      if (i < currentModel.histPeriods) {
        // Historical Periods
        const sum = isSumOfRows
          ? (histValues && histValues[i] !== undefined ? histValues[i] : '0')
          : (historicalAmounts[label] !== undefined
            ? historicalAmounts[label]
            : (isHistorical ? 0 : ''));

        // Determine if the historical amount should be editable
        const isEditableHistorical = !isHistorical || (isEditing && !isEditingSetup);

        amountRow.push(
          <td key={`amount-${i}`}>
            {isEditableHistorical ? (
              <input
                type="number"
                value={sum}
                onChange={(e) => handleHistoricalAmountChange(label, e.target.value)}
              />
            ) : (
              sum
            )}
          </td>
        );

        // Handle different drivers for historical data
        if (selectedDriver === 'Percent') {
          if (i === 0) {
            // First column should be blank for percentage change
            growthRow.push(<td key={`percent-${i}`} />);
          } else {
            const previousSum = historicalAmounts[periods[i - 1]] || 0;
            const percentChange = previousSum !== 0 ? ((sum - previousSum) / previousSum) * 100 : 0;
            growthRow.push(
              <td key={`percent-${i}`}>
                {percentChange.toFixed(2)}%
              </td>
            );
          }
        } else if (selectedDriver === 'Fixed Growth') {
          // For "Fixed Growth", calculate the difference from the previous value
          const change = i === 0 ? '' : sum - (historicalAmounts[periods[i - 1]] || 0);
          growthRow.push(
            <td key={`growth-${i}`}>
              {change !== '' ? change.toFixed(2) : ''}
            </td>
          );
        } else if (selectedDriver === 'Percent of Another Row') {
          // Leave this row blank for "Percent of Another Row" historical periods
          growthRow.push(<td key={`growth-${i}`} />);
        }
      } else {
        // Forecast Periods
        if (isSumOfRows) {
          const forecastIndex = i - currentModel.histPeriods;
          const value = calculatedForecastValues[forecastIndex] || '';
          amountRow.push(<td key={`fore-${i}`}>{value}</td>);
          // For "Formula of Rows", no growth row is needed for forecast periods
          growthRow.push(<td key={`fore-growth-${i}`}></td>);
        } else {
          // Handling other drivers
          if (selectedDriver === 'Percent') {
            // Input row for percentage changes for forecast periods
            growthRow.push(
              <td key={`percent-${i}`}>
                <input
                  type="number"
                  value={forecastInputs[label] || ''}
                  onChange={(e) => handleForecastInputChange(label, e.target.value, periods)}
                />
                %
              </td>
            );

            // Row for forecasted amounts based on input percentages for "Percent"
            amountRow.push(
              <td key={`amount-${i}`}>
                {forecastAmounts[label] !== undefined && forecastAmounts[label] !== ''
                  ? forecastAmounts[label]
                  : ''}
              </td>
            );
          } else if (selectedDriver === 'Percent of Another Row') {
            // Input row for percentage changes for "Percent of Another Row"
            growthRow.push(
              <td key={`percent-${i}`}>
                <input
                  type="number"
                  value={forecastInputs[label] || ''}
                  onChange={(e) => handleForecastInputChange(label, e.target.value, periods)}
                />
                %
              </td>
            );

            // Row for forecasted amounts based on input percentages for "Percent of Another Row"
            const calculatedAmount = forecastAmounts[label] || '0.00';

            amountRow.push(
              <td key={`amount-${i}`}>
                {calculatedAmount}
              </td>
            );
          } else if (selectedDriver === 'Fixed Growth') {
            // Input row for fixed growth
            growthRow.push(
              <td key={`growth-${i}`}>
                <input
                  type="number"
                  value={forecastInputs[label] || ''}
                  onChange={(e) => handleForecastInputChange(label, e.target.value, periods)}
                />
              </td>
            );

            // **Use forecastAmounts[label] instead of recalculating**
            const calculatedAmount = forecastAmounts[label] || '0.00';

            amountRow.push(<td key={`amount-${i}`}>{calculatedAmount}</td>);
          } else if (selectedDriver === 'Fixed Amount' || selectedDriver === 'Formula of Rows') {
            // Input row for fixed amounts
            amountRow.push(
              <td key={`amount-${i}`}>
                <input
                  type="number"
                  value={forecastInputs[label] || ''}
                  onChange={(e) => handleForecastInputChange(label, e.target.value, periods)}
                />
              </td>
            );
            // No growth input for Fixed Amount
            growthRow.push(<td key={`growth-${i}`}></td>);
          }
        }
      }
    });

    // Render the table rows with appropriate labels in the driver column
    return (
      <>
        {(selectedDriver === 'Percent' || selectedDriver === 'Fixed Growth' || selectedDriver === 'Percent of Another Row') && (
          <tr>
            <td>{rowName}</td>
            <td>{selectedDriver === 'Percent' ? 'Percent Change' : selectedDriver === 'Percent of Another Row' ? 'Percentage' : 'Growth'}</td>
            {growthRow}
          </tr>
        )}
        <tr>
          <td>{rowName}</td>
          <td>Amount</td>
          {amountRow}
        </tr>
      </>
    );
  },
  [
    currentModel.histPeriods,
    currentModel.forePeriods,
    selectedDriver,
    rowName,
    historicalAmounts,
    forecastInputs,
    forecastAmounts,
    calculatedHistoricalValues,
    calculatedForecastValues,
    handleForecastInputChange,
    handleHistoricalAmountChange,
    referenceRowForecastValues, // Added for "Percent of Another Row"
    isEditing,
    isEditingSetup,
    isHistorical,
  ]
);
  
  // 11. Helper function to generate table headers
  const generateTableHeaders = useCallback(() => {
    let histHeaders = [];
    let foreHeaders = [];

    // Determine which headers to use based on the model's basis
    switch (currentModel.basis) {
      case 'Yearly':
        histHeaders = JSON.parse(currentModel.annualHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.annualForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      case 'Quarterly':
        histHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      case 'Monthly':
        histHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      default:
        console.error('Invalid model basis');
        return [];
    }

    // Extract the first element (label) from each header array
    const historicalLabels = histHeaders.map((header) => header[0]); // Access the first element for historical labels
    const forecastLabels = foreHeaders.map((header) => header[0]); // Access the first element for forecast labels

    console.log('Historical Labels:', historicalLabels);
    console.log('Forecast Labels:', forecastLabels);

    return ['Row name', 'Driver', ...historicalLabels, ...forecastLabels];
  }, [currentModel]);

  // 12. useEffect to set historicalAmounts when dependencies change
  useEffect(() => {
    if (processedData && currentModel.histPeriods) {
      let histHeaders = [];
  
      switch (currentModel.basis) {
        case 'Yearly':
          histHeaders = JSON.parse(currentModel.annualHistHeaders || '[]');
          break;
        case 'Quarterly':
          histHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]');
          break;
        case 'Monthly':
          histHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]');
          break;
        default:
          console.error('Invalid model basis');
          return;
      }
  
      // Calculate all historical amounts
      const tempAllHistoricalAmounts = {};
      histHeaders.forEach((header) => {
        const [label, startDate, endDate] = header;
        const sum = calculateSumForPeriod(
          startDate,
          endDate,
          processedData,
          selectedCategoryValue
        );
        tempAllHistoricalAmounts[label] = sum;
      });
  
      // Update allHistoricalAmounts state
      if (!isObjectShallowEqual(tempAllHistoricalAmounts, allHistoricalAmounts)) {
        setAllHistoricalAmounts(tempAllHistoricalAmounts);
      }
  
      // Also, set the displayed historicalAmounts by slicing the last histPeriods
      const displayedHistHeaders = histHeaders.slice(-currentModel.histPeriods);
      const tempDisplayedHistoricalAmounts = {};
      displayedHistHeaders.forEach((header) => {
        const [label] = header;
        tempDisplayedHistoricalAmounts[label] = tempAllHistoricalAmounts[label];
      });
  
      if (!isObjectShallowEqual(tempDisplayedHistoricalAmounts, historicalAmounts)) {
        setHistoricalAmounts(tempDisplayedHistoricalAmounts);
      }
    }
  }, [
    processedData,
    currentModel.histPeriods,
    currentModel.basis,
    selectedCategoryValue,
    calculateSumForPeriod,
    historicalAmounts, // For comparison
    allHistoricalAmounts, // For comparison
    isObjectShallowEqual
  ]);  

    // 17. useEffect to prepopulate form if editing an existing row
    useEffect(() => {
      if (isEditing && rowData) {
        console.log('Editing mode: Initializing with existing row data');
        console.log('rowData:', rowData);
    
        // Prepopulate row details when editing
        setRowName(rowData.name || '');
    
        // Handle Complex Drivers
        if (Array.isArray(rowData.driver)) {
          const [driverType, driverValue] = rowData.driver;
          console.log('Driver Type:', driverType);
          console.log('Driver Value:', driverValue);
    
          setSelectedDriver(driverType);
    
          if (driverType === 'Percent of Another Row') {
            setSelectedReferenceRow(driverValue);
          } else if (driverType === 'Formula of Rows') {
            setSumFormulaInput(driverValue);
          }
    
          // After setting selectedReferenceRow, set referenceRowForecastValues
          if (driverType === 'Percent of Another Row' && driverValue) {
            // Ensure 'aaa' exists in rowNames
            const referenceRowIndex = currentModel.rowNames.indexOf(driverValue);
            console.log(`Reference row index for "${driverValue}":`, referenceRowIndex);
    
            if (referenceRowIndex === -1) {
              console.error(`Reference row "${driverValue}" not found in currentModel.rowNames.`);
              setReferenceRowForecastValues([]);
            } else {
              let parsedForecastRows = [];
              switch (currentModel.basis) {
                case 'Yearly':
                  parsedForecastRows = currentModel.annualForeRows ? JSON.parse(currentModel.annualForeRows) : [];
                  break;
                case 'Quarterly':
                  parsedForecastRows = currentModel.quarterlyForeRows ? JSON.parse(currentModel.quarterlyForeRows) : [];
                  break;
                case 'Monthly':
                  parsedForecastRows = currentModel.monthlyForeRows ? JSON.parse(currentModel.monthlyForeRows) : [];
                  break;
                default:
                  console.error('Invalid model basis');
              }
    
              const selectedRowData = parsedForecastRows[referenceRowIndex] || [];
              console.log('Selected row forecast data:', selectedRowData);
    
              const forecastValues = selectedRowData.slice(0, currentModel.forePeriods) || [];
              console.log('Setting referenceRowForecastValues:', forecastValues);
    
              setReferenceRowForecastValues(forecastValues);
            }
          }
        } else {
          console.log('Driver (String):', rowData.driver);
          setSelectedDriver(rowData.driver);
        }
    
        // Slice historical and forecast data based on model periods
        const slicedHistoricalData = rowData.historicalData.slice(-currentModel.histPeriods);
        const slicedForecastData = rowData.forecastData.slice(0, currentModel.forePeriods);
        const slicedForecastValues = rowData.forecastValues.slice(0, currentModel.forePeriods);
    
        console.log('Editing row with prepopulated data:', {
          rowData,
          slicedHistoricalData,
          slicedForecastData,
          slicedForecastValues
        });
    
        if (isHistorical) {
          // Retrieve historical and forecast headers based on model basis
          let histHeaders = [];
          let foreHeaders = [];
    
          switch (currentModel.basis) {
            case 'Yearly':
              histHeaders = JSON.parse(currentModel.annualHistHeaders || '[]').slice(-currentModel.histPeriods);
              foreHeaders = JSON.parse(currentModel.annualForeHeaders || '[]').slice(0, currentModel.forePeriods);
              break;
            case 'Quarterly':
              histHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]').slice(-currentModel.histPeriods);
              foreHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
              break;
            case 'Monthly':
              histHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]').slice(-currentModel.histPeriods);
              foreHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
              break;
            default:
              console.error('Invalid model basis');
              return;
          }
    
          const histLabels = histHeaders.map((header) => header[0]);
          const foreLabels = foreHeaders.map((header) => header[0]);
    
          // Map slicedHistoricalData to histLabels
          const tempHistoricalAmounts = {};
          histLabels.forEach((label, idx) => {
            tempHistoricalAmounts[label] = Number(slicedHistoricalData[idx]) || 0;
          });
          console.log('Setting historicalAmounts:', tempHistoricalAmounts);
          setHistoricalAmounts(tempHistoricalAmounts);
    
          // Map slicedForecastData to foreLabels
          const tempForecastInputs = {};
          foreLabels.forEach((label, idx) => {
            tempForecastInputs[label] = slicedForecastValues[idx] || '';
          });
          console.log('Setting forecastInputs:', tempForecastInputs);
          setForecastInputs(tempForecastInputs);
    
          // Combine periods for table rows
          const periods = [...histLabels, ...foreLabels];
    
          // Generate table rows based on existing forecast amounts
          console.log('Generating table rows with periods:', periods);
          setTableRows(generateTableRows(periods));
        } else {
          // When not historical, pad historical data with "" based on currentModel's basis and histPeriods
    
          // Retrieve historical headers based on model basis
          let histHeaders = [];
          switch (currentModel.basis) {
            case 'Yearly':
              histHeaders = JSON.parse(currentModel.annualHistHeaders || '[]').slice(-currentModel.histPeriods);
              break;
            case 'Quarterly':
              histHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]').slice(-currentModel.histPeriods);
              break;
            case 'Monthly':
              histHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]').slice(-currentModel.histPeriods);
              break;
            default:
              console.error('Invalid model basis');
              histHeaders = [];
          }
    
          const histLabels = histHeaders.map((header) => header[0]);
    
          // Set historicalAmounts to "" for each historical period
          const tempHistoricalAmounts = {};
          histLabels.forEach((label) => {
            tempHistoricalAmounts[label] = ''; // Set as empty string
          });
          setHistoricalAmounts(tempHistoricalAmounts);
    
          // Set forecastInputs to "" for each forecast period
          let foreHeaders = [];
          switch (currentModel.basis) {
            case 'Yearly':
              foreHeaders = JSON.parse(currentModel.annualForeHeaders || '[]').slice(0, currentModel.forePeriods);
              break;
            case 'Quarterly':
              foreHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
              break;
            case 'Monthly':
              foreHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
              break;
            default:
              foreHeaders = [];
          }
    
          const tempForecastInputs = {};
          foreHeaders.forEach((header) => {
            tempForecastInputs[header[0]] = ''; // Set as empty string
          });
          setForecastInputs(tempForecastInputs);
    
          // Combine periods for table rows
          const periods = [...histLabels, ...foreHeaders.map((header) => header[0])];
          setTableRows(generateTableRows(periods));
        }
      }
    }, [isEditing, rowData, currentModel, isHistorical]);        

  // 13. useEffect to generate table rows when dependencies change
  useEffect(() => {
    let histHeaders = [];
    let foreHeaders = [];

    // Retrieve the appropriate headers based on the model's basis
    switch (currentModel.basis) {
      case 'Yearly':
        histHeaders = JSON.parse(currentModel.annualHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.annualForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      case 'Quarterly':
        histHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      case 'Monthly':
        histHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      default:
        console.error('Invalid model basis');
        return;
    }

    // Combine historical and forecast headers into periods
    const periods = [
      ...histHeaders.map((header) => header[0]),
      ...foreHeaders.map((header) => header[0]),
    ];

    // Update table rows whenever relevant dependencies change
    setTableRows(generateTableRows(periods));
  }, [
    selectedCategoryValue, // Trigger when the selected category value changes
    selectedVariable,
    selectedDateColumn,
    selectedCategory,
    forecastInputs, // Trigger when forecast inputs change
    forecastAmounts, // Trigger when forecast amounts change
    historicalAmounts, // Trigger when historical amounts change
    currentModel, // Trigger when the model changes (including headers)
    generateTableRows,
  ]);

  // 14. useEffect to recalculate forecast amounts whenever forecastInputs or historicalAmounts change
  useEffect(() => {
    if (isEditingSetup) {
      // Skip recalculations during setup editing
      console.log('Recalculation skipped: isEditingSetup is true');
      return;
    }
  
    if (
      Object.keys(forecastInputs).length === 0 ||
      Object.keys(historicalAmounts).length === 0
    ) {
      // Do not calculate if inputs are not set
      console.log('Recalculation skipped: forecastInputs or historicalAmounts are empty');
      return;
    }
  
    console.log('Recalculating forecast amounts');
    let histHeaders = [];
    let foreHeaders = [];
  
    switch (currentModel.basis) {
      case 'Yearly':
        histHeaders = JSON.parse(currentModel.annualHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.annualForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      case 'Quarterly':
        histHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      case 'Monthly':
        histHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      default:
        console.error('Invalid model basis');
        return;
    }
  
    const periods = [
      ...histHeaders.map((header) => header[0]),
      ...foreHeaders.map((header) => header[0]),
    ];
  
    recalculateForecastAmounts(forecastInputs, periods);
  }, [
    forecastInputs,
    historicalAmounts,
    currentModel.histPeriods,
    currentModel.forePeriods,
    currentModel.basis,
    recalculateForecastAmounts,
    isEditingSetup,
  ]);  

  // Helper function to calculate forecast row value based on driver
const calculateForecastRowValue = (driver, foreValue, previousAmount) => {
  if (driver === 'Fixed Growth') {
      const growthAmount = parseFloat(foreValue) || 0;
      return (previousAmount + growthAmount).toFixed(2);
  } else if (driver === 'Percent') {
      const percentage = parseFloat(foreValue) || 0;
      const newAmount = previousAmount + (previousAmount * percentage) / 100;
      return newAmount.toFixed(2);
  } else if (driver === 'Percent of Another Row') {
      // This case should already be handled in the main function
      return (parseFloat(foreValue) || 0).toFixed(2);
  }
  // Add other drivers as needed
  return '0.00';
};

// Add this helper function within your component
const getPeriodsToLookBack = () => {
  if (selectedPeriodOption === 'Previous period') {
    return 1;
  } else if (selectedPeriodOption === 'Prior year month') {
    switch (currentModel.basis) {
      case 'Yearly':
        return 1;
      case 'Quarterly':
        return 4;
      case 'Monthly':
        return 12;
      default:
        console.error('Invalid model basis');
        return 1;
    }
  } else if (selectedPeriodOption === 'Specific period') {
    const period = parseInt(specificPeriod, 10);
    return isNaN(period) || period < 1 ? 1 : period;
  }
  return 1; // Default fallback
};
  
  const handleImportRowData = useCallback(async () => {
    console.log('handleImportRowData called');
    if (isSubmitting) return;
    if (!rowName || !selectedDriver) {
        console.error('Row name and driver must be filled.');
        alert('Please fill in all required fields: Row Name and Driver.');
        return;
    }
    setIsSubmitting(true);

    // Initialize empty arrays for historical and forecast data
    let annualHistData = Array(10).fill('0');
    let quarterlyHistData = Array(40).fill('0');
    let monthlyHistData = Array(120).fill('0');

    let annualForeValues = Array(10).fill('0'); // To store percentages/growth amounts
    let quarterlyForeValues = Array(40).fill('0');
    let monthlyForeValues = Array(120).fill('0');

    let annualForeRows = Array(10).fill('0'); // To store calculated forecast values
    let quarterlyForeRows = Array(40).fill('0');
    let monthlyForeRows = Array(120).fill('0');

      // Retrieve offset and selected period option
  const offset = getPeriodsToLookBack();
  const selectedPeriod = selectedPeriodOption;

    if (isHistorical) {
        if (selectedDriver === 'Formula of Rows') {
                // **Handling "Formula of Rows" Driver for All Bases**
                console.log('sumFormulaInput: ', sumFormulaInput);

                const bases = ['Annual', 'Quarterly', 'Monthly'];
                const calculatedData = {};

                // Loop through each basis and calculate values
                for (const basis of bases) {
                    const { calculatedHistoricalValues, calculatedForecastValues } = calculateSumBasedOnFormula(sumFormulaInput, basis);

                    calculatedData[basis] = {
                        historical: Object.values(calculatedHistoricalValues),
                        forecast: Object.values(calculatedForecastValues),
                    };
                }

                // Assign calculated historical data
                annualHistData = calculatedData['Annual'].historical;
                quarterlyHistData = calculatedData['Quarterly'].historical;
                monthlyHistData = calculatedData['Monthly'].historical;

                // Assign calculated forecast data
                annualForeValues = calculatedData['Annual'].forecast; // Store percentages/growth amounts
                quarterlyForeValues = calculatedData['Quarterly'].forecast;
                monthlyForeValues = calculatedData['Monthly'].forecast;

                // For "Formula of Rows", forecast rows are the calculated values
                annualForeRows = calculatedData['Annual'].forecast;
                quarterlyForeRows = calculatedData['Quarterly'].forecast;
                monthlyForeRows = calculatedData['Monthly'].forecast;

                console.log('Calculated Historical Data:', {
                    annualHistData,
                    quarterlyHistData,
                    monthlyHistData,
                });
                console.log('Calculated Forecast Data:', {
                    annualForeValues,
                    quarterlyForeValues,
                    monthlyForeValues,
                });
                console.log('Calculated Forecast Rows:', {
                    annualForeRows,
                    quarterlyForeRows,
                    monthlyForeRows,
                });
        } else {
            // Helper function to process historical data using calculateSumForPeriod
            const processHistoricalData = (histHeaders) => {
              return histHeaders.map((header) => {
                  const [year, startDate, endDate] = header;
                  const sum = calculateSumForPeriod(startDate, endDate, processedData, selectedCategoryValue);
                  return sum !== 'Error' ? String(sum) : '';
              });
            };
            try {
              // Process Annual Historical Data
              const annualHistHeaders = JSON.parse(currentModel.annualHistHeaders || '[]');
              annualHistData = processHistoricalData(annualHistHeaders);

              // Process Quarterly Historical Data
              const quarterlyHistHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]');
              quarterlyHistData = processHistoricalData(quarterlyHistHeaders);

              // Process Monthly Historical Data
              const monthlyHistHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]');
              monthlyHistData = processHistoricalData(monthlyHistHeaders);
            } catch (error) {
                console.error('Error processing historical data:', error);
                alert('An error occurred while processing historical data.');
                setIsSubmitting(false);
                return;
            }

            // Handle Forecast Data
            try {
              const generateForecastData = (foreHeaders, foreValuesArray, foreRowsArray, basis) => {
                  foreHeaders.forEach((header, index) => {
                      const periodLabel = header[0];
                      const foreValue = forecastInputs[periodLabel] || '';
                      console.log('foreValue: ', foreValue);

                      // Store the input percentage/growth amount in foreValuesArray
                      foreValuesArray[index] = foreValue !== '' ? String(foreValue) : '0';

                      // Calculate the forecasted amount based on driver
                      if (selectedDriver === 'Fixed Amount') {
                          // For Fixed Amount, the foreRows are the same as foreValues
                          foreRowsArray[index] = foreValue !== '' ? String(foreValue) : '0';
                      } else if (selectedDriver === 'Fixed Growth' || selectedDriver === 'Percent') {
                        // **Use pre-calculated forecastAmounts for Fixed Growth and Percent**
                        const calculatedAmount = forecastAmounts[periodLabel] || '0.00';
                        foreRowsArray[index] = calculatedAmount;
                      } else if (selectedDriver === 'Percent of Another Row') {
                        // **Handling "Percent of Another Row" Driver**
                        if (!selectedReferenceRow) {
                            console.error('Reference row must be specified for "Percent of Another Row" driver.');
                            foreRowsArray[index] = '0';
                            return;
                        }

                        // Find the index of the reference row in rowNames
                        const rowIndex = currentModel.rowNames.indexOf(selectedReferenceRow);
                        if (rowIndex === -1) {
                            console.error(`Reference row "${selectedReferenceRow}" not found.`);
                            foreRowsArray[index] = '0';
                            return;
                        }

                        console.log('rowIndex: ', rowIndex);
                        console.log('currentModel.annualForeRows[rowIndex]: ', JSON.parse(currentModel.annualForeRows)[rowIndex]);

                        // Retrieve the reference forecast value based on the basis
                        let referenceForecastValue = 0;
                        if (basis === 'Annual') {
                            referenceForecastValue = parseFloat(JSON.parse(currentModel.annualForeRows)[rowIndex][index]) || 0;
                        } else if (basis === 'Quarterly') {
                            referenceForecastValue = parseFloat(JSON.parse(currentModel.quarterlyForeRows)[rowIndex][index]) || 0;
                        } else if (basis === 'Monthly') {
                            referenceForecastValue = parseFloat(JSON.parse(currentModel.monthlyForeRows)[rowIndex][index]) || 0;
                        }

                        // Retrieve the percentage
                        const percentage = parseFloat(foreValue) || 0;

                        // Calculate the forecasted amount
                        const calculatedValue = (referenceForecastValue * percentage) / 100;
                        foreRowsArray[index] = calculatedValue.toFixed(2);

                        console.log(`Percent of Another Row - Reference Forecast Value (${basis}, period ${index + 1}): ${referenceForecastValue}, Percentage: ${percentage}, Calculated Value: ${calculatedValue}`);
                    }
                  });
              };

              // Process Annual Forecast Data
              const annualForeHeaders = JSON.parse(currentModel.annualForeHeaders || '[]');
              generateForecastData(annualForeHeaders, annualForeValues, annualForeRows, 'Annual');

              // Process Quarterly Forecast Data
              const quarterlyForeHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]');
              generateForecastData(quarterlyForeHeaders, quarterlyForeValues, quarterlyForeRows, 'Quarterly');

              // Process Monthly Forecast Data
              const monthlyForeHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]');
              generateForecastData(monthlyForeHeaders, monthlyForeValues, monthlyForeRows, 'Monthly');

              console.log('Processed Forecast Values and Rows:', {
                  annualForeValues,
                  quarterlyForeValues,
                  monthlyForeValues,
                  annualForeRows,
                  quarterlyForeRows,
                  monthlyForeRows,
              });
          } catch (error) {
              console.error('Error processing forecast data:', error);
              alert('An error occurred while processing forecast data.');
              setIsSubmitting(false);
              return;
          }
        } // End of if (isHistorical) block
      } else {
          try {
                // Overwrite the last `histPeriods` historical entries with user-entered values from `historicalAmounts`
                const overwriteHistorical = (histDataArray, histPeriods, histLabels) => {
                  histLabels.forEach((label, idx) => {
                      const position = histDataArray.length - histPeriods + idx;
                      histDataArray[position] = historicalAmounts[label] || '0';
                  });
              };

              // Determine histLabels based on basis
              const overwriteHistoricalData = () => {
                  const bases = ['Annual', 'Quarterly', 'Monthly'];
                  bases.forEach((basis) => {
                      let histHeaders = [];
                      let histDataArray;
                      let histPeriods;

                      switch (basis) {
                          case 'Annual':
                              histHeaders = JSON.parse(currentModel.annualHistHeaders || '[]').slice(-currentModel.histPeriods);
                              histDataArray = annualHistData;
                              histPeriods = currentModel.histPeriods;
                              break;
                          case 'Quarterly':
                              histHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]').slice(-currentModel.histPeriods);
                              histDataArray = quarterlyHistData;
                              histPeriods = currentModel.histPeriods;
                              break;
                          case 'Monthly':
                              histHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]').slice(-currentModel.histPeriods);
                              histDataArray = monthlyHistData;
                              histPeriods = currentModel.histPeriods;
                              break;
                          default:
                              console.error('Invalid model basis');
                              return;
                      }

                      const histLabels = histHeaders.map(header => header[0]);
                      overwriteHistorical(histDataArray, histPeriods, histLabels);
                      console.log(`Overwritten Historical Data for ${basis}:`, histDataArray);
                  });
              };

              overwriteHistoricalData();
          } catch (error) {
              console.error('Error overwriting historical data for non-historical row:', error);
              alert('An error occurred while setting historical data for non-historical row.');
              setIsSubmitting(false);
              return;
          }

      // **b. Overwrite the Forecast Entries with User Inputs Using forecastAmounts and Apply Offset Logic**
      try {
        const overwriteForecast = (foreHeaders, foreValuesArray, foreRowsArray, basis) => {
          foreHeaders.slice(0, currentModel.forePeriods).forEach((header, idx) => {
              const periodLabel = header[0];
              const foreValue = forecastInputs[periodLabel] || '0';
              foreValuesArray[idx] = foreValue !== '' ? String(foreValue) : '0';

              if (selectedDriver === 'Formula of Rows' || selectedDriver === 'Fixed Amount') {
                  // For Formula of Rows and Fixed Amount, forecast rows are the same as foreValues
                  foreRowsArray[idx] = foreValue !== '' ? String(foreValue) : '0';
              } else {
                // For Fixed Growth and Percent, use pre-calculated forecastAmounts
                const calculatedAmount = forecastAmounts[periodLabel] || '0.00';
                foreRowsArray[idx] = calculatedAmount;
              }

              console.log(`Forecast for ${basis} - Period ${idx + 1}:`, {
                  foreValue,
                  calculatedAmount: foreRowsArray[idx],
              });
          });

          // **Apply Offset Logic for Forecast Rows Beyond forePeriods**
          for (let i = currentModel.forePeriods; i < foreRowsArray.length; i++) {
            const referenceIndex = i - offset;

            if (referenceIndex < currentModel.forePeriods) {
              // Reference is within the current forecast periods
              foreRowsArray[i] = foreRowsArray[referenceIndex] || '0';
            } else {
              // Reference is in the historical data
              const histIndex = referenceIndex - currentModel.forePeriods;
              foreRowsArray[i] = histIndex >= 0 ? (historicalAmounts[foreHeaders[i].label] || '0') : '0';
            }

            console.log(`Forecast for ${basis} - Period ${i + 1} (Offset ${offset}): ${foreRowsArray[i]}`);
          }
      };

      const overwriteForecastData = () => {
          const bases = ['Annual', 'Quarterly', 'Monthly'];
          bases.forEach((basis) => {
              let foreHeaders = [];
              let foreValuesArray;
              let foreRowsArray;

              switch (basis) {
                  case 'Annual':
                      foreHeaders = JSON.parse(currentModel.annualForeHeaders || '[]');
                      foreValuesArray = annualForeValues;
                      foreRowsArray = annualForeRows;
                      break;
                  case 'Quarterly':
                      foreHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]');
                      foreValuesArray = quarterlyForeValues;
                      foreRowsArray = quarterlyForeRows;
                      break;
                  case 'Monthly':
                      foreHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]');
                      foreValuesArray = monthlyForeValues;
                      foreRowsArray = monthlyForeRows;
                      break;
                  default:
                      console.error('Invalid model basis');
                      return;
              }

              overwriteForecast(foreHeaders, foreValuesArray, foreRowsArray, basis);
              console.log(`Overwritten Forecast Data for ${basis}:`, {
                  foreValuesArray,
                  foreRowsArray,
              });
          });
      };

      overwriteForecastData();
      } catch (error) {
          console.error('Error overwriting forecast data for non-historical row:', error);
          alert('An error occurred while setting forecast data for non-historical row.');
          setIsSubmitting(false);
          return;
      }
  }
        // **Prepare the Row Data to be Sent to the Backend**
        const rowDataToSend = {
            rowNames: [rowName],
            rowVariables: isHistorical ? [selectedCategoryValue] : ['None'],
            categories: isHistorical ? [selectedCategory] : ['None'],
            groupedOver: isHistorical ? selectedVariable : 'None',
            dateColumn: isHistorical ? selectedDateColumn : 'None',
            sourceFile: isHistorical
                ? [
                    [
                        selectedSpreadsheet,
                        spreadsheets.find((s) => s.id === selectedSpreadsheet)?.suborganisation || '',
                        spreadsheets.find((s) => s.id === selectedSpreadsheet)?.newName || '',
                    ],
                ]
                : ['None'],
                drivers: [
                  (() => {
                    if (selectedDriver === 'Percent of Another Row') {
                      return ['Percent of Another Row', selectedReferenceRow];
                    } else if (selectedDriver === 'Formula of Rows') {
                      return ['Formula of Rows', sumFormulaInput];
                    } else if (selectedDriver === 'Fixed Growth' || selectedDriver === 'Percent') {
                      return [selectedDriver, offset, selectedPeriod];
                    }
                    return selectedDriver; // For Fixed Amount and other drivers
                  })(),
                ],
            supportingAssumptionModelIds: [''],
            annualHistRows: [annualHistData],
            quarterlyHistRows: [quarterlyHistData],
            monthlyHistRows: [monthlyHistData],
            annualForeRows: [annualForeRows],
            quarterlyForeRows: [quarterlyForeRows],
            monthlyForeRows: [monthlyForeRows],
            annualForeValues: [annualForeValues],
            quarterlyForeValues: [quarterlyForeValues],
            monthlyForeValues: [monthlyForeValues],
            quarterlySeasonality: [[25, 25, 25, 25]],
            monthlySeasonality: [[8.33, 8.33, 8.34, 8.33, 8.33, 8.34, 8.33, 8.33, 8.34, 8.33, 8.33, 8.34]]
        };

        console.log('Row Data to Send:', rowDataToSend); // Add this log for debugging

        try {
            const response = await api.put(`/models/${currentModel.id}/add-row`, rowDataToSend);
            if (response.status === 200) {
                console.log('Row data successfully imported');
                const updatedModel = response.data.model;
                onRowAdded(updatedModel);
                onClose();
            } else {
                console.error('Unexpected response:', response.data);
                alert(`Failed to import row data: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error importing row data:', error);
            alert('An error occurred while importing row data.');
        } finally {
            setIsSubmitting(false); // Reset submitting state
        }
  }, [
        isSubmitting,
        rowName,
        selectedDriver,
        currentModel,
        calculateSumForPeriod,
        processedData,
        forecastInputs,
        spreadsheets,
        isHistorical,
        historicalAmounts, // Added historicalAmounts as it's used in overwriteForecast
        forecastAmounts,    // Added forecastAmounts as it's used in overwriteForecast
        calculatedHistoricalValues, // For "Formula of Rows" driver
        calculatedForecastValues,  // For "Formula of Rows" driver
        selectedCategoryValue,
        selectedCategory,
        selectedVariable,
        selectedDateColumn,
        selectedSpreadsheet,
        selectedReferenceRow,
        onRowAdded,
        onClose,
        getPeriodsToLookBack, // Ensure getPeriodsToLookBack is included in dependencies
        selectedPeriodOption,  // Include selectedPeriodOption in dependencies
        sumFormulaInput,       // Include sumFormulaInput
        sumValidationMessage,  // Include sumValidationMessage
    ]);


    const handleSaveChanges = useCallback(async () => {
      console.log('handleSaveChanges called');
      if (onSave && rowData) {
        // Clone the existing historical and forecast data from rowData
        let existingHistoricalData = [...rowData.historicalData];
        let existingForecastData = [...rowData.forecastData];
        let existingForecastValues = [...rowData.forecastValues];
    
        console.log('rowdata: ', rowData);
    
        // Determine whether to use updated state variables or existing rowData values
        const driver = isEditingSetup ? selectedDriver : rowData.driver;
        console.log('driver: ', driver);
        const category = isEditingSetup ? selectedCategory : rowData.selectedCategory;
        console.log('category: ', category);
        const categoryValue = isEditingSetup ? selectedCategoryValue : rowData.selectedCategoryValue;
        const groupedOver = isEditingSetup ? selectedVariable : rowData.selectedVariable;
        const dateColumn = isEditingSetup ? selectedDateColumn : rowData.selectedDateColumn;
        const sourceFile = isEditingSetup
          ? isHistorical
            ? [
                [
                  selectedSpreadsheet,
                  spreadsheets.find((s) => s.id === selectedSpreadsheet)?.suborganisation || '',
                  spreadsheets.find((s) => s.id === selectedSpreadsheet)?.newName || '',
                ],
              ]
            : ['None']
          : rowData.sourceFile;
    
        if (isHistorical) {
          // Update historical values based on user input
          const updatedHistoricalValues = Object.values(historicalAmounts).map(v => String(v));
          const startHistIndex = existingHistoricalData.length - currentModel.histPeriods;
          for (let i = 0; i < updatedHistoricalValues.length; i++) {
            existingHistoricalData[startHistIndex + i] = updatedHistoricalValues[i];
          }
        } else {
          // When not historical, pad historical data with ""
          switch (currentModel.basis) {
            case 'Yearly':
              existingHistoricalData = Array(currentModel.histPeriods).fill('0');
              break;
            case 'Quarterly':
              existingHistoricalData = Array(currentModel.histPeriods).fill('');
              break;
            case 'Monthly':
              existingHistoricalData = Array(currentModel.histPeriods).fill('');
              break;
            default:
              console.error('Invalid model basis');
              existingHistoricalData = [];
          }
        }
    
        // Initialize placeholders for updatedForecastRows and updatedForecastValues
        let updatedForecastRows = [];
        let updatedForecastValues = [];
    
        // Handle different drivers
        if (Array.isArray(driver)) {
          const [driverType, driverValue] = driver;
    
          switch (driverType) {
            case 'Formula of Rows':
              if (!sumFormulaInput || sumFormulaInput.trim() === '') {
                console.error('Formula is required for "Formula of Rows" driver.');
                alert('Please enter a formula for "Formula of Rows" driver.');
                return; // Exit early if formula is not provided
              }
              if (sumValidationMessage !== "Formula is valid!") {
                alert('Please ensure the Formula of Rows formula is valid before saving.');
                return;
              }
              // Use calculatedForecastValues which are derived from the validated formula
              updatedForecastRows = Object.values(calculatedForecastValues).map(v => String(v));
              // Depending on your backend needs, you might not need to send foreValues for Formula of Rows
              updatedForecastValues = []; // Or set to null/empty array if not needed
              break;
    
            case 'Percent of Another Row':
              if (!selectedReferenceRow) {
                console.error('Selected Reference Row is required for "Percent of Another Row" driver.');
                alert('Please select a reference row for "Percent of Another Row" driver.');
                return; // Exit early if reference row is not selected
              }
              // Use forecastInputs as foreValues
              updatedForecastValues = Object.values(forecastInputs).map(inputValue => String(inputValue));
              // Use calculatedForecastValues for foreRows
              updatedForecastRows = Object.values(forecastAmounts).map(v => String(v));
              break;
    
            default:
              console.error('Unknown driver type:', driverType);
              alert('Unknown driver type. Please check your selections.');
              return;
          }
        } else {
          // Handle standard drivers: 'Fixed Amount', 'Fixed Growth', 'Percent Increase'
          switch (driver) {
            case 'Fixed Amount':
              updatedForecastRows = Object.values(forecastAmounts).map(v => String(v));
              updatedForecastValues = Object.values(forecastAmounts).map(v => String(v));
              break;
            case 'Fixed Growth':
            case 'Percent':
              updatedForecastRows = Object.values(forecastAmounts).map(v => String(v));
              updatedForecastValues = Object.values(forecastInputs).map(v => String(v));
              break;
            default:
              console.error('Unknown driver:', driver);
              alert('Unknown driver type. Please check your selections.');
              return;
          }
        }
    
        // Replace the forecast data with new values
        if (updatedForecastValues.length > 0) {
          for (let i = 0; i < updatedForecastValues.length; i++) {
            existingForecastValues[i] = updatedForecastValues[i];
          }
        }
    
        if (updatedForecastRows.length > 0) {
          for (let i = 0; i < updatedForecastRows.length; i++) {
            existingForecastData[i] = updatedForecastRows[i];
          }
        }
    
        // **Format the driver field based on its type**
        let formattedDriver;
        console.log('Selected Driver:', selectedDriver);
    
        if (Array.isArray(driver)) {
          // Driver is already in the correct format (array)
          formattedDriver = driver;
        } else {
          // For standard drivers, keep as string
          formattedDriver = driver;
        }
    
        // **Prepare the updated row data to be sent**
        const updatedData = {
          rowIndex,
          rowName, // Include updated row name
          driver: formattedDriver, // Updated driver: string or list
          category: category, // Updated category
          categoryValue: categoryValue, // Updated category value
          groupedOver: groupedOver, // Updated grouped over
          dateColumn: dateColumn, // Updated date column
          sourceFile: sourceFile,
          updatedHistoricalValues: existingHistoricalData,
          updatedForecastValues: existingForecastValues,
          updatedForecastRows: existingForecastData, // Include foreRows
          basis: currentModel.basis,
        };
    
        console.log('Submitting updated row data:', updatedData);
        await onSave(updatedData);
      }
    }, [
      onSave,
      rowIndex,
      historicalAmounts,
      forecastAmounts,
      forecastInputs,
      rowName,
      selectedDriver,
      selectedCategory,
      selectedCategoryValue,
      selectedVariable,
      selectedDateColumn,
      selectedReferenceRow,
      selectedSpreadsheet,
      spreadsheets,
      isHistorical,
      rowData,
      currentModel.histPeriods,
      currentModel.forePeriods,
      currentModel.basis,
      sumFormulaInput, // Added dependency for sumFormulaInput
      sumValidationMessage,
      calculatedForecastValues,
      forecastAmounts,
    ]);    

  // 18. useEffect to set setup-related fields when isEditingSetup changes
  useEffect(() => {
    if (isEditingSetup && isEditing && rowData) {
      console.log(
        'Editing setup options: Initializing setup fields from rowData'
      );

      // Update only if the new value differs from the current state
      if (selectedSpreadsheet !== rowData.selectedSpreadsheetId) {
        setSelectedSpreadsheet(rowData.selectedSpreadsheetId || '');
      }

      if (selectedCategory !== rowData.selectedCategory) {
        setSelectedCategory(rowData.selectedCategory || '');
      }

      if (selectedVariable !== rowData.selectedVariable) {
        setSelectedVariable(rowData.selectedVariable || '');
      }

      if (selectedDateColumn !== rowData.selectedDateColumn) {
        setSelectedDateColumn(rowData.selectedDateColumn || '');
      }

      if (selectedCategoryValue !== rowData.selectedCategoryValue) {
        setSelectedCategoryValue(rowData.selectedCategoryValue || '');
      }

      // After setting up, allow recalculations
    } else if (!isEditingSetup && isEditing) {
      console.log('Setup editing canceled: Resetting setup fields');

      if (selectedSpreadsheet !== '') {
        setSelectedSpreadsheet('');
      }

      if (selectedCategory !== '') {
        setSelectedCategory('');
      }

      if (selectedVariable !== '') {
        setSelectedVariable('');
      }

      if (selectedDateColumn !== '') {
        setSelectedDateColumn('');
      }

      if (selectedCategoryValue !== '') {
        setSelectedCategoryValue('');
      }

      console.log('Setup fields reset and isFirstLoad set to false');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingSetup, isEditing, rowData]);

  // 19. useEffect to reset selectedReferenceRow when driver changes
  useEffect(() => {
    if (selectedDriver !== 'Percent of Another Row') {
      setSelectedReferenceRow('');
    }
  }, [selectedDriver]);


  // Toggle between single and consolidated spreadsheet modes
  const handleToggleSpreadsheetMode = () => {
    setIsConsolidated((prevState) => {
      const newState = !prevState;
      if (!newState) {
        setSelectedSpreadsheets([]); // Clear consolidated selections when switching back
      }
      return newState;
    });
  };
  

  const handleDeleteRow = useCallback(async () => {
    // Step 1: Prompt the user for confirmation
    const confirmDelete = window.confirm('Are you sure you want to delete this row? This action cannot be undone.');
  
    if (!confirmDelete) {
      console.log('Row deletion canceled by the user.');
      return;
    }
  
    console.log(`Attempting to delete row at index: ${rowIndex}`);
  
    try {
      // Step 2: Make an API call to delete the row
      const response = await api.delete(`/model/${currentModel.id}/delete-row`, {
        data: { rowIndex }, // The rowIndex is passed correctly here as part of the config object
      });
  
      if (response.status === 200) {
        console.log('Row successfully deleted:', response.data);
  
        // Step 3: Update the parent component or state
        const updatedModel = response.data.model; // Assuming the updated model is returned in the response
        onRowAdded(updatedModel); // Update the parent with the new data
  
        // Close the popup after successful deletion
        window.location.reload()
        onClose();
      } else {
        console.error('Failed to delete row:', response.data);
        alert(`Failed to delete row: ${response.data.message || 'Unknown error.'}`);
      }
    } catch (error) {
      console.error('Error deleting row:', error);
      alert('An error occurred while deleting the row. Please try again.');
    }
  }, [rowIndex, currentModel.id, onRowAdded, onClose, api]);
  


  return (
    <div className="popup-content">
      {/* Close Button */}
      <button className="popup-close" onClick={onClose}>
        ×
      </button>
  
      {/* Header Right Section */}
      <div className="popup-header-right">
        {/* Conditionally render Checkbox and Toggle Button */}
        {(!isEditing || isEditingSetup) && (
          <>
            {/* Checkbox for "Based on historical data" */}
            <label className="popup-header-label">
              <input
                type="checkbox"
                checked={isHistorical}
                onChange={(e) => setIsHistorical(e.target.checked)}
              />
              Row is based on historical data
            </label>

            {/* Toggle button for single/consolidated spreadsheet */}
            {isHistorical && (
              <button
                type="button"
                onClick={handleToggleSpreadsheetMode}
                className="edit-setup-button"
              >
                {isConsolidated
                  ? 'Based on consolidated spreadsheets'
                  : 'Based on single spreadsheet'}
              </button>
            )}
          </>
        )}

        {/* Delete Row button (always visible when editing) */}
        {isEditing && (
          <button type="button" onClick={handleDeleteRow} className="edit-setup-button">
            Delete Row
          </button>
        )}
      </div>
  
      {/* Popup Title */}
      <h2>{isEditing ? 'Edit Row' : 'Add Simplified Row'}</h2>
  
      {/* Form Section */}
      <div>
        <div>
          <label htmlFor="row-name">Row Name:</label>
          <input
            id="row-name"
            type="text"
            value={rowName}
            onChange={(e) => setRowName(e.target.value)}
          />
        </div>
  
        {/* "Edit Setup Options" Button */}
        {isEditing && isHistorical && (
          <button
            type="button"
            onClick={() => setIsEditingSetup((prev) => !prev)}
            className="edit-setup-button"
          >
            {isEditingSetup ? 'Cancel Setup Editing' : 'Edit Setup Options'}
          </button>
        )}
  
        {/* Conditionally Render Setup Options */}
        {isHistorical && (!isEditing || isEditingSetup) && (
          <>
            <div>
              <label htmlFor="driver-select">Select Driver:</label>
              <select
                id="driver-select"
                value={selectedDriver}
                onChange={(e) => {
                  const newDriver = e.target.value;
                  setSelectedDriver(newDriver);
                  if (newDriver !== 'Formula of Rows') {
                    setSumFormulaInput('');
                    setSumFormulaToValidate('');
                    setSumValidationMessage(null);
                  }
                }}
              >
                <option value="Fixed Amount">Fixed Amount</option>
                <option value="Fixed Growth">Fixed Growth</option>
                <option value="Percent">Percent Increase</option>
                <option value="Percent of Another Row">Percent of Another Row</option>
                <option value="Formula of Rows">Formula of Rows</option>
              </select>
            </div>

            {/* Offset set period settings - conditional */}
            {(selectedDriver === 'Percent' || selectedDriver === 'Fixed Growth') && (
              <div>
                <label htmlFor="period-select">Select Period:</label>
                <select
                  id="period-select"
                  value={selectedPeriodOption}
                  onChange={(e) => setSelectedPeriodOption(e.target.value)}
                >
                  <option value="Previous period">Previous period</option>
                  <option value="Prior year month">Prior year month</option>
                  <option value="Specific period">Specific period</option>
                </select>

                {/* Conditionally render the input box if 'Specific period' is selected */}
                {selectedPeriodOption === 'Specific period' && (
                  <input
                    type="number"
                    min="1"
                    placeholder="Enter number of periods"
                    value={specificPeriod}
                    onChange={(e) => setSpecificPeriod(e.target.value)}
                    style={{ marginLeft: '10px' }} // Optional: Add some spacing
                  />
                )}
              </div>
            )}

            {/* Reference Row Dropdown - Conditional */}
            {selectedDriver === 'Percent of Another Row' && (
              <div>
                <label htmlFor="reference-row-select">Select Reference Row:</label>
                <select
                  id="reference-row-select"
                  value={selectedReferenceRow}
                  onChange={(e) => setSelectedReferenceRow(e.target.value)}
                >
                  <option value="">-- Select Row --</option>
                  {currentModel.rowNames && currentModel.rowNames.length > 0 ? (
                    currentModel.rowNames
                      .filter((row) => typeof row === 'string') // Filter out non-string entries
                      .map((row, index) => (
                        <option key={index} value={row}>
                          {row}
                        </option>
                      ))
                  ) : (
                    <option disabled>No rows available</option>
                  )}
                </select>
              </div>
            )}

            {/* Conditionally render Formula Input and Validate Button */}
            {selectedDriver === 'Formula of Rows' && (
              <div className="formula-validation-section">
                <div>
                  <label htmlFor="sum-formula-input">Enter Formula:</label>
                  <input
                    id="sum-formula-input"
                    type="text"
                    value={sumFormulaInput}
                    onChange={(e) => {
                      setSumFormulaInput(e.target.value);
                      setSumValidationMessage(null); // Hide validation message when editing
                      setSumFormulaToValidate(''); // Reset the formula to validate
                    }}
                    placeholder="'row1'+'row2'-'row3'"
                  />
                </div>
                <button
                  type="button"
                  onClick={() => setSumFormulaToValidate(sumFormulaInput)}
                  className="validate-formula-button"
                >
                  Validate Formula
                </button>
                {sumValidationMessage && (
                  <p className={`validation-message ${sumValidationMessage.startsWith('Error') ? 'error' : 'success'}`}>
                    {sumValidationMessage}
                  </p>
                )}

                {/* Conditionally render FormulaValidatorRowSums only if sumFormulaToValidate is not empty */}
                {sumFormulaToValidate && (
                  <FormulaValidator
                    formula={sumFormulaToValidate}
                    columnData={currentModel.rowNames.map((name) => ({ name, status: 'Numeric' }))}
                    onValidationResult={handleValidationResult}
                  />
                )}
              </div>
            )}

            {selectedDriver !== 'Formula of Rows' && (
              <SpreadsheetSelector
                spreadsheets={spreadsheets}
                selectedSpreadsheet={selectedSpreadsheet}
                setSelectedSpreadsheet={setSelectedSpreadsheet}
                selectedSpreadsheets={selectedSpreadsheets}
                setSelectedSpreadsheets={setSelectedSpreadsheets}
                isConsolidated={isConsolidated}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedVariable={selectedVariable}
                setSelectedVariable={setSelectedVariable}
                selectedDateColumn={selectedDateColumn}
                setSelectedDateColumn={setSelectedDateColumn}
                categoryValues={categoryValues}
                setCategoryValues={setCategoryValues}
                selectedCategoryValue={selectedCategoryValue}
                setSelectedCategoryValue={setSelectedCategoryValue}
                handleCategoryValueChange={handleCategoryValueChange}
                fetchSpreadsheetData={loadSpreadsheetData}
                processedData={processedData}
                currentOrgId={currentModel.organisationId}
                isEditable={!isEditing || isEditingSetup} // New prop
              />
            )}
          </>
        )}
  
        {!isHistorical && (
          <div>
            <label htmlFor="driver-select-non-historical">Driver:</label>
            <select
              id="driver-select-non-historical"
              value={selectedDriver}
              onChange={(e) => setSelectedDriver(e.target.value)}
              className="driver-select"
            >
              <option value="Fixed Amount">Fixed Amount</option>
              <option value="Fixed Growth">Fixed Growth</option>
              <option value="Percent">Percent Increase</option>
            </select>

            {/* Reference Row Dropdown - Conditional (Non-Historical Mode) */}
            {selectedDriver === 'Percent of Another Row' && (
              <div>
                <label htmlFor="reference-row-select-non-historical">Select Reference Row:</label>
                <select
                  id="reference-row-select-non-historical"
                  value={selectedReferenceRow}
                  onChange={(e) => setSelectedReferenceRow(e.target.value)}
                >
                  <option value="">-- Select Row --</option>
                  {currentModel.rowNames && currentModel.rowNames.length > 0 ? (
                    currentModel.rowNames
                      .filter((row) => typeof row === 'string') // Filter out non-string entries
                      .map((row, index) => (
                        <option key={`non-historical-${index}`} value={row}>
                          {row}
                        </option>
                      ))
                  ) : (
                    <option disabled>No rows available</option>
                  )}
                </select>
              </div>
            )}
          </div>
        )}
      </div>
  
      {/* Table Section */}
      <div className="popup-table-container">
        <table className="popup-table">
          <thead>
            <tr>
              {generateTableHeaders().map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>
  
      {/* Conditionally render either the "Import" or "Save" button */}
      {!isEditing ? (
        <button
          className="popup-save"
          onClick={handleImportRowData}
          disabled={
            (selectedDriver === 'Formula of Rows' && sumValidationMessage !== "Formula is valid!") ||
            (selectedDriver === 'Formula of Rows' && sumFormulaInput.trim() === "")
          }
        >
          Import Row Data
        </button>
      ) : (
        <button
          className="popup-save"
          onClick={handleSaveChanges}
          disabled={
            (selectedDriver === 'Formula of Rows' && sumValidationMessage !== "Formula is valid!") ||
            (selectedDriver === 'Formula of Rows' && sumFormulaInput.trim() === "")
          }
        >
          Save
        </button>
      )}
    </div>
  );  
};

export default AddRowForm;