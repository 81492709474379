import React, { useState, useEffect } from 'react';
import api from '../api';
import { useLocation, NavLink } from 'react-router-dom'; // Assuming you're using React Router for routing

function VerifyEmail() {
    const [verificationStatus, setVerificationStatus] = useState('');
    const [verificationAttempted, setVerificationAttempted] = useState(false);
    const location = useLocation();

    useEffect(() => {
        console.log('Verifying email only once.');
        if (!verificationAttempted) {
        const verifyEmail = async () => {
            // Extract token from URL
            const params = new URLSearchParams(location.search);
            const token = params.get('token');

            if (token) {
                setVerificationAttempted(true);  // Mark that we're attempting verification
                try {
                    // Send token to backend for verification
                    const response = await api.get(`/verifyemail?token=${token}`);
                    console.log("Verification response:", response.data); // Log the entire response
                    setVerificationStatus(response.data.message);
                } catch (error) {
                    console.error("Verification error:", error.response); // Log the entire error response
                    if (error.response && error.response.data) {
                        setVerificationStatus(`Failed to verify email: ${error.response.data.message}`);
                    } else {
                        setVerificationStatus('Failed to verify email. Please try again or contact support if the problem persists.');
                    }
                }
            } else {
                setVerificationStatus('No verification token found. Please make sure you followed the correct link from your verification email.');
            }
        };

        verifyEmail();
        
    }}, [location, verificationAttempted]);

    return (
        <div>
            <h2>Email Verification</h2>
            <p>{verificationStatus}</p>
            <NavLink to='/'>Sign in</NavLink>

        </div>
    );
}

export default VerifyEmail;