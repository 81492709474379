import React, { useState, useEffect, useCallback } from 'react';
import api from '../api';
import FormulaValidator from '../formulavalidator';
import { evaluateSumFormula } from './evaluatesumformula';

const AddCalculatedRowForm = ({
  currentModel,
  onRowAdded,
  onClose,
  isEditing,
  rowData,
  rowIndex,
  onSave,
}) => {
  // State variables
  const [isHistorical, setIsHistorical] = useState(true);
  const [selectedDriver, setSelectedDriver] = useState('Calculated Row');
  const [rowName, setRowName] = useState('');
  const [forecastInputs, setForecastInputs] = useState({});
  const [forecastAmounts, setForecastAmounts] = useState({});
  const [historicalAmounts, setHistoricalAmounts] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const [isEditingSetup, setIsEditingSetup] = useState(false); // New state for Edit Setup Options
  const [sumFormulaInput, setSumFormulaInput] = useState(''); // To hold the user's formula input
  const [sumValidationMessage, setSumValidationMessage] = useState(null); // To display validation results
  const [sumFormulaToValidate, setSumFormulaToValidate] = useState(''); // To trigger validation
  const [calculatedHistoricalValues, setCalculatedHistoricalValues] = useState({});
  const [calculatedForecastValues, setCalculatedForecastValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // 7. Handler for forecast input changes
  const handleForecastInputChange = useCallback(
    (period, value, periods) => {
      const parsedValue = parseFloat(value);
      console.log(`Entered forecast value for ${period}:`, parsedValue);
  
      setForecastInputs((prevState) => {
        const updatedInputs = {
          ...prevState,
          [period]: isNaN(parsedValue) ? '' : parsedValue, // Allow empty string for flexibility
        };
  
        console.log('Updated forecast inputs:', updatedInputs);
  
        return updatedInputs;
      });
    },
    []
  );  

  // 8. Handler to update historical amounts when edited
  const handleHistoricalAmountChange = useCallback((label, value) => {
    const parsedValue = isNaN(parseFloat(value)) ? '' : parseFloat(value);
    setHistoricalAmounts((prevState) => {
      if (prevState[label] === parsedValue) return prevState; // Prevent update if value is same
      return {
        ...prevState,
        [label]: parsedValue,
      };
    });
  }, []);  
  

// Update this function within your component
const calculateSumBasedOnFormula = useCallback(
  (formula, basis) => { // Added 'basis' parameter
    if (!currentModel || Object.keys(currentModel).length === 0) {
      console.error('Current model data is not available for formula evaluation.');
      return { calculatedHistoricalValues: {}, calculatedForecastValues: {} };
    }

    // Function to extract data based on type and basis
    const extractData = (type, basis) => {
      let rows = {};
      const rowNames = currentModel.rowNames; // Array of row names
    
      rowNames.forEach((rowName, index) => {
        // **Skip Pivot Table Rows**
        if (Array.isArray(rowName) && rowName[0] === 'Pivot') {
          console.log(`Skipping pivot row at index ${index}:`, rowName);
          return; // Skip to the next iteration
        }

        let data = [];
        if (type === 'historical') {
          switch (basis) { // Use the passed 'basis' parameter
            case 'Annual':
              data = JSON.parse(currentModel.annualHistRows || '[]')[index] || [];
              break;
            case 'Quarterly':
              data = JSON.parse(currentModel.quarterlyHistRows || '[]')[index] || [];
              break;
            case 'Monthly':
              data = JSON.parse(currentModel.monthlyHistRows || '[]')[index] || [];
              break;
            default:
              console.error('Invalid basis');
              data = [];
          }
        } else if (type === 'forecast') {
          switch (basis) { // Use the passed 'basis' parameter
            case 'Annual':
              data = JSON.parse(currentModel.annualForeRows || '[]')[index] || [];
              break;
            case 'Quarterly':
              data = JSON.parse(currentModel.quarterlyForeRows || '[]')[index] || [];
              break;
            case 'Monthly':
              data = JSON.parse(currentModel.monthlyForeRows || '[]')[index] || [];
              break;
            default:
              console.error('Invalid basis');
              data = [];
          }
        }
        
        // Sanitize data: ensure all values are numeric
        rows[rowName] = Array.isArray(data)
          ? data.map(value => {
              const parsed = parseFloat(value);
              return isNaN(parsed) ? 0 : parsed;
            })
          : [];
      });
    
      return rows;
    };            

    // Extract historical and forecast data based on the provided basis
    const allHistoricalData = extractData('historical', basis); // Pass the basis as an argument
    const allForecastData = extractData('forecast', basis); // Pass the basis as an argument

    console.log('allhistdata: ', allHistoricalData);
    console.log('allforedata: ', allForecastData);

    // Evaluate formula for all historical periods
    const newCalculatedHistoricalValues = {};
    const histPeriodCount = Object.values(allHistoricalData)[0]?.length || 0; // Determine the number of historical periods

    for (let i = 0; i < histPeriodCount; i++) {
      try {
        const result = evaluateSumFormula(formula, allHistoricalData, i);
        newCalculatedHistoricalValues[i] = result.toFixed(2);
      } catch (err) {
        console.error(`Error evaluating formula for historical period ${i + 1}:`, err.message);
        newCalculatedHistoricalValues[i] = 'Error';
      }
    }

    // Evaluate formula for all forecast periods
    const newCalculatedForecastValues = {};
    const forePeriodCount = Object.values(allForecastData)[0]?.length || 0; // Determine the number of forecast periods

    for (let i = 0; i < forePeriodCount; i++) {
      try {
        const result = evaluateSumFormula(formula, allForecastData, i);
        newCalculatedForecastValues[i] = result.toFixed(2);
      } catch (err) {
        console.error(`Error evaluating formula for forecast period ${i + 1}:`, err.message);
        newCalculatedForecastValues[i] = 'Error';
      }
    }

    console.log('newhistvalues: ', newCalculatedHistoricalValues);
    console.log('newforevalues: ', newCalculatedForecastValues);

    // **Return** the calculated values instead of setting state
    return {
      calculatedHistoricalValues: newCalculatedHistoricalValues,
      calculatedForecastValues: newCalculatedForecastValues,
    };
  },
  [currentModel, evaluateSumFormula]
);

const handleValidationResult = useCallback(
  (error, correctedFormula) => {
    if (error) {
      setSumValidationMessage(error);
      setCalculatedHistoricalValues({});
      setCalculatedForecastValues({});
    } else {
      setSumValidationMessage("Formula is valid!");
      const sanitizedFormula = correctedFormula.replace(/"/g, "'");
      
      // **Call calculateSumBasedOnFormula and capture the returned values**
      const { calculatedHistoricalValues, calculatedForecastValues } = calculateSumBasedOnFormula(sanitizedFormula, currentModel.basis);
      
      // **Slice the calculated values based on histPeriods and forePeriods**
      const slicedHistoricalValues = {};
      const slicedForecastValues = {};
      
      // Slice historical values: take the last 'histPeriods' entries
      Object.keys(calculatedHistoricalValues).forEach(key => {
        const index = parseInt(key, 10);
        if (index >= (Object.keys(calculatedHistoricalValues).length - currentModel.histPeriods)) {
          slicedHistoricalValues[index] = calculatedHistoricalValues[key];
        }
      });
      
      // Slice forecast values: take the first 'forePeriods' entries
      Object.keys(calculatedForecastValues).forEach(key => {
        const index = parseInt(key, 10);
        if (index < currentModel.forePeriods) {
          slicedForecastValues[index] = calculatedForecastValues[key];
        }
      });
      
      // **Set the sliced values to state**
      setCalculatedHistoricalValues(slicedHistoricalValues);
      setCalculatedForecastValues(slicedForecastValues);
    }
    // Reset sumFormulaToValidate to prevent re-validation
    setSumFormulaToValidate('');
  },
  [calculateSumBasedOnFormula, currentModel.histPeriods, currentModel.forePeriods, currentModel.basis]
);
  


const generateTableRows = useCallback(
  (periods) => {
    const isSumOfRows = selectedDriver === 'Calculated Row' && sumValidationMessage === "Formula is valid!";
    console.log(`Generating table rows. isSumOfRows: ${isSumOfRows}`);

    // Determine if the current driver is "Calculated Row" and slice historical values accordingly
    const histValues = isSumOfRows
      ? Object.values(calculatedHistoricalValues).slice(-currentModel.histPeriods)
      : null;

    console.log('histvalues: ', histValues);

    const growthRow = []; // Row for the input fields (growth or percentage)
    const amountRow = []; // Row for the calculated forecast amounts
    let previousValue = 0; // To hold the previous value for Fixed Growth or Percent Increase

    periods.forEach((label, i) => {
      console.log(`Period Label: ${label}, Forecast Input: ${forecastInputs[label]}`);

      if (i < currentModel.histPeriods) {
        // Historical Periods
        const sum = isSumOfRows
          ? (histValues && histValues[i] !== undefined ? histValues[i] : '0')
          : (historicalAmounts[label] !== undefined
            ? historicalAmounts[label]
            : (isHistorical ? 0 : ''));

        // Determine if the historical amount should be editable
        const isEditableHistorical = !isHistorical || (isEditing && !isEditingSetup);

        amountRow.push(
          <td key={`amount-${i}`}>
            {isEditableHistorical ? (
              <input
                type="number"
                value={sum}
                onChange={(e) => handleHistoricalAmountChange(label, e.target.value)}
              />
            ) : (
              sum
            )}
          </td>
        );
      } else {
        // Forecast Periods
        if (isSumOfRows) {
          const forecastIndex = i - currentModel.histPeriods;
          const value = calculatedForecastValues[forecastIndex] || '';
          amountRow.push(<td key={`fore-${i}`}>{value}</td>);
          // For "Calculated Row", no growth row is needed for forecast periods
          growthRow.push(<td key={`fore-growth-${i}`}></td>);
        } else {
          if (selectedDriver === 'Calculated Row') {
            // Input row for fixed amounts
            amountRow.push(
              <td key={`amount-${i}`}>
                <input
                  type="number"
                  value={forecastInputs[label] || ''}
                  onChange={(e) => handleForecastInputChange(label, e.target.value, periods)}
                />
              </td>
            );
            // No growth input for Fixed Amount
            growthRow.push(<td key={`growth-${i}`}></td>);
          }
        }
      }
    });

    // Render the table rows with appropriate labels in the driver column
    return (
      <>
        <tr>
          <td>{rowName}</td>
          <td>Amount</td>
          {amountRow}
        </tr>
      </>
    );
  },
  [
    currentModel.histPeriods,
    currentModel.forePeriods,
    selectedDriver,
    rowName,
    historicalAmounts,
    forecastInputs,
    forecastAmounts,
    calculatedHistoricalValues,
    calculatedForecastValues,
    handleForecastInputChange,
    handleHistoricalAmountChange,
    isEditing,
    isEditingSetup,
    isHistorical,
  ]
);
  
  // 11. Helper function to generate table headers
  const generateTableHeaders = useCallback(() => {
    let histHeaders = [];
    let foreHeaders = [];

    // Determine which headers to use based on the model's basis
    switch (currentModel.basis) {
      case 'Yearly':
        histHeaders = JSON.parse(currentModel.annualHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.annualForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      case 'Quarterly':
        histHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      case 'Monthly':
        histHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      default:
        console.error('Invalid model basis');
        return [];
    }

    // Extract the first element (label) from each header array
    const historicalLabels = histHeaders.map((header) => header[0]); // Access the first element for historical labels
    const forecastLabels = foreHeaders.map((header) => header[0]); // Access the first element for forecast labels

    console.log('Historical Labels:', historicalLabels);
    console.log('Forecast Labels:', forecastLabels);

    return ['Row name', 'Driver', ...historicalLabels, ...forecastLabels];
  }, [currentModel]);

    // 17. useEffect to prepopulate form if editing an existing row
    useEffect(() => {
      if (isEditing && rowData) {
        console.log('Editing mode: Initializing with existing row data');
        console.log('rowData:', rowData);
    
        // Prepopulate row details when editing
        setRowName(rowData.name || '');
    
        // Handle Complex Drivers
        if (Array.isArray(rowData.driver)) {
          const [driverType, driverValue] = rowData.driver;
          console.log('Driver Type:', driverType);
          console.log('Driver Value:', driverValue);
    
          setSelectedDriver(driverType);
    
          if (driverType === 'Calculated Row') {
            setSumFormulaInput(driverValue);
          }
    
        } else {
          console.log('Driver (String):', rowData.driver);
          setSelectedDriver(rowData.driver);
        }
    
        // Slice historical and forecast data based on model periods
        const slicedHistoricalData = rowData.historicalData.slice(-currentModel.histPeriods);
        const slicedForecastData = rowData.forecastData.slice(0, currentModel.forePeriods);
        const slicedForecastValues = rowData.forecastValues.slice(0, currentModel.forePeriods);
    
        console.log('Editing row with prepopulated data:', {
          rowData,
          slicedHistoricalData,
          slicedForecastData,
          slicedForecastValues
        });
    
        if (isHistorical) {
          // Retrieve historical and forecast headers based on model basis
          let histHeaders = [];
          let foreHeaders = [];
    
          switch (currentModel.basis) {
            case 'Yearly':
              histHeaders = JSON.parse(currentModel.annualHistHeaders || '[]').slice(-currentModel.histPeriods);
              foreHeaders = JSON.parse(currentModel.annualForeHeaders || '[]').slice(0, currentModel.forePeriods);
              break;
            case 'Quarterly':
              histHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]').slice(-currentModel.histPeriods);
              foreHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
              break;
            case 'Monthly':
              histHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]').slice(-currentModel.histPeriods);
              foreHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
              break;
            default:
              console.error('Invalid model basis');
              return;
          }
    
          const histLabels = histHeaders.map((header) => header[0]);
          const foreLabels = foreHeaders.map((header) => header[0]);
    
          // Map slicedHistoricalData to histLabels
          const tempHistoricalAmounts = {};
          histLabels.forEach((label, idx) => {
            tempHistoricalAmounts[label] = Number(slicedHistoricalData[idx]) || 0;
          });
          console.log('Setting historicalAmounts:', tempHistoricalAmounts);
          setHistoricalAmounts(tempHistoricalAmounts);
    
          // Map slicedForecastData to foreLabels
          const tempForecastInputs = {};
          foreLabels.forEach((label, idx) => {
            tempForecastInputs[label] = slicedForecastValues[idx] || '';
          });
          console.log('Setting forecastInputs:', tempForecastInputs);
          setForecastInputs(tempForecastInputs);
    
          // Combine periods for table rows
          const periods = [...histLabels, ...foreLabels];
    
          // Generate table rows based on existing forecast amounts
          console.log('Generating table rows with periods:', periods);
          setTableRows(generateTableRows(periods));
        } else {
          // When not historical, pad historical data with "" based on currentModel's basis and histPeriods
    
          // Retrieve historical headers based on model basis
          let histHeaders = [];
          switch (currentModel.basis) {
            case 'Yearly':
              histHeaders = JSON.parse(currentModel.annualHistHeaders || '[]').slice(-currentModel.histPeriods);
              break;
            case 'Quarterly':
              histHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]').slice(-currentModel.histPeriods);
              break;
            case 'Monthly':
              histHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]').slice(-currentModel.histPeriods);
              break;
            default:
              console.error('Invalid model basis');
              histHeaders = [];
          }
    
          const histLabels = histHeaders.map((header) => header[0]);
    
          // Set historicalAmounts to "" for each historical period
          const tempHistoricalAmounts = {};
          histLabels.forEach((label) => {
            tempHistoricalAmounts[label] = ''; // Set as empty string
          });
          setHistoricalAmounts(tempHistoricalAmounts);
    
          // Set forecastInputs to "" for each forecast period
          let foreHeaders = [];
          switch (currentModel.basis) {
            case 'Yearly':
              foreHeaders = JSON.parse(currentModel.annualForeHeaders || '[]').slice(0, currentModel.forePeriods);
              break;
            case 'Quarterly':
              foreHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
              break;
            case 'Monthly':
              foreHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
              break;
            default:
              foreHeaders = [];
          }
    
          const tempForecastInputs = {};
          foreHeaders.forEach((header) => {
            tempForecastInputs[header[0]] = ''; // Set as empty string
          });
          setForecastInputs(tempForecastInputs);
    
          // Combine periods for table rows
          const periods = [...histLabels, ...foreHeaders.map((header) => header[0])];
          setTableRows(generateTableRows(periods));
        }
      }
    }, [isEditing, rowData, currentModel, isHistorical]);        

  // 13. useEffect to generate table rows when dependencies change
  useEffect(() => {
    let histHeaders = [];
    let foreHeaders = [];

    // Retrieve the appropriate headers based on the model's basis
    switch (currentModel.basis) {
      case 'Yearly':
        histHeaders = JSON.parse(currentModel.annualHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.annualForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      case 'Quarterly':
        histHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      case 'Monthly':
        histHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      default:
        console.error('Invalid model basis');
        return;
    }

    // Combine historical and forecast headers into periods
    const periods = [
      ...histHeaders.map((header) => header[0]),
      ...foreHeaders.map((header) => header[0]),
    ];

    // Update table rows whenever relevant dependencies change
    setTableRows(generateTableRows(periods));
  }, [
    forecastInputs, // Trigger when forecast inputs change
    forecastAmounts, // Trigger when forecast amounts change
    historicalAmounts, // Trigger when historical amounts change
    currentModel, // Trigger when the model changes (including headers)
    generateTableRows,
  ]);

  // 14. useEffect to recalculate forecast amounts whenever forecastInputs or historicalAmounts change
  useEffect(() => {
    if (isEditingSetup) {
      // Skip recalculations during setup editing
      console.log('Recalculation skipped: isEditingSetup is true');
      return;
    }
  
    if (
      Object.keys(forecastInputs).length === 0 ||
      Object.keys(historicalAmounts).length === 0
    ) {
      // Do not calculate if inputs are not set
      console.log('Recalculation skipped: forecastInputs or historicalAmounts are empty');
      return;
    }
  
    console.log('Recalculating forecast amounts');
    let histHeaders = [];
    let foreHeaders = [];
  
    switch (currentModel.basis) {
      case 'Yearly':
        histHeaders = JSON.parse(currentModel.annualHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.annualForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      case 'Quarterly':
        histHeaders = JSON.parse(currentModel.quarterlyHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.quarterlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      case 'Monthly':
        histHeaders = JSON.parse(currentModel.monthlyHistHeaders || '[]').slice(-currentModel.histPeriods);
        foreHeaders = JSON.parse(currentModel.monthlyForeHeaders || '[]').slice(0, currentModel.forePeriods);
        break;
      default:
        console.error('Invalid model basis');
        return;
    }
  
    const periods = [
      ...histHeaders.map((header) => header[0]),
      ...foreHeaders.map((header) => header[0]),
    ];
  
  }, [
    forecastInputs,
    historicalAmounts,
    currentModel.histPeriods,
    currentModel.forePeriods,
    currentModel.basis,
    isEditingSetup,
  ]);  

  
  const handleImportRowData = useCallback(async () => {
    console.log('handleImportRowData called');
    if (isSubmitting) return;
    if (!rowName || !selectedDriver) {
        console.error('Row name and driver must be filled.');
        alert('Please fill in all required fields: Row Name and Driver.');
        return;
    }
    setIsSubmitting(true);

    // Initialize empty arrays for historical and forecast data
    let annualHistData = Array(10).fill('0');
    let quarterlyHistData = Array(40).fill('0');
    let monthlyHistData = Array(120).fill('0');

    let annualForeValues = Array(10).fill('0'); // To store percentages/growth amounts
    let quarterlyForeValues = Array(40).fill('0');
    let monthlyForeValues = Array(120).fill('0');

    let annualForeRows = Array(10).fill('0'); // To store calculated forecast values
    let quarterlyForeRows = Array(40).fill('0');
    let monthlyForeRows = Array(120).fill('0');

    if (isHistorical) {
        if (selectedDriver === 'Calculated Row') {
                // **Handling "Calculated Row" Driver for All Bases**
                console.log('sumformulainput: ', sumFormulaInput);

                const bases = ['Annual', 'Quarterly', 'Monthly'];
                const calculatedData = {};

                // Loop through each basis and calculate values
                for (const basis of bases) {
                    const { calculatedHistoricalValues, calculatedForecastValues } = calculateSumBasedOnFormula(sumFormulaInput, basis);

                    calculatedData[basis] = {
                        historical: Object.values(calculatedHistoricalValues),
                        forecast: Object.values(calculatedForecastValues),
                    };
                }

                // Assign calculated historical data
                annualHistData = calculatedData['Annual'].historical;
                quarterlyHistData = calculatedData['Quarterly'].historical;
                monthlyHistData = calculatedData['Monthly'].historical;

                // Assign calculated forecast data
                annualForeValues = calculatedData['Annual'].forecast; // Store percentages/growth amounts
                quarterlyForeValues = calculatedData['Quarterly'].forecast;
                monthlyForeValues = calculatedData['Monthly'].forecast;

                // For "Calculated Row", forecast rows are the calculated values
                annualForeRows = calculatedData['Annual'].forecast;
                quarterlyForeRows = calculatedData['Quarterly'].forecast;
                monthlyForeRows = calculatedData['Monthly'].forecast;

                console.log('Calculated Historical Data:', {
                    annualHistData,
                    quarterlyHistData,
                    monthlyHistData,
                });
                console.log('Calculated Forecast Data:', {
                    annualForeValues,
                    quarterlyForeValues,
                    monthlyForeValues,
                });
                console.log('Calculated Forecast Rows:', {
                    annualForeRows,
                    quarterlyForeRows,
                    monthlyForeRows,
                });
        }
      }
        // **Prepare the Row Data to be Sent to the Backend**
        const rowDataToSend = {
            rowNames: [rowName],
            rowVariables: ['None'],
            categories: ['None'],
            groupedOver: 'None',
            dateColumn: 'None',
            sourceFile: ['None'],
            drivers: [['Calculated Row', sumFormulaInput]],
            supportingAssumptionModelIds: [''],
            annualHistRows: [annualHistData],
            quarterlyHistRows: [quarterlyHistData],
            monthlyHistRows: [monthlyHistData],
            annualForeRows: [annualForeRows],
            quarterlyForeRows: [quarterlyForeRows],
            monthlyForeRows: [monthlyForeRows],
            annualForeValues: [annualForeValues],
            quarterlyForeValues: [quarterlyForeValues],
            monthlyForeValues: [monthlyForeValues],
        };

        console.log('Row Data to Send:', rowDataToSend); // Add this log for debugging

        try {
            const response = await api.put(`/models/${currentModel.id}/add-row`, rowDataToSend);
            if (response.status === 200) {
                console.log('Row data successfully imported');
                const updatedModel = response.data.model;
                onRowAdded(updatedModel);
                onClose();
            } else {
                console.error('Unexpected response:', response.data);
                alert(`Failed to import row data: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error importing row data:', error);
            alert('An error occurred while importing row data.');
        } finally {
            setIsSubmitting(false); // Reset submitting state
        }
  }, [
        isSubmitting,
        rowName,
        selectedDriver,
        currentModel,
        forecastInputs,
        isHistorical,
        calculatedHistoricalValues, // For "Calculated Row" driver
        calculatedForecastValues,  // For "Calculated Row" driver
        onRowAdded,
        onClose,
    ]);


    const handleSaveChanges = useCallback(async () => {
      console.log('handleSaveChanges called');
      if (onSave && rowData) {
        // Clone the existing historical and forecast data from rowData
        let existingHistoricalData = [...rowData.historicalData];
        let existingForecastData = [...rowData.forecastData];
        let existingForecastValues = [...rowData.forecastValues];
    
        console.log('rowdata: ', rowData);
    
        // Determine whether to use updated state variables or existing rowData values
        const driver = isEditingSetup ? selectedDriver : rowData.driver;
        console.log('driver: ', driver);
    
        if (isHistorical) {
          // Update historical values based on user input
          const updatedHistoricalValues = Object.values(historicalAmounts).map(v => String(v));
          const startHistIndex = existingHistoricalData.length - currentModel.histPeriods;
          for (let i = 0; i < updatedHistoricalValues.length; i++) {
            existingHistoricalData[startHistIndex + i] = updatedHistoricalValues[i];
          }
        } else {
          // When not historical, pad historical data with ""
          switch (currentModel.basis) {
            case 'Yearly':
              existingHistoricalData = Array(currentModel.histPeriods).fill('0');
              break;
            case 'Quarterly':
              existingHistoricalData = Array(currentModel.histPeriods).fill('');
              break;
            case 'Monthly':
              existingHistoricalData = Array(currentModel.histPeriods).fill('');
              break;
            default:
              console.error('Invalid model basis');
              existingHistoricalData = [];
          }
        }
    
        // Initialize placeholders for updatedForecastRows and updatedForecastValues
        let updatedForecastRows = [];
        let updatedForecastValues = [];
    
        // Handle different drivers
        if (Array.isArray(driver)) {
          const [driverType, driverValue] = driver;
    
          switch (driverType) {
            case 'Calculated Row':
              if (!sumFormulaInput || sumFormulaInput.trim() === '') {
                console.error('Formula is required for "Calculated Row" driver.');
                alert('Please enter a formula for "Calculated Row" driver.');
                return; // Exit early if formula is not provided
              }
              if (sumValidationMessage !== "Formula is valid!") {
                alert('Please ensure the Calculated Row formula is valid before saving.');
                return;
              }
              // Use calculatedForecastValues which are derived from the validated formula
              updatedForecastRows = Object.values(calculatedForecastValues).map(v => String(v));
              // Depending on your backend needs, you might not need to send foreValues for Calculated Row
              updatedForecastValues = []; // Or set to null/empty array if not needed
              break;
    
            default:
              console.error('Unknown driver type:', driverType);
              alert('Unknown driver type. Please check your selections.');
              return;
          }
        } else {
          // Handle standard drivers: 'Fixed Amount', 'Fixed Growth', 'Percent Increase'
          switch (driver) {
            case 'Fixed Amount':
              updatedForecastRows = Object.values(forecastAmounts).map(v => String(v));
              updatedForecastValues = Object.values(forecastAmounts).map(v => String(v));
              break;
            case 'Fixed Growth':
            case 'Percent Increase':
              updatedForecastRows = Object.values(forecastAmounts).map(v => String(v));
              updatedForecastValues = Object.values(forecastInputs).map(v => String(v));
              break;
            default:
              console.error('Unknown driver:', driver);
              alert('Unknown driver type. Please check your selections.');
              return;
          }
        }
    
        // Replace the forecast data with new values
        if (updatedForecastValues.length > 0) {
          for (let i = 0; i < updatedForecastValues.length; i++) {
            existingForecastValues[i] = updatedForecastValues[i];
          }
        }
    
        if (updatedForecastRows.length > 0) {
          for (let i = 0; i < updatedForecastRows.length; i++) {
            existingForecastData[i] = updatedForecastRows[i];
          }
        }
    
        // **Format the driver field based on its type**
        let formattedDriver;
        console.log('Selected Driver:', selectedDriver);
    
        if (Array.isArray(driver)) {
          // Driver is already in the correct format (array)
          formattedDriver = driver;
        } else {
          // For standard drivers, keep as string
          formattedDriver = driver;
        }
    
        // **Prepare the updated row data to be sent**
        const updatedData = {
          rowIndex,
          rowName, // Include updated row name
          updatedHistoricalValues: existingHistoricalData,
          updatedForecastValues: existingForecastValues,
          updatedForecastRows: existingForecastData, // Include foreRows
          basis: currentModel.basis,
        };
    
        console.log('Submitting updated row data:', updatedData);
        await onSave(updatedData);
      }
    }, [
      onSave,
      rowIndex,
      historicalAmounts,
      forecastAmounts,
      forecastInputs,
      rowName,
      selectedDriver,
      isHistorical,
      rowData,
      currentModel.histPeriods,
      currentModel.forePeriods,
      currentModel.basis,
      sumFormulaInput, // Added dependency for sumFormulaInput
      sumValidationMessage,
      calculatedForecastValues,
      forecastAmounts,
    ]);    

  const handleDeleteRow = useCallback(async () => {
    // Step 1: Prompt the user for confirmation
    const confirmDelete = window.confirm('Are you sure you want to delete this row? This action cannot be undone.');
  
    if (!confirmDelete) {
      console.log('Row deletion canceled by the user.');
      return;
    }
  
    console.log(`Attempting to delete row at index: ${rowIndex}`);
  
    try {
      // Step 2: Make an API call to delete the row
      const response = await api.delete(`/model/${currentModel.id}/delete-row`, {
        data: { rowIndex }, // The rowIndex is passed correctly here as part of the config object
      });
  
      if (response.status === 200) {
        console.log('Row successfully deleted:', response.data);
  
        // Step 3: Update the parent component or state
        const updatedModel = response.data.model; // Assuming the updated model is returned in the response
        onRowAdded(updatedModel); // Update the parent with the new data
  
        // Close the popup after successful deletion
        onClose();
      } else {
        console.error('Failed to delete row:', response.data);
        alert(`Failed to delete row: ${response.data.message || 'Unknown error.'}`);
      }
    } catch (error) {
      console.error('Error deleting row:', error);
      alert('An error occurred while deleting the row. Please try again.');
    }
  }, [rowIndex, currentModel.id, onRowAdded, onClose, api]);
  


  return (
    <div className="popup-content">
      {/* Close Button */}
      <button className="popup-close" onClick={onClose}>
        ×
      </button>
  
      {/* Header Right Section */}
      <div className="popup-header-right">

        {/* Delete Row button (always visible when editing) */}
        {isEditing && (
          <button type="button" onClick={handleDeleteRow} className="edit-setup-button">
            Delete Row
          </button>
        )}
      </div>
  
      {/* Popup Title */}
      <h2>{isEditing ? 'Edit Row' : 'Add Simplified Row'}</h2>
  
      {/* Form Section */}
      <div>
        <div>
          <label htmlFor="row-name">Row Name:</label>
          <input
            id="row-name"
            type="text"
            value={rowName}
            onChange={(e) => setRowName(e.target.value)}
          />
        </div>
  
        {/* "Edit Setup Options" Button */}
        {isEditing && (
          <button
            type="button"
            onClick={() => setIsEditingSetup((prev) => !prev)}
            className="edit-setup-button"
          >
            {isEditingSetup ? 'Cancel Setup Editing' : 'Edit Setup Options'}
          </button>
        )}
  
        {/* Conditionally Render Setup Options */}
        {(!isEditing || isEditingSetup) && (
          <>

            {/* Conditionally render Formula Input and Validate Button */}
            {selectedDriver === 'Calculated Row' && (
              <div className="formula-validation-section">
                <div>
                  <label htmlFor="sum-formula-input">Enter Formula:</label>
                  <input
                    id="sum-formula-input"
                    type="text"
                    value={sumFormulaInput}
                    onChange={(e) => {
                      setSumFormulaInput(e.target.value);
                      setSumValidationMessage(null); // Hide validation message when editing
                      setSumFormulaToValidate(''); // Reset the formula to validate
                    }}
                    placeholder="'row1'+'row2'-'row3'"
                  />
                </div>
                <button
                  type="button"
                  onClick={() => setSumFormulaToValidate(sumFormulaInput)}
                  className="validate-formula-button"
                >
                  Validate Formula
                </button>
                {sumValidationMessage && (
                  <p className={`validation-message ${sumValidationMessage.startsWith('Error') ? 'error' : 'success'}`}>
                    {sumValidationMessage}
                  </p>
                )}

                {/* Conditionally render FormulaValidatorRowSums only if sumFormulaToValidate is not empty */}
                {sumFormulaToValidate && (
                  <FormulaValidator
                    formula={sumFormulaToValidate}
                    columnData={currentModel.rowNames.map((name) => ({ name, status: 'Numeric' }))}
                    onValidationResult={handleValidationResult}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
  
      {/* Table Section */}
      <div className="popup-table-container">
        <table className="popup-table">
          <thead>
            <tr>
              {generateTableHeaders().map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>
  
      {/* Conditionally render either the "Import" or "Save" button */}
      {!isEditing ? (
        <button
          className="popup-save"
          onClick={handleImportRowData}
          disabled={
            (selectedDriver === 'Calculated Row' && sumValidationMessage !== "Formula is valid!") ||
            (selectedDriver === 'Calculated Row' && sumFormulaInput.trim() === "")
          }
        >
          Import Row Data
        </button>
      ) : (
        <button
          className="popup-save"
          onClick={handleSaveChanges}
          disabled={
            (selectedDriver === 'Calculated Row' && sumValidationMessage !== "Formula is valid!") ||
            (selectedDriver === 'Calculated Row' && sumFormulaInput.trim() === "")
          }
        >
          Save
        </button>
      )}
    </div>
  );  
};

export default AddCalculatedRowForm;