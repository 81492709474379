import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './authcontext';
import api from './api';

function ColumnSelectionPage() {
    const { spreadsheetId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { spreadsheets } = useAuth();
    const [originalHeaders, setOriginalHeaders] = useState([]);
    const [selectedHeaders, setSelectedHeaders] = useState([]);
    const [editableHeaders, setEditableHeaders] = useState([]);
    const [columnStatuses, setColumnStatuses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [allSpreadsheetData, setAllSpreadsheetData] = useState([]); // Store all spreadsheet data
    const [isMultiSheet, setIsMultiSheet] = useState(false); // Track if multiple spreadsheets are being edited
    const [errorMessage, setErrorMessage] = useState(null); // For duplicate header validation


    // Fetch spreadsheet data from the URL if present
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const data = queryParams.get('data') ? JSON.parse(decodeURIComponent(queryParams.get('data'))) : null;
        const allIds = queryParams.get('all') ? queryParams.get('all').split(',').map(id => parseInt(id, 10)) : null;

        setIsMultiSheet(allIds && allIds.length > 1); // Set if working with multiple sheets

        if (data) {
            // Use the data from the URL (newly uploaded case)
            setAllSpreadsheetData(data);
            const currentSpreadsheet = data.find(sp => sp.spreadsheet_id === parseInt(spreadsheetId, 10));

            if (currentSpreadsheet) {
                setOriginalHeaders(currentSpreadsheet.headers || []);
                setColumnStatuses(currentSpreadsheet.column_status || []);
                setEditableHeaders(currentSpreadsheet.headers || []);

                const initialSelectedHeaders = currentSpreadsheet.column_status.map(status =>
                    status === 'NumericalEditable' || status === 'CategoricalEditable' || status === 'Categorical' || status === 'Date'
                );
                setSelectedHeaders(initialSelectedHeaders);
            } else {
                console.error("Spreadsheet data not found in URL parameter.");
            }
        } else {
            // Fall back to AuthContext (existing spreadsheets case)
            const spreadsheet = spreadsheets.find(sp => sp.id === parseInt(spreadsheetId, 10));
            if (spreadsheet) {
                setOriginalHeaders(spreadsheet.originalHeaders || []);
                setColumnStatuses(spreadsheet.columnStatus || []);
                setEditableHeaders(spreadsheet.editedHeaders || []);

                const initialSelectedHeaders = (spreadsheet.columnStatus || []).map(status =>
                    status === 'NumericalEditable' || status === 'CatgoricalEditable' || status === 'Categorical' || status === 'Date'
                );
                setSelectedHeaders(initialSelectedHeaders);
            }
        }

        setLoading(false);
    }, [spreadsheetId, location, spreadsheets]);

    const handleCheckboxChange = (index) => {
        const updatedSelectedHeaders = [...selectedHeaders];
        updatedSelectedHeaders[index] = !updatedSelectedHeaders[index]; // Toggle selection state
        setSelectedHeaders(updatedSelectedHeaders);
    
        const updatedStatuses = [...columnStatuses];
        
        if (updatedSelectedHeaders[index]) {
            if (updatedStatuses[index] === 'DateUnselected') {
                updatedStatuses[index] = 'Date';
            } else if (updatedStatuses[index] === 'NumericalUnselected') {
                updatedStatuses[index] = 'NumericalEditable';
            } else {
                updatedStatuses[index] = 'Categorical';
            }
        } else {
            if (updatedStatuses[index] === 'Date') {
                updatedStatuses[index] = 'DateUnselected';
            } else if (updatedStatuses[index] === 'NumericalEditable' || updatedStatuses[index] === 'CategoricalEditable') {
                updatedStatuses[index] = 'NumericalUnselected';
            } else {
                updatedStatuses[index] = 'CategoricalUnselected';
            }
        }
    
        setColumnStatuses(updatedStatuses);
        
        // Log to debug state changes
        console.log('Editable Headers:', editableHeaders);
        console.log('Column Statuses:', columnStatuses);
    
        // Update allSpreadsheetData immediately when the checkbox changes
        updateSpreadsheetData(spreadsheetId);
    };    
    

    // Check for duplicate headers in the current spreadsheet
    const validateHeaders = () => {
        const headerSet = new Set();
        for (let header of editableHeaders) {
            if (headerSet.has(header)) {
                return false; // Duplicate found
            }
            headerSet.add(header);
        }
        return true; // No duplicates
    };

    // Check for conflicts across all spreadsheets and new ones being saved
    const checkConflicts = () => {
        const conflicts = [];
        const statusMap = {}; // To track the status (Numerical, Date, Categorical) by header name

        // Check the current spreadsheet's selected headers and statuses
        editableHeaders.forEach((header, index) => {
            const status = columnStatuses[index];
            if (['NumericalEditable', 'CategoricalEditable', 'Categorical', 'Date'].includes(status)) {
                if (statusMap[header] && statusMap[header] !== status) {
                    conflicts.push({ header, existingStatus: statusMap[header], newStatus: status });
                }
                statusMap[header] = status;
            }
        });

        // Check against all other spreadsheets in the same organization
        for (const spreadsheet of spreadsheets) {
            spreadsheet.editedHeaders.forEach((header, index) => {
                const status = spreadsheet.columnStatus[index];
                if (['NumericalEditable', 'CategoricalEditable', 'Categorical', 'Date'].includes(status)) {
                    if (statusMap[header] && statusMap[header] !== status) {
                        conflicts.push({
                            header,
                            existingStatus: statusMap[header],
                            newStatus: status,
                            conflictingSpreadsheet: spreadsheet.newName,
                        });
                    }
                    statusMap[header] = status;
                }
            });
        }

        return conflicts;
    };

    // Update the current spreadsheet's data (headers and statuses)
    const updateSpreadsheetData = (spreadsheetId) => {
        const updatedData = [...allSpreadsheetData];
        const index = updatedData.findIndex(sp => sp.spreadsheet_id === parseInt(spreadsheetId, 10));

        if (index !== -1) {
            // Update the headers and statuses in the allSpreadsheetData array
            updatedData[index].headers = [...editableHeaders];
            updatedData[index].column_status = [...columnStatuses];
            setAllSpreadsheetData(updatedData);
        }
    };

    const handleSave = async () => {
        // First, force update the current spreadsheet data before saving
        updateSpreadsheetData(spreadsheetId);
    
        const token = localStorage.getItem('token');
        
        // Validate headers before proceeding
        if (!validateHeaders()) {
            setErrorMessage('Duplicate column headers found. Please ensure all headers are unique.');
            return;
        }
    
        console.log("All Spreadsheet Data Before Save:", allSpreadsheetData);  // Debugging line
        
        // Check if any spreadsheet doesn't have a "Date" column selected
        const spreadsheetsWithoutDate = allSpreadsheetData.filter(spreadsheet => {
            return !spreadsheet.column_status.some(status => status === 'Date');
        });
        
        if (spreadsheetsWithoutDate.length > 0) {
            const spreadsheetNames = spreadsheetsWithoutDate.map(sp => sp.name).join(', ');
            const warningMessage = `The following spreadsheet(s) do not have a Date column selected: ${spreadsheetNames}. Are you sure you want to continue without selecting any Date columns?`;
            
            const confirmProceed = window.confirm(warningMessage);
            if (!confirmProceed) {
                return;  // Stop the save process if the user cancels
            }
        }
    
        // Handle conflicts
        const conflicts = checkConflicts();
        if (conflicts.length > 0) {
            const conflictMessages = conflicts.map(c =>
                `Conflict: Column "${c.header}" is a "${c.existingStatus}" in "${c.conflictingSpreadsheet || 'this spreadsheet'}", but being saved as "${c.newStatus}".`
            ).join('\n');
    
            const confirmSave = window.confirm(
                `The following conflicts were found:\n\n${conflictMessages}\n\nDo you want to disregard these warnings and save anyway?`
            );
    
            if (!confirmSave) {
                return; // Stop if user cancels
            }
        }
    
        setErrorMessage(null); // Clear previous error if validation passes
    
        try {
            if (isMultiSheet) {
                updateSpreadsheetData(spreadsheetId);  // Ensure latest changes are saved
    
                // Save all spreadsheets in multi-sheet mode
                for (const sheetData of allSpreadsheetData) {
                    const { spreadsheet_id, headers, column_status } = sheetData;
    
                    await api.put(`/spreadsheets/${spreadsheet_id}/columnstatus`, {
                        headers: headers,
                        columnStatuses: column_status,
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        }
                    });
                }
            } else {
                // Save the single spreadsheet case
                await api.put(`/spreadsheets/${spreadsheetId}/columnstatus`, {
                    headers: editableHeaders,
                    columnStatuses: columnStatuses,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
            }
    
            navigate('/datainput');
            window.location.reload();
        } catch (error) {
            console.error('Error saving column statuses:', error);
            setErrorMessage('An error occurred while saving. Please try again.');
        }
    };
    
    const handleNext = () => {
        // Save the current spreadsheet state before navigating
        updateSpreadsheetData(spreadsheetId);
    
        const currentIndex = allSpreadsheetData.findIndex(sp => sp.spreadsheet_id === parseInt(spreadsheetId, 10));
        if (currentIndex < allSpreadsheetData.length - 1) {
            const nextSpreadsheetId = allSpreadsheetData[currentIndex + 1].spreadsheet_id;
            navigate(`/columnselection/${nextSpreadsheetId}?all=${allSpreadsheetData.map(sp => sp.spreadsheet_id).join(',')}&data=${encodeURIComponent(JSON.stringify(allSpreadsheetData))}`);
        }
    };
    
    const handlePrevious = () => {
        // Save the current spreadsheet state before navigating
        updateSpreadsheetData(spreadsheetId);
    
        const currentIndex = allSpreadsheetData.findIndex(sp => sp.spreadsheet_id === parseInt(spreadsheetId, 10));
        if (currentIndex > 0) {
            const previousSpreadsheetId = allSpreadsheetData[currentIndex - 1].spreadsheet_id;
            navigate(`/columnselection/${previousSpreadsheetId}?all=${allSpreadsheetData.map(sp => sp.spreadsheet_id).join(',')}&data=${encodeURIComponent(JSON.stringify(allSpreadsheetData))}`);
        }
    };
    
    const handleSelectAll = () => {
        const updatedSelectedHeaders = [...selectedHeaders];
        const updatedStatuses = [...columnStatuses];
    
        originalHeaders.forEach((header, index) => {
            if (!selectedHeaders[index]) {
                updatedSelectedHeaders[index] = true;
                if (updatedStatuses[index] === 'DateUnselected') {
                    updatedStatuses[index] = 'Date';
                } else if (updatedStatuses[index] === 'NumericalUnselected') {
                    updatedStatuses[index] = 'NumericalEditable';
                } else if (updatedStatuses[index] === 'CategoricalUnselected') {
                    updatedStatuses[index] = 'Categorical';
                }
            }
        });
    
        setSelectedHeaders(updatedSelectedHeaders);
        setColumnStatuses(updatedStatuses);
    };
    
    const handleUnselectAll = () => {
        const updatedSelectedHeaders = [...selectedHeaders];
        const updatedStatuses = [...columnStatuses];
    
        originalHeaders.forEach((header, index) => {
            if (selectedHeaders[index]) {
                updatedSelectedHeaders[index] = false;
                if (updatedStatuses[index] === 'Date') {
                    updatedStatuses[index] = 'DateUnselected';
                } else if (updatedStatuses[index] === 'NumericalEditable' || updatedStatuses[index] === 'CategoricalEditable') {
                    updatedStatuses[index] = 'NumericalUnselected';
                } else if (updatedStatuses[index] === 'Categorical') {
                    updatedStatuses[index] = 'CategoricalUnselected';
                }
            }
        });
    
        setSelectedHeaders(updatedSelectedHeaders);
        setColumnStatuses(updatedStatuses);
    };    

    const handleEditableHeaderChange = (index, value) => {
        const updatedEditableHeaders = [...editableHeaders];
        updatedEditableHeaders[index] = value;
        setEditableHeaders(updatedEditableHeaders);
    
        // Update allSpreadsheetData immediately when the header changes
        updateSpreadsheetData(spreadsheetId);
    };
    
    const handleStatusChange = (index, value) => {
        const updatedStatuses = [...columnStatuses];
        updatedStatuses[index] = value;
        setColumnStatuses(updatedStatuses);
    
        // Update allSpreadsheetData immediately when the status changes
        updateSpreadsheetData(spreadsheetId);
    };
    

    if (loading) {
        return <div>Loading...</div>;
    }

    if (originalHeaders.length === 0) {
        return <div>No headers found for this spreadsheet.</div>;
    }

    return (
        <div>
            <h1>Column Selection</h1>
            <button onClick={handleSelectAll}>Select All</button>
            <button onClick={handleUnselectAll}>Unselect All</button>
            <div className="column-selection">
                {originalHeaders.map((header, index) => (
                    <div key={header} className="column-item">
                        <input
                            type="checkbox"
                            checked={selectedHeaders[index] || false}
                            onChange={() => handleCheckboxChange(index)}  // Keep checkbox functional
                        />
                        <label>{header}</label>
    
                        {selectedHeaders[index] && (
                            <div className="column-options">
                                {/* Keep the input editable regardless of status */}
                                <input
                                    type="text"
                                    value={editableHeaders[index]}
                                    onChange={(e) => handleEditableHeaderChange(index, e.target.value)}
                                    placeholder="Edit header"
                                />
                                {/* Only disable the status dropdown, not the editable input */}
                                <select
                                    value={columnStatuses[index]}
                                    onChange={(e) => handleStatusChange(index, e.target.value)}
                                    disabled={columnStatuses[index] === 'Date' || columnStatuses[index] === 'Categorical'} // Disable dropdown for Date and Categorical columns
                                >
                                    {columnStatuses[index] === 'Date' ? (
                                        <option value="Date">Date</option>
                                    ) : columnStatuses[index] === 'Categorical' ? (
                                        <option value="Categorical">Categorical</option>
                                    ) : (
                                        <>
                                            <option value="NumericalEditable">Numerical</option>
                                            <option value="CategoricalEditable">Categorical</option>
                                        </>
                                    )}
                                </select>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <div className="navigation-buttons">
                {isMultiSheet ? (
                    <div>
                        {allSpreadsheetData.findIndex(sp => sp.spreadsheet_id === parseInt(spreadsheetId, 10)) > 0 && (
                            <button onClick={handlePrevious}>Previous</button>
                        )}
                        {allSpreadsheetData.findIndex(sp => sp.spreadsheet_id === parseInt(spreadsheetId, 10)) < allSpreadsheetData.length - 1 ? (
                            <button onClick={handleNext}>Next</button>
                        ) : (
                            <button onClick={handleSave}>Save Changes</button>
                        )}
                    </div>
                ) : (
                    <button onClick={handleSave}>Save Changes</button>
                )}
            </div>
            {/* Display error message below the buttons */}
            {errorMessage && <div className="error">{errorMessage}</div>}
        </div>
    );    
}

export default ColumnSelectionPage;
